import DracoDecoder from './draco-decoder';
import DracoPrimitive from "./DracoPrimitive";
const EXT_ID = 'KHR_draco_mesh_compression';
class Instance {
    constructor(gltfLoader) {
        this.name = EXT_ID;
        this.priority = 1;
        this.loader = gltfLoader;
    }
    acceptElement(data, element) {
        return null;
    }
    loadElement(data) {
        if (data.gltftype === "primitives" /* PRIMITIVE */ && data.extensions && data.extensions[EXT_ID]) {
            return this.loadPrimitive(data);
        }
        return null;
    }
    async loadPrimitive(data) {
        const dracoExtData = data.extensions[EXT_ID];
        const bufferView = await this.loader.getElement("bufferViews" /* BUFFERVIEW */, dracoExtData.bufferView);
        const dracoBuffer = bufferView.buffer._bytes.slice(bufferView.getByteOffset(), bufferView.getByteOffset() + bufferView.byteLength);
        const request = {
            buffer: dracoBuffer,
            attributes: dracoExtData.attributes
        };
        var res = await KHR_draco_mesh_compression.dracoDecoder.transcode(request);
        if (res.error) {
            throw new Error(res.error);
        }
        const primitive = new DracoPrimitive();
        primitive.setDatas(res.geometry);
        await primitive.parse(this.loader, data);
        return primitive;
    }
}
export default class KHR_draco_mesh_compression {
    constructor() {
        this.name = EXT_ID;
    }
    createInstance(gltfLoader) {
        return new Instance(gltfLoader);
    }
}
KHR_draco_mesh_compression.dracoDecoder = new DracoDecoder({
    wasmUrl: 'https://www.gstatic.com/draco/v1/decoders/draco_decoder_gltf.wasm',
    moduleUrl: 'https://www.gstatic.com/draco/v1/decoders/draco_decoder_gltf.js',
});
