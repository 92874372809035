
export default function getQuery(name:string, deflt:string) {
  
  var m : string[], reg : RegExp;
  if (deflt === undefined) {
    deflt = '';
  }

  reg = new RegExp(`&?${name}=([^&]+)`, 'g');
  m = reg.exec(location.search);
  
  if (m) {
    return m[1].replace(/%20|\+/g, " ");
  } else {
    return deflt;
  }

};