import Texture from "nanogl/texture";
import GroundEntity from "../entities/GroundEntity";
import BackgroundParallaxEntity from "../entities/BackgroundParallaxEntity";
import Gltf from "../../lib/nanogl-gltf/lib";
import Scene from "../../scene";
import store from "@/store";
import { BACK_FENCE_SLOWMO, BACK_SLOWMO } from "../gameConfig";
import ParticleFieldEntity from "../entities/ParticleFieldEntity";
import RibbonEntity from "../entities/RibbonEntity";
import Camera from "nanogl-camera";
import Masks from "@/webgl/gl/masks";
import { Passes } from "@/webgl/glsl/Passes";
import IRenderable from "@/webgl/lib/nanogl-gltf/lib/renderer/IRenderable";
import GLConfig from "nanogl-state/config";

export default class ParallaxController {
    scroll: number
    scene: Scene
    ribbonEntities:Array<RibbonEntity>
    groundEntities:Array<GroundEntity>
    pEntity:BackgroundParallaxEntity
    partFieldEntity:ParticleFieldEntity
    partFrontFieldEntity:ParticleFieldEntity
    ribbonGltf:Gltf
    constructor() {
        this.scroll = 0
    }

    init(gltf: Gltf, scene:Scene, groundImgList:Array<Array<HTMLImageElement>>, ribbonGltf:Gltf) {
        this.scene = scene
        this.ribbonGltf = ribbonGltf


        const noiseTex = new Texture(scene.gl)
        noiseTex.fromImage(this.scene.game.resources.get('noise2'))
        noiseTex.bind()
        noiseTex.repeat()
        noiseTex.setFilter(true)

        this.ribbonEntities = Array.from(Array(5).keys()).map((r, i) => new RibbonEntity(ribbonGltf, scene, noiseTex, i))

        const fgTex = new Texture(this.scene.gl, this.scene.gl.RGB)
        fgTex.fromImage(window['resources'].get('fg'))
        fgTex.setFilter( true )

        const cloudTex = new Texture(this.scene.gl, this.scene.gl.RGB)
        cloudTex.fromImage(window['resources'].get('cloud'))
        cloudTex.setFilter( true )
        const groundTexList:Array<Array<Texture>> = groundImgList.map(imgs => this.createMultiTextures(imgs))
        this.groundEntities = groundTexList.map(texs => new GroundEntity(texs[0], texs[1], cloudTex, fgTex, scene))

        this.pEntity = new BackgroundParallaxEntity(gltf, scene)

        this.partFieldEntity = new ParticleFieldEntity(this.scene)
        this.partFrontFieldEntity = new ParticleFieldEntity(this.scene, true)
    }

    shadowsReady() {
        this.groundEntities[0].shadowsReady()
    }

    createMultiTextures(multiImage:Array<HTMLImageElement>):Array<Texture> {
        return multiImage.map((img) => {
            const t:Texture = new Texture(this.scene.gl, this.scene.gl.RGBA)
            t.fromImage(img)
            t.setFilter( false )
            t.bind()
            t.repeat()
            return t;
        })
    }

    preRender(dt:number, globalSpeed:number, changeSpeed:number, introOutro:number) {
        const scrollSpeed = (dt + globalSpeed * dt) * changeSpeed * (1 - this.scene.game.introOutro)
        this.scroll += store.getters['game/qte'] === -1 ? scrollSpeed : scrollSpeed * (store.getters['game/qte'] === 1 ? BACK_FENCE_SLOWMO : BACK_SLOWMO)
        this.pEntity.preRender(this.scroll)
        this.groundEntities.forEach((entity) => entity.preRender(this.scroll))
        this.partFieldEntity.preRender(this.scroll, dt)
        this.partFrontFieldEntity.preRender(this.scroll, dt)
        this.ribbonEntities.forEach(r => r.preRender(this.scroll, dt, changeSpeed * introOutro))
    }

    render() {
        this.pEntity.render()
        this.groundEntities.forEach((entity) => entity.render())
        
    }

    renderParticles() {
        this.partFieldEntity.render()
        this.partFrontFieldEntity.render()

        this.renderRibbons()
    }

    renderRibbons() {
        this.renderMainScene( this.scene.game.getCamera(), Masks.OPAQUE , Passes.COLOR, this.ribbonGltf.renderables, this.scene.game.ribbonCfg);
    }

    renderMainScene(camera: Camera, mask: Masks, passId: Passes = Passes.DEFAULT, renderables:IRenderable[], cfg?: GLConfig) {
      for (const renderable of renderables) {
          if(renderable.node.x > -1.8 && renderable.node.x < 1.8)
            renderable.render(this.scene, camera, mask, passId, cfg) 
      }
    }
}