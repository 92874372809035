import Chunk from "nanogl-pbr/Chunk";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import glsl_pre_vert from "./letter_pre.vert";
import glsl_vert from "./letter.vert";
import glsl_pre_frag from "./letter_pre.frag";
import glsl_frag from "./letter.frag";

export default class LetterEffect extends Chunk {

  constructor() {
    super(true, true);
  }

  setup(prg) {
    // this.sunX = envs.SUN_POS
    // if(prg.uSunX)
    //   prg.uSunX(this.sunX);
  }

  _genCode(slots: ChunksSlots) {
    slots.add('pv', glsl_pre_vert());
    slots.add('vertex_warp_world', glsl_vert());
    slots.add('pf', glsl_pre_frag());
    slots.add('postf', glsl_frag());
  }

  _getHash(): string {
      return 'letter-effect';
  }

}
