import Vao from "nanogl-vao";
import Program from "nanogl/program";
import ArrayBuffer from "nanogl/arraybuffer";
import { GLContext } from "nanogl/types";
import Node from "nanogl-node";

import particleVert  from '@/webgl/glsl/particles/particles.vert';
import particleFrag  from '@/webgl/glsl/particles/particles.frag';
import particleTexFrag  from '@/webgl/glsl/particles/particlestex.frag';
import Scene from "@/webgl/scene";
import Texture from "nanogl/texture";


function checkHighP( gl:GLContext ){

    const hv = gl.getShaderPrecisionFormat( gl.VERTEX_SHADER, gl.HIGH_FLOAT );
    const hf = gl.getShaderPrecisionFormat( gl.FRAGMENT_SHADER, gl.HIGH_FLOAT );
    return  hf.precision > 0 && hv.precision > 0;
  
  }

const PARTICLE_NUMBER:number = 30
const PARTICLE_NUMBER_FRONT:number = 3

export default class ParticleFieldEntity {
    particleVao:Vao
    buffer:ArrayBuffer
    particleProgram:Program
    node:Node
    hasHighp:boolean
    scene:Scene
    scroll:number
    zPertubator:number
    maxFrontSize:number
    noiseTex:Texture
    partTex:Texture
    time:number;
    isFront:boolean

    constructor(scene:Scene, isFront = false) {
        this.isFront = isFront
        this.scene = scene
        const gl:GLContext = scene.gl
        this.node = new Node();
        const partNumber = isFront ? PARTICLE_NUMBER_FRONT : PARTICLE_NUMBER
        const particleArr = new Float32Array(partNumber * 4)

        this.noiseTex = new Texture(gl)
        this.noiseTex.fromImage(this.scene.game.resources.get('noise'))
        this.noiseTex.bind()
        this.noiseTex.setFilter(true, false, false)

        this.zPertubator = isFront ? 0 : 2
        this.maxFrontSize = isFront ? 110 : 0

        for (let i = 0; i < partNumber; i++) {
            particleArr[i * 3 + 0] = Math.random() * 2 - 1
            particleArr[i * 3 + 1] = Math.random() * 2 - 1
            particleArr[i * 3 + 2] = Math.random() * 2 - 1
            particleArr[i * 3 + 3] = 0.15 + Math.random() * 0.85
        }

        this.buffer = new ArrayBuffer(gl, particleArr)
        this.buffer.attrib( 'aPosition', 4, gl.FLOAT )

        if(isFront) {
            this.partTex = new Texture(this.scene.gl, this.scene.gl.RGBA)
            this.partTex.fromImage(window['resources'].get('particle'))
            this.partTex.bind()
            // this.sunTex.setFilter( false )
            this.partTex.wrap(this.scene.gl.CLAMP_TO_EDGE)
        }

        this.hasHighp = checkHighP( gl );

        this.particleProgram      = new Program(gl)

        this.compile()

        this.time = Math.random() * 100;
    }

    precision(){
      return this.hasHighp ? 'highp' : 'mediump';
    }


    compile( ) {
  
        var defs = '\n';
  
        defs += 'precision ' + this.precision() + ' float;\n';
        defs += `#define COLOR1 vec3(${255 / 255}, ${251 / 255}, ${227 / 255});\n`;
        defs += `#define COLOR2 vec3(${255 / 255}, ${224 / 255}, ${119 / 255});\n`;
        defs += `#define COLOR3 vec3(${225 / 255}, ${218 / 255}, ${70 / 255});\n`;
  
  
        this.particleProgram.compile(
            particleVert(),
            this.isFront ? particleTexFrag() : particleFrag(),
            defs
        );
        this.particleProgram.use();  
    }

    preRender(scroll:number, dt:number) {
        this.scroll = scroll
        this.time += dt;
    }

    render() {
        this.scene.glstate.push(this.isFront ? this.scene.game.baseConfig : this.scene.game.partCfg)
        this.scene.glstate.apply()
        this.particleProgram.use()
        this.particleProgram.uM(this.node._wmatrix)
        this.particleProgram.uVP(this.scene.game.getCamera()._viewProj)
        this.particleProgram.uScroll(this.scroll * 0.1)
        this.particleProgram.uZPertubator(this.zPertubator)
        this.particleProgram.uMaxFrontSize(this.maxFrontSize)
        this.particleProgram.uTime(this.time)
        this.particleProgram.uNoise(this.noiseTex)

        if(this.isFront) this.particleProgram.uParticleTex(this.partTex)

        this.buffer.attribPointer(this.particleProgram)
        this.buffer.drawPoints()

        this.scene.glstate.pop()
    }
}