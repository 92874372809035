import Chunk from "nanogl-pbr/Chunk";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import glsl_pre_vert from "./ribbon_pre.vert";
import glsl_vert from "./ribbon.vert";
import Texture from "nanogl/texture";
import glsl_pre_frag from "./ribbon_pre.frag";
import glsl_frag from "./ribbon.frag";

export default class RibbonEffect extends Chunk {
    noiseTex:Texture
    time:number
    radius:number
    alpha:number
    delay:number
  constructor(noiseTex:Texture) {
    super(true, true);
    this.noiseTex = noiseTex
    this.time = 0
  }

  setup(prg) {
    prg.uNoiseTex(this.noiseTex)
    prg.uTime(this.time)
    // prg.uRadius(this.radius)
    prg.uAlpha(this.alpha)
    prg.uDelay(this.delay)
  }

  _genCode(slots: ChunksSlots) {
    slots.add('pv', glsl_pre_vert());
    slots.add('vertex_warp_world', glsl_vert());
    slots.add('pf', glsl_pre_frag());
    slots.add('postf', glsl_frag());
  }

  _getHash(): string {
      return 'letter-effect';
  }

}
