import Gltf from '..';
import { Sampler } from 'nanogl-pbr/Input';
import TexCoord from 'nanogl-pbr/TexCoord';
export class BaseTextureInfo {
    constructor() {
        this._sampler = null;
    }
    createSampler(id) {
        if (this._sampler === null) {
            const attrib = Gltf.getSemantics().getAttributeName(`TEXCOORD_${this.texCoord}`);
            this._sampler = new Sampler(`tex_${name !== null && name !== void 0 ? name : ''}${id}`, TexCoord.create(attrib));
            this.texture.glTexturePromise.then((t) => this._sampler.set(t));
        }
        return this._sampler;
    }
    async parse(gltfLoader, data) {
        var _a;
        this.texture = await gltfLoader.getElement("textures" /* TEXTURE */, data.index);
        this.texCoord = (_a = data.texCoord) !== null && _a !== void 0 ? _a : 0;
    }
}
export default class TextureInfo extends BaseTextureInfo {
    constructor() {
        super(...arguments);
        this.gltftype = "textureInfo" /* TEXTURE_INFO */;
    }
}
