<template>
  <div class="game-tuto" :class="{ show }">
    <div class="game-tuto-inside">
      <video
        ref="video"
        src="/assets/videos/tuto.mp4#t=0.1"
        muted
        playsinline
      />
      <common-button
        :label="'Got it'"
        :small="true"
        :appear="900"
        @click="() => hideTuto(null, true)"
      />
    </div>
  </div>
</template>

<script>
import CommonButton from '@/components/CommonButton.vue'
import { onBeforeUnmount, onMounted, ref } from '@vue/runtime-core'
import timeout from '@/utils/timeout'
export default {
  components: {
    'common-button': CommonButton
  },
  emits: ['hide-tuto'],
  setup(props, ctx) {
    const video = ref(null)
    const show = ref(true)
    const count = ref(0)
    const hideTuto = async (event, isClick = false) => {
      if (count.value < 2 && !isClick) {
        video.value.play()
        count.value++
      } else {
        show.value = false
        await timeout(1000)
        ctx.emit('hide-tuto')
      }
    }

    onMounted(() => {
      count.value = 0
      show.value = true
      video.value.addEventListener('ended', hideTuto)
      video.value.play()
    })

    onBeforeUnmount(() => {
      video.value.removeEventListener('ended', hideTuto)
    })

    return { hideTuto, video, show }
  }
}
</script>

<style lang="scss">
.game-tuto {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(216, 99, 95, 0);
  transition: background 0.6s $ease-out-sine 0.4s;
  &-inside {
    border-radius: 15px;
    position: relative;
    opacity: 0;
    transition: opacity 1s $ease-out-sine;
    max-width: 504px;
    video {
      width: 100%;
      border-radius: 15px;
    }
    button {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
    .t-btn-primary--small {
      font-size: 12px;
    }
  }
  &.show {
    background: rgba(216, 99, 95, 0.7);
    .game-tuto-inside {
      opacity: 1;
    }
  }
}
</style>
