import { mat4, vec3, vec4 } from 'gl-matrix';
import vShader from './unlit.vert';
import fShader from './unlit.frag';
import MaterialPass from 'nanogl-pbr/MaterialPass';
import Flag from 'nanogl-pbr/Flag';
import Input, { Uniform } from 'nanogl-pbr/Input';
import { AlphaModeEnum, AlphaModes } from 'nanogl-pbr/AlphaModeEnum';
import Enum from 'nanogl-pbr/Enum';
import ShaderVersion from 'nanogl-pbr/ShaderVersion';
import ShaderPrecision from 'nanogl-pbr/ShaderPrecision';
import Texture from 'nanogl/texture';
import TexCoord from 'nanogl-pbr/TexCoord';
import SunPos from '../sunpos'
import Node from 'nanogl-node';
import Chunk from 'nanogl-pbr/Chunk';
import ChunksSlots from 'nanogl-pbr/ChunksSlots';
import GltfNode from "@webgl/lib/nanogl-gltf/lib/elements/Node";
const M4 = mat4.create();
const MAT_ID = 'multi_tex';

const NB_CLOUDS = 5

class ShadowsShaderCode extends Chunk {
    totalShadows:number

    constructor(totalShadows:number) {
      super(true, true);
      this.totalShadows = totalShadows
    }

    _genCode(slots: ChunksSlots) {
        let t:string = ''

        //pf
        t += `
        uniform vec4 uShadow[${this.totalShadows}];
        `

        slots.add('pf', t);

        //f
        t = ''
        t+= `vec4 tshadow;`
        t += `
        for (int i = 0; i < ${this.totalShadows}; i++) {
            // );
            vec2 locuvs = vec2(uvs.x, uvs.y);
            vec2 transuv = vec2(
                (-uShadow[i].x + 0.5),
                (-uShadow[i].z + 0.5)
            );
                        
            float fy = clamp(locuvs.y - transuv.y, 0., 1.);
            float fx = clamp(abs(locuvs.x - (transuv.x + (fy) * 0.05)), 0., 1.);
            vec2 steps = vec2(fx, fy);
            float stepX = 1. - step(0.005 * uShadow[i].y * (0.5 + smoothstep(0., 0.1, fy) * 0.5), steps.x);
            float stepY = smoothstep(0.008 * uScale * uShadow[i].w, 0.005 * uScale * uShadow[i].w, steps.y) * smoothstep(0.001, 0.0015 * uScale * uShadow[i].w, steps.y);
            sh += stepX * stepY;
        }
        sh = 1. - min(sh, 1.) * 0.2;
        `
        // }

        slots.add('f', t);

    }

    _getHash(): string {
        return 'shadow-ground-effect';
    }

}

class CloudsShaderCode extends Chunk {
    totalClouds:number
    constructor(totalClouds:number) {
        super(true, true);
        this.totalClouds = totalClouds
    }

    _genCode(slots: ChunksSlots) {
        let t:string = ''

        //pf
        t += `
        uniform sampler2D uForegroundShapes;
        uniform vec4 uClouds[${this.totalClouds}];
        `

        slots.add('pf', t);

        //f
        t = ''
        t+= `float cloud = 1.;`
        t += `
        for (int i = 0; i < ${this.totalClouds}; i++) {
            float uvxfract1 = mod(uScroll + uClouds[i].x + uvs.x * uScale, 2.) - 0.1;
            vec2 uvscloud = vec2(
                smoothstep(0.22 + (0.1 * uClouds[i].y), 0.3, uvxfract1), 
                smoothstep(0.75 + (uClouds[i].y * 0.25) , 1., uvs.y)
            );
  
            vec3 texCloud = texture2D(uForegroundShapes, uvscloud).rgb;

            float cloudr = texCloud.r * step(1., uClouds[i].z) * (1. - step(1.1, uClouds[i].z));
            float cloudg = texCloud.g * step(2., uClouds[i].z) * (1. - step(2.1, uClouds[i].z));
            float cloudb = texCloud.b * step(3., uClouds[i].z) * (1. - step(3.1, uClouds[i].z));

            float tcloud = (cloudr + cloudg + cloudb) * uClouds[i].w;

            cloud = min(1. - tcloud, cloud);
        }
        `
        // }

        slots.add('f', t);

    }

    _getHash(): string {
        return 'clouds-ground-effect';
    }
}

export default class MultiTexture extends MaterialPass {
    version: ShaderVersion;
    precision: ShaderPrecision;
    shaderid: Flag;
    baseColor3: Input;
    baseColor2: Input;
    baseColor: Input;
    baseColorFactor: Input;
    alpha: Input;
    alphaFactor: Input;
    alphaCutoff: Input;
    alphaMode: AlphaModeEnum;
    doubleSided: Flag;
    tex1:Texture;
    tex2:Texture;
    cloudTex:Texture;
    fgTex:Texture;
    stop:number;
    scroll:number;
    parallax:number;
    uBaseColor: vec3
    scale:number
    sunPos: SunPos
    
    //Cloud
    cloudOffsetY:number
    cloudScale:number
    cloudTimeScale:number
    cloudOpacity:number
    fgReset:boolean[]
    cloudReset:boolean
    cloudChannel:vec3
    shadowList:Node[]
    shadowListWP:vec3[]
    shadowListWPN:Float32Array
    cloudListWP:vec4[]
    cloudListWPN:Float32Array
    shadowInit:boolean
    invModelMat:mat4
    tempVec:vec3

    startScroll:number

    constructor(tex1:Texture, tex2:Texture, cloudTex:Texture, fgTex:Texture, name = 'unlit-pass') {
        super({
            uid: MAT_ID,
            vert: vShader(),    
            frag: fShader(),
        });
        this.stop = tex1.width / (tex1.width + tex2.width)
        this.tex1 = tex1
        this.tex2 = tex2

        this.cloudTex = cloudTex
        this.fgTex = fgTex
        const inputs = this.inputs;
        this.invModelMat = mat4.create()
        inputs.add(this.version = new ShaderVersion('100'));
        inputs.add(this.precision = new ShaderPrecision('highp'));
        inputs.add(this.shaderid = new Flag('id_' + MAT_ID, true));
        inputs.add(this.baseColor = new Input('baseColor', 3));
        inputs.add(this.baseColor2 = new Input('baseColor2', 3));
        inputs.add(this.baseColor3 = new Input('baseColor3', 3));
        inputs.add(this.baseColorFactor = new Input('baseColorFactor', 3));
        inputs.add(this.alpha = new Input('alpha', 1));
        inputs.add(this.alphaFactor = new Input('alphaFactor', 1));
        inputs.add(this.alphaCutoff = new Input('alphaCutoff', 1));
        inputs.add(this.alphaMode = new Enum('alphaMode', AlphaModes));
        inputs.add(this.doubleSided = new Flag('doubleSided', false));
        this.sunPos = new SunPos()
        inputs.add(this.sunPos)

        this.tempVec = vec3.create()


        const tCoord:TexCoord = TexCoord.create('aTexCoord' )
        this.baseColor.attachSampler( 'basecolor', tCoord ).set( tex1 )
        this.baseColor2.attachSampler( 'basecolor2', tCoord ).set( tex2 )
        this.baseColor3.attachSampler( 'basecolor3', tCoord ).set( cloudTex )
        const total = tex1.width + tex2.width
        this.scale = (tex1.width + tex2.width) / tex1.height
        this.cloudChannel = vec3.create()

        this.startScroll = Math.random() * 100

        this.cloudListWP = []
        this.cloudListWPN = new Float32Array(NB_CLOUDS * 4)
        for (let i = 0; i < NB_CLOUDS; i++) {
            this.cloudListWP.push(vec4.create())
        }

        const shadowCCode = new CloudsShaderCode(NB_CLOUDS)
        this.inputs.add(shadowCCode)

        this.scroll = this.startScroll

        this.randomForegrounds()
        this.randomCloud()
    }

    shadowsReady(shadowList:Node[]) {
        this.shadowList = shadowList
        this.shadowListWP = []
        this.shadowListWPN = new Float32Array(this.shadowList.length * 4)
        for (let i = 0; i < this.shadowList.length; i++) {
            this.shadowListWP.push(vec3.create())
        }

        const shadowSCode = new ShadowsShaderCode(this.shadowList.length)
        this.inputs.add(shadowSCode)

        this.shadowInit = true
    }

    preRender(scroll:number, speed:number, node:Node) {
        mat4.invert(this.invModelMat, node._wmatrix)
        this.scroll = this.startScroll + scroll * 0.1 * speed
        for (let i = 0; i < this.cloudListWP.length; i++) {
            const fgTime = (this.scroll + this.cloudListWP[i][0]) % 2 - 0.1
            if(fgTime > 1.1 && !this.fgReset[i]) {
                this.randomForeground(i)
                this.fgReset[i] = true
            }

            if(fgTime > 1.2 && this.fgReset[i]) {
                this.fgReset[i] = false
            }
            
        }

        const cloudTime = (this.cloudTimeScale - this.scroll) % 1

        // console.log(cloudTime)
        
        if(cloudTime < 0.23) {
            this.randomCloud()
        }

        // if(cloudTime > 1 && this.cloudReset) {
        //     this.cloudReset = false
        // }
    }

    randomForegrounds() {
        this.fgReset = []
        for (let i = 0; i < this.cloudListWP.length; i++) {
            this.cloudListWP[i][0] = i / (this.cloudListWP.length - 1) * 2 + (Math.random() * 2 - 1) * 0.01;
            this.randomForeground(i)
            this.fgReset.push(false)
            
        }
    }

    randomForeground(i:number) {
        this.cloudListWP[i][1] = 0.2 + Math.random() * 0.4;
        this.cloudListWP[i][2] = 1 + Math.floor(Math.random() * 3)
        this.cloudListWP[i][3] = 0.8 + Math.random() * 0.2
    }

    randomCloud() {
        const rand = Math.floor(Math.random() * 3);
        vec3.set(this.cloudChannel, rand === 0 ? 1 : 0, rand === 1 ? 1 : 0, rand === 2 ? 1 : 0)
        this.cloudScale = 0.6 + Math.random() * 0.4
        this.cloudTimeScale = (this.scroll + 0.6)
        this.cloudOffsetY = -0.15 + Math.random() * 0.3
        this.cloudOpacity = 0.5 + Math.random() * 0.2
    }

    prepare(prg, node, camera) {
        prg.uM(node._wmatrix);
        prg.uVP(camera._viewProj);
        prg.uStop(this.stop)
        prg.basecolor(this.tex1)
        prg.basecolor2(this.tex2)
        prg.basecolor3(this.cloudTex)
        prg.uForegroundShapes(this.fgTex)
        prg.uScroll(this.scroll)
        prg.uScale(this.scale)
        if(!this.shadowInit) return

        prg.uCloudScale(this.cloudScale)
        prg.uCloudTimeScale(this.cloudTimeScale)
        prg.uCloudOffsetY(this.cloudOffsetY)
        prg.uCloudChannel(this.cloudChannel)
        prg.uCloudOpacity(this.cloudOpacity)
        mat4.invert(M4, node._wmatrix)
        for (let i = 0; i < this.shadowList.length; i++) {
            let isRendered = false
            if(
                this.shadowList[i]._parent._parent && 
                (this.shadowList[i]._parent._parent as GltfNode)) {
                let pp = this.shadowList[i]._parent?._parent as GltfNode
                isRendered = pp.extras?.isRendered
            }
            vec3.set(this.tempVec, 0, 0, 0)
            //worldspace
            vec3.transformMat4(this.tempVec, this.shadowList[i].position, this.shadowList[i]._wmatrix)
            //ground local space
            vec3.transformMat4(this.shadowListWP[i], this.tempVec, M4)
            this.shadowListWPN[i * 4 + 0] = isRendered ? this.shadowListWP[i][0] : -10000
            this.shadowListWPN[i * 4 + 1] = this.shadowList[i].scale[0]//isRendered ? this.shadowList[i].scale[1] : -10000;
            this.shadowListWPN[i * 4 + 2] = this.shadowListWP[i][1]
            this.shadowListWPN[i * 4 + 3] = this.shadowList[i].scale[1]
            // if(i === 9) console.log(
            //     this.shadowList[i]._parent['name'],
            //     (this.shadowListWPN[i * 4 + 0] + 1) * 0.5,
            //     (this.shadowListWPN[i * 4 + 2] + 1) * 0.5)
        }
        prg.uShadow(this.shadowListWPN)
        // prg.uTime((Date.now() * 0.0005) % 1)

        for (let i = 0; i < this.cloudListWP.length; i++) {
            this.cloudListWPN[i * 4 + 0] = this.cloudListWP[i][0]
            this.cloudListWPN[i * 4 + 1] = this.cloudListWP[i][1]
            this.cloudListWPN[i * 4 + 2] = this.cloudListWP[i][2]
            this.cloudListWPN[i * 4 + 3] = this.cloudListWP[i][3]
        }
        prg.uClouds(this.cloudListWPN)

        this.sunPos.setup(prg)
    }
}
;
