import store from "@/store";
import Masks from "@/webgl/gl/masks";
import { Passes } from "@/webgl/glsl/Passes";
import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import Scene from "@/webgl/scene";
import { vec3 } from "gl-matrix";
import Node from "nanogl-node";
import GltfNode from "@webgl/lib/nanogl-gltf/lib/elements/Node";
import GLTFAnim from "@webgl/lib/nanogl-gltf/lib/elements/Animation"
import timeout from "@/utils/timeout";

import { computed, watch } from 'vue'
import gameStatic from "../../gameStatic";
import IRenderable from "@/webgl/lib/nanogl-gltf/lib/renderer/IRenderable";

const SMOOTH = 0.1
const REG_SPEED = 0.005

export default class BisonController {
    gltf:Gltf
    scene:Scene
    pronghorn:GltfNode
    clouds:GltfNode
    level:GltfNode
    cloudsXStart:number
    pronghornSuperRun:GLTFAnim
    isEndAnim:boolean
    currObj:number
    time:number
    temp:vec3
    temp2:vec3
    dir:vec3
    dirL:vec3
    inGame:boolean
    endScore:number
    levelNB:number
    watchResult
    constructor(gltf:Gltf, scene:Scene) {
        this.gltf = gltf
        this.scene = scene
        this.inGame = false
        this.levelNB = 0
        const c = computed(() => store.getters['game/isResultMode'])

        this.clouds = this.gltf.getElementByName(GltfTypes.NODE, 'Clouds')
        this.cloudsXStart = this.clouds.x

        this.watchResult = watch(c, () => {
            if(store.getters['game/isResultMode'] && this.inGame) this.setEndAnim()
        })
    }

    start() {
        store.commit('game/setTempScore', { score: 0 })
        this.inGame = true
        this.scene.game.root.add(this.gltf.root)
        this.scene.game.root.updateWorldMatrix()
        let node:Node
        let count:number = 1
        this.temp = vec3.create()
        this.temp2 = vec3.create()
        this.dir = vec3.create()
        this.dirL = vec3.create()
        const bisonsPosition:Array<Object> = []
        this.isEndAnim = false
        this.pronghorn = this.gltf.getElementByName(GltfTypes.NODE, 'pronghornInside')
        this.clouds.x = this.cloudsXStart
        this.pronghornSuperRun = this.gltf.getElementByName<GltfTypes.ANIMATION>(GltfTypes.ANIMATION, 'FastRun23')
        this.time = 0

        this.levelNB = store.getters['game/level'] + 1
        // if(this.levelNB > 3) this.levelNB = 1

        this.level = this.gltf.getElementByName(GltfTypes.NODE, 'bisons' + this.levelNB)

        vec3.copy(this.pronghorn.position, this.gltf.getElementByName(GltfTypes.NODE, 'startPoint' + this.levelNB).position)
        this.pronghorn.invalidate()
        
        const v = vec3.create()
        const { width, height } = store.getters['global/getViewportSize']
        const forceLandscape = store.getters['global/forceLandscape']
        const img = this.gltf.getElementByName(GltfTypes.NODE, 'image')
        const imgH:number = this.gltf.cameraInstances[0].lens['_yMax'] * 2
        const imgRatio = img.scale[0] / img.scale[1]
        const scaleImg = imgH / img.scale[1];
        const scaleImgX= (imgRatio * imgH) / img.scale[0];
        vec3.set(img.scale, imgRatio * imgH, imgH, img.scale[2])
        img.invalidate()
        this.gltf.cameraInstances[0].updateViewProjectionMatrix(width, height)
        do {
            node = this.gltf.getElementByName(GltfTypes.NODE, 'point'+this.levelNB+count)
            if(node) {
                vec3.set(node.position, node.position[0] * scaleImgX, 0, node.position[2]  * scaleImg)
                node.invalidate()
                node.updateWorldMatrix()
                vec3.transformMat4(v, node.position,this.gltf.cameraInstances[0]._viewProj)
                const x = this.getScreenXValue(v, forceLandscape ? height : width)
                const y = this.getScreenYValue(v, forceLandscape ? width : height)
                bisonsPosition.push({ x, y, time: 0, started: false })
            }
            count++
        } while(node)

        store.commit('game/setBisonsPosition', { bisonsPosition })
    }

    async setEndAnim() {
        if(store.getters['game/percSuccess'] >= 0.9) {
            this.scene.game.toggleSoundLayers(false)
            const winSound = this.scene.game.howler.$getSound('qte-win', true).play()
            this.scene.game.howler.$getSound('qte-win', true).once('end', () => this.scene.game.toggleSoundLayers(true))
        }
        this.currObj = 0;
        const obj:GltfNode = this.gltf.getElementByName(GltfTypes.NODE, 'point'+this.levelNB+(this.currObj + 1))
        vec3.set(this.dir, obj.x - this.pronghorn.x, 0, obj.z - this.pronghorn.z)
        vec3.normalize(this.dir, this.dir)
        this.endScore = store.getters['game/tempScore']
        vec3.scale(this.dir, this.dir, REG_SPEED + (this.endScore / (50000 * gameStatic.scoreScale)) * SMOOTH)
        vec3.copy(this.dirL, this.dir)
        this.isEndAnim = true
        store.commit('game/setaddScoreQte')
        // store.commit('game/addToScore', { score: this.endScore })
        // store.commit('game/setTempScore', { score: 0 })
    }

    stop() {
        this.inGame = false
        this.scene.game.root.remove(this.gltf.root)

        store.commit('game/setBisonsPosition', { bisonsPosition: [] })
        store.commit('game/setTempScore', { score: 0 })
        gameStatic.speed = 0.5 + store.getters['game/percSuccess'] * 0.25
    }

    async updateNodes(dt:number, globalSpeed:number, changeSpeed:number) {
        if(this.isEndAnim) {
            this.pronghornSuperRun.evaluate(this.time % this.pronghornSuperRun.duration)
            this.time += dt;
            const obj:GltfNode = this.gltf.getElementByName(GltfTypes.NODE, 'point'+this.levelNB+(this.currObj + 1))
            vec3.lerp(this.dirL, this.dirL, this.dir, dt * 10)
            vec3.add(this.pronghorn.position, this.pronghorn.position, this.dirL)

            vec3.add(this.temp, this.pronghorn.position, this.dirL)

            this.pronghorn.lookAt(this.temp)
            this.pronghorn.rotateY(-Math.PI * 0.5)
            if(Math.abs(obj.x - this.pronghorn.x) < 0.08 && Math.abs(obj.z - this.pronghorn.z) < 0.08) {
                if(this.currObj === 3) {
                    const obj:GltfNode = this.gltf.getElementByName(GltfTypes.NODE, 'lastPoint'+this.levelNB)
                    vec3.set(this.dir, obj.x - this.pronghorn.x, 0, obj.z - this.pronghorn.z)
                    vec3.normalize(this.dir, this.dir)
                    vec3.scale(this.dir, this.dir, REG_SPEED + (this.endScore / (50000 * gameStatic.scoreScale)) * SMOOTH)
                    await timeout(500)
                    store.commit('game/setQTE', { qte: -1 })
                }
                else {
                    this.currObj++;
                    const obj:GltfNode = this.gltf.getElementByName(GltfTypes.NODE, 'point'+this.levelNB+(this.currObj + 1))
                    vec3.set(this.dir, obj.x - this.pronghorn.x, 0, obj.z - this.pronghorn.z)
                    vec3.normalize(this.dir, this.dir)
                    vec3.scale(this.dir, this.dir, REG_SPEED + (this.endScore / (50000 * gameStatic.scoreScale)) * SMOOTH)
                }
            }
        }
    }

    preRender(dt:number) {
        this.clouds.x -= 0.001
    }

    getScreenXValue(v:vec3, width:number, invert:number = 1):number {
        return (invert * v[0] + 1) / 2 * width
    }

    getScreenYValue(v:vec3, height:number, invert:number = -1):number {
        return (invert * v[1] + 1) / 2 * height
    }

    render() {
        for (const renderable of this.gltf.renderables) {
            if((renderable.node._parent as GltfNode).name === this.level.name || renderable.node.name !== 'image') {
                renderable.render(this.scene, this.gltf.cameraInstances[0], Masks.OPAQUE , Passes.COLOR)
                renderable.render(this.scene, this.gltf.cameraInstances[0], Masks.BLENDED , Passes.COLOR, this.scene.game.baseConfig)
            }
        }
    }

    release() {
        this.watchResult()
    }
}