import NGLNode from 'nanogl-node';
import MeshRenderer from '../renderer/MeshRenderer';
export default class Node extends NGLNode {
    constructor() {
        super(...arguments);
        this.gltftype = "nodes" /* NODE */;
        this.weights = null;
    }
    async parse(gltfLoader, data) {
        var _a, _b;
        // super.parse();
        // this.uuid         = data.uuid;
        // this.elementIndex = data.elementIndex;
        // this.gltf         = gltfLoader.gltf;    
        this.extras = data.extras;
        // this.extensions   = data.extensions
        if (data.camera !== undefined)
            this.camera = await gltfLoader.getElement("cameras" /* CAMERA */, data.camera);
        if (data.matrix !== undefined)
            this.setMatrix(new Float32Array(data.matrix));
        if (data.scale !== undefined)
            this.scale.set(data.scale);
        if (data.translation !== undefined)
            this.position.set(data.translation);
        if (data.rotation !== undefined)
            this.rotation.set(data.rotation);
        if (data.children !== undefined) {
            const childPromises = data.children.map((index) => gltfLoader.getElement("nodes" /* NODE */, index));
            const children = await Promise.all(childPromises);
            for (var child of children) {
                this.add(child);
            }
        }
        if (data.skin !== undefined) {
            this.skin = await gltfLoader.getElement("skins" /* SKIN */, data.skin);
        }
        if (data.mesh !== undefined) {
            this.mesh = await gltfLoader.getElement("meshes" /* MESH */, data.mesh);
            const targets = this.mesh.primitives[0].targets;
            if (targets) {
                this.weights = new Float32Array(targets.length);
            }
        }
        if (data.weights !== undefined) {
            if (this.weights === null || this.weights.length !== data.weights.length) {
                throw new Error("morph weights on node doesn't match mesh targets");
            }
            this.weights.set(data.weights);
        }
        this.name = (_a = data.name) !== null && _a !== void 0 ? _a : ((_b = this.mesh) === null || _b === void 0 ? void 0 : _b.name);
        this.invalidate();
    }
    findChild(name) {
        for (let i = 0; i < this._children.length; i++) {
            const child = this._children[i];
            if (child.name === name)
                return child;
        }
        return undefined;
    }
    findDescendant(name) {
        var _a;
        let res = this.findChild(name);
        if (res === undefined) {
            for (let i = 0; i < this._children.length; i++) {
                const child = this._children[i];
                res = (_a = child.findDescendant) === null || _a === void 0 ? void 0 : _a.call(child, name);
                if (res !== undefined)
                    return res;
            }
        }
        return res;
    }
    allocateGl(gl) {
        if (this.mesh) {
            this.renderable = new MeshRenderer(gl, this);
        }
    }
}
