<template>
  <div v-if="active" class="game-loader">
    <div class="game-loader-background" />

    <div class="game-loader-inner">
      <div class="game-loader-title-container">
        <p>
          <span>
            THE DASH
            <span class="overlay">THE DASH</span>
          </span>
        </p>
        <p>
          <span>
            FOR DONATIONS
            <span class="overlay">FOR DONATIONS</span>
          </span>
        </p>
      </div>

      <hr class="game-loader-divider" />

      <p class="game-loader-text">{{ t('GameLoader.txt-gl') }}</p>

      <!-- <div class="game-loader-headphones">
        <headphones-icon />
        <span v-html="t('Loader.enable-audio')" />
      </div> -->
    </div>

    <div class="game-loader-footer">
      <circle-loader
        v-if="showCircle"
        ref="circleLoader"
        :appear="1000"
        :dynamic="true"
      />
      <svg
        width="812"
        height="111"
        viewBox="0 0 812 111"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-7 0s227.452 16.615 409.441 16.615C584.43 16.615 811.882 0 811.882 0v110.646H-7V0z"
          fill="#FFECD2"
        />
      </svg>
    </div>
    <!-- <div class="debug">Test</div> -->
  </div>
</template>

<script lang="ts">
import {
  inject,
  onActivated,
  onDeactivated,
  onMounted,
  onUnmounted,
  ref
} from 'vue'
import CircleLoader from '@/components/CircleLoader.vue'
// import HeadphonesIcon from '@/assets/svg/headphones.svg'
import anime from 'animejs/lib/anime.es.js'
import { watchResize } from '@/hooks/global'
import store from '@/store'
import timeout from '@/utils/timeout'
import { useGtm } from 'vue-gtm'

export default {
  name: 'GameLoader',
  components: {
    CircleLoader
    // HeadphonesIcon
  },
  setup() {
    const t = inject('$t')
    const active = ref(true)
    const showCircle = ref(false)
    const circleLoader = ref(null)
    const gtm = useGtm()

    const tl = anime.timeline({
      delay: 0
    })

    let unwatch = null

    onMounted(() => {
      active.value = true
      onEnter()
      runAnim()

      unwatch = watchResize((w, h, isLandscape) => {
        runAnim()
      })
    })

    onUnmounted(() => {
      unwatch()
      showCircle.value = false
      active.value = false
    })

    const runAnim = () => {
      if (store.getters['global/forceLandscape']) {
        anime.set('.game-loader-title-container', {
          opacity: 1
        })
        tl.play()
        showCircle.value = true
      } else {
        if (store.getters['global/getOrientation']) {
          tl.pause()
          showCircle.value = false
        } else {
          anime.set('.game-loader-title-container', {
            opacity: 1
          })
          tl.play()
          showCircle.value = true
        }
      }
    }

    const onEnter = () => {
      gtm.trackEvent({
        event: 'vpv',
        VPV: 'gameLoader'
      })
      anime.set('.game-loader-title-container', {
        opacity: 1
      })

      tl.add(
        {
          duration: 1200,
          targets: '.game-loader-background',
          easing: 'linear',
          opacity: [0, 1]
        },
        0
      )
        .add(
          {
            duration: 3000,
            targets: '.game-loader-background',
            easing: 'easeOutCubic',
            scale: [1.1, 1]
          },
          0
        )
        // Footer animation
        .add(
          {
            targets: '.game-loader-footer',
            duration: 1367,
            translateY: ['100%', 0],
            easing: 'cubicBezier(0.78, 0, 0.14, 1)'
          },
          700
        )

        .add(
          {
            targets: '.game-loader-headphones',
            duration: 760,
            opacity: [0, 1],
            easing: 'linear'
          },
          1400
        )
        .add(
          {
            targets: '.game-loader-title-container p > span',
            duration: 1433,
            translateY: [60, 0],
            easing: 'cubicBezier(0.51, 0, 0.18, 1)',
            delay: anime.stagger(133)
          },
          500
        )
        .add(
          {
            targets: '.game-loader-divider',
            duration: 733,
            scaleX: [0, 1],
            easing: 'cubicBezier(0.09, 0.55, 0.1, 1)'
          },
          1200
        )
        .add(
          {
            targets: '.game-loader-text',
            duration: 733,
            opacity: [0, 1],
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          1200
        )
    }

    const onLeave = async cb => {
      // anime({
      //   targets: '.game-loader',
      //   duration: 800,
      //   easing: 'linear',
      //   opacity: 0,
      //   complete: () => cb
      // })

      await timeout(500)
      if (circleLoader.value && showCircle) circleLoader.value.onLeave()
      const tl = anime.timeline({
        delay: 0,
        complete: () => {
          cb()
        }
      })
      tl.add(
        {
          targets: '.game-loader-footer',
          duration: 1367,
          translateY: '100%',
          easing: 'cubicBezier(0.9, 0, 0.1, 1)'
        },
        0
      )
        .add(
          {
            targets: '.game-loader-title-container',
            duration: 1119,
            opacity: 0,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          100
        )
        .add(
          {
            targets: '.game-loader-divider',
            duration: 1119,
            opacity: 0,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          130
        )

        .add(
          {
            targets: '.game-loader-text',
            duration: 1119,
            opacity: 0,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          160
        )
        .add(
          {
            targets: '.game-loader-headphones',
            duration: 1119,
            opacity: 0,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          190
        )
        .add(
          {
            targets: '.game-loader',
            duration: 800,
            easing: 'linear',
            opacity: 0
          },
          600
        )
    }

    return { t, showCircle, circleLoader, onLeave, active }
  }
}
</script>

<style lang="scss" scoped>
.game-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  background-color: color(pale-white);

  &-background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/images/game-loader-bg.jpg);
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
  }

  &-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 80px);
  }

  &-title-container {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;

    p {
      margin: 0px;
      overflow: hidden;

      &:nth-child(1) .overlay {
        background: radial-gradient(
          ellipse at 50% 200%,
          #f9d728,
          rgb(0 0 0 / 0%) 100%
        );
        background-clip: text;
      }
      &:nth-child(2) .overlay {
        background: radial-gradient(
          ellipse at 50% 150%,
          #f9d728,
          rgb(0 0 0 / 0%) 100%
        );
        background-clip: text;
      }
    }

    span {
      font-family: font(heading);
      font-size: fontSizeHeading(h2);
      display: block;
      color: #9d1d29;
      position: relative;
      text-shadow: 0px 3px 0px #f8d27e;
      width: 100%;

      .overlay {
        position: absolute;
        transform: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: radial-gradient(
          ellipse at 50% 100%,
          #f9d728,
          rgb(0 0 0 / 0%) 100%
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
        mix-blend-mode: overlay;
        text-shadow: none;
      }
    }

    // @include respond-to(xs) {
    //   padding-top: 97px;
    // }
  }

  &-divider {
    width: 80px;
    margin: 23px auto;

    border: 0;
    border-bottom: 1px solid color(border);

    @include respond-to-height(xxs) {
      margin: 14px auto;
    }
  }

  &-text {
    margin: 0 60px 20px;
    text-align: center;
    max-width: 600px;

    color: #e93a32;

    font-size: fontSizeHeading(h6);
    line-height: 1.5;

    @include respond-to-height(xxs) {
      margin: 0 23px 20px;
      font-size: fontSize(sm);
    }

    @include respond-to(xs, xs) {
      margin: 0 23px 20px;
      font-size: fontSize(sm);
    }
  }

  &-headphones {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    color: color(dark-red);

    font-size: 9px;
    text-transform: uppercase;

    svg {
      width: auto;
      height: 15px;
      margin-bottom: 7px;
    }

    @include respond-to-height(xxs) {
      svg {
        display: none;
      }
    }
  }

  &-footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 90px;

    .circle-loader {
      position: absolute;
      top: 0%;
      left: 50%;
      z-index: 2;
      transform: translate(-50%, -20%) scale(0.7);
    }

    svg {
      width: 100%;
      height: auto;
      transform: translateY(20px);
    }
  }
}
</style>
