
import { computed, onMounted, reactive, ref, watch } from 'vue'
import store from '@/store'
import Point from '@/assets/svg/point.svg'
const lerp = require('lerp')
import {
  DODGE_ALPHA_THRESHOLD,
  DODGE_CANVAS_SIZE,
  DODGE_NB_GLYPHS,
  DODGE_START_POS
} from '@/webgl/game/gameConfig'
import timeout from '@/utils/timeout'
import gameStatic from '@/webgl/game/gameStatic'
import game from '@/store/game'

export default {
  components: {
    point: Point
  },
  setup() {
    //Canvas where we draw where the user already drawn
    const sourceCanvas = ref(null)
    let ctxSource = null

    //Display canvas
    const displayCanvas = ref(null)
    let ctxDisplay = null

    //Gesture Canvas
    const gestureCanvas = ref(null)
    let ctxGesture = null

    const total = ref(0)
    const countdown = ref(0)
    const isIntro = ref(false)
    const isStarted = ref(false)
    const isTouched = ref(false)
    const hideInterface = ref(false)
    const dpr = ref(1)

    const forceLandscape = computed(
      () => store.getters['global/forceLandscape']
    )

    const size = computed(() => store.getters['global/getViewportSize'])

    const resultMode = computed(() => store.getters['game/isResultMode'])

    // const resultWatch = watch(resultMode, (isResults: boolean) => {
    //   if (isResults) store.commit('game/addToScore', { score: total.value })
    // })

    const touch = reactive({
      x: 0,
      y: 0,
      offsetX: 0,
      offsetY: 0,
      drawing: false,
      currX: 0,
      currY: 0,
      prevX: 0,
      prevY: 0,
      rot: 0
    })
    const randGlyph = store.getters['game/level'] //Math.floor(Math.random() * DODGE_NB_GLYPHS)
    const img = window['resources'].get('glyph0' + (1 + randGlyph))
    const imgShow = window['resources'].get(
      'glyph0' + (1 + randGlyph) + '-show'
    )

    onMounted(async () => {
      isTouched.value = false
      isIntro.value = false
      isStarted.value = false
      countdown.value = 0
      resize()
      const fullSize = getFullSize()
      total.value = 0
      store.commit('game/setQteSuccess', { percSuccess: 0 })

      gestureCanvas.value = document.createElement('canvas')
      sourceCanvas.value = document.createElement('canvas')

      displayCanvas.value.width = gestureCanvas.value.width = sourceCanvas.value.width = getFullSize(
        true
      )
      displayCanvas.value.height = gestureCanvas.value.height = sourceCanvas.value.height = getFullSize(
        true
      )

      displayCanvas.value.style.width = gestureCanvas.value.style.width = sourceCanvas.value.style.width =
        getFullSize() + 'px'
      displayCanvas.value.style.height = gestureCanvas.value.style.height = sourceCanvas.value.style.height =
        getFullSize() + 'px'

      ctxSource = sourceCanvas.value.getContext('2d')
      ctxGesture = gestureCanvas.value.getContext('2d')
      ctxDisplay = displayCanvas.value.getContext('2d')

      dpr.value = window.devicePixelRatio || 1
      ctxSource.scale(dpr.value, dpr.value)
      ctxGesture.scale(dpr.value, dpr.value)
      ctxDisplay.scale(dpr.value, dpr.value)

      ctxSource.drawImage(img, 0, 0, fullSize, fullSize)
      resize()

      touch.currX = DODGE_START_POS[randGlyph].x * getFullSize()
      touch.currY = DODGE_START_POS[randGlyph].y * getFullSize()
      touch.rot = DODGE_START_POS[randGlyph].rot

      await timeout(1700)
      isIntro.value = true
      await timeout(700)
      isStarted.value = true
    })

    const touchstart = (event: TouchEvent) => {
      if (!isStarted.value) return
      isTouched.value = true
      const t: Touch =
        event.touches && event.touches.length > 0 && event.touches[0]
      touch.x = forceLandscape.value ? t.clientY : t.clientX
      touch.y = forceLandscape.value ? size.value.width - t.clientX : t.clientY
      touch.drawing = true
      ctxGesture.strokeStyle = 'rgb(255,0,0)'
      ctxGesture.lineJoin = 'round'
      ctxGesture.lineCap = 'round'
      ctxGesture.lineWidth = 15
      touch.currX = touch.x - touch.offsetX
      touch.currY = touch.y - touch.offsetY
      touch.prevX = touch.currX
      touch.prevY = touch.currY
      ctxGesture.moveTo(touch.currX, touch.currY)
    }

    const touchmove = (event: TouchEvent) => {
      if (!isStarted.value) return
      const t: Touch =
        event.touches && event.touches.length > 0 && event.touches[0]
      touch.x = forceLandscape.value ? t.clientY : t.clientX
      touch.y = forceLandscape.value ? size.value.width - t.clientX : t.clientY
    }

    const touchend = () => {
      if (!isStarted.value) return
      touch.drawing = false
      hideInterface.value = true
    }

    const update = (dt: number) => {
      if (!ctxDisplay) return
      const fullSize = getFullSize(true)
      dt
      if (isStarted.value && !resultMode.value) countdown.value += dt
      const t = Math.min(1, Math.max(countdown.value / 4000, 0))

      if (t >= 1 && !resultMode.value) {
        store.commit('game/setToQteResult')
        hideInterface.value = true
      }
      //Clear gesture with alpha
      ctxGesture.save()
      ctxGesture.globalAlpha = 0
      ctxGesture.globalCompositeOperation = 'destination-out'
      ctxGesture.fillStyle = '#FFF'
      ctxGesture.fillRect(0, 0, fullSize, fullSize)
      ctxGesture.restore()

      displayCanvas.value.width = displayCanvas.value.width * 1
      ctxDisplay.drawImage(imgShow, 0, 0, fullSize, fullSize)
      ctxDisplay.save()
      ctxDisplay.globalCompositeOperation = 'overlay'
      // ctxDisplay.drawImage(sourceCanvas.value, 0, 0, fullSize, fullSize)
      ctxDisplay.drawImage(gestureCanvas.value, 0, 0, fullSize, fullSize)
      ctxDisplay.restore()

      if (touch.drawing) {
        const x: number = touch.x - touch.offsetX
        const y: number = touch.y - touch.offsetY
        if (x > 0 && x < fullSize && y > 0 && y < fullSize && touch.drawing) {
          const rgb: ImageData = ctxSource.getImageData(
            x * dpr.value,
            y * dpr.value,
            1,
            1
          )
          const distX = x - touch.prevX
          const distY = y - touch.prevY
          const dist = Math.floor(Math.sqrt(distX * distX + distY * distY))
          if (rgb.data[3] > DODGE_ALPHA_THRESHOLD && rgb.data[2] < 200) {
            // total.value += Math.floor(Math.max(1, dist) * (rgb.data[3] / 255))
            const score =
              store.getters['game/tempScore'] +
              Math.round(
                Math.floor(Math.max(1, dist) * (rgb.data[3] / 255)) *
                  gameStatic.duckScore *
                  gameStatic.scoreScale
              )
            store.commit('game/setTempScore', { score })
            store.commit('game/setQteSuccess', {
              percSuccess: Math.min(1, score / (1200 * gameStatic.scoreScale))
            })
            ctxSource.fillStyle = 'rgb(0, 0, 255)'
            ctxSource.fillRect(x - 10, y - 10, 20, 20)
          } else if (rgb.data[3] < DODGE_ALPHA_THRESHOLD) {
            total.value = Math.max(0, total.value - Math.floor(dist / 2))
          }

          // touch.rot =
          //   (Math.atan2(y - touch.prevY, x - touch.prevX) / Math.PI) * 180
          touch.currX = lerp(touch.currX, x, 0.1)
          touch.currY = lerp(touch.currY, y, 0.1)
          ctxGesture.lineTo(touch.currX, touch.currY)
          ctxGesture.stroke()
        }
        touch.prevX = x
        touch.prevY = y
      }
    }

    const resize = () => {
      const rect: ClientRect = displayCanvas.value.getBoundingClientRect()
      touch.offsetX = forceLandscape.value ? rect.top : rect.left
      touch.offsetY = forceLandscape.value ? rect.left : rect.top
      document.documentElement.style.setProperty(
        '--dodge-canvas-size',
        getFullSize() + 'px'
      )

      touch.x = DODGE_START_POS[randGlyph].x + touch.offsetX
      touch.y = DODGE_START_POS[randGlyph].y + touch.offsetY
    }

    const getFullSize = (useDpr = false) => {
      var dpr = window.devicePixelRatio || 1
      if (!useDpr) dpr = 1
      return Math.min(
        DODGE_CANVAS_SIZE * dpr,
        store.getters['global/getViewportSize'].height * dpr
      )
    }

    return {
      forceLandscape,
      size,
      displayCanvas,
      sourceCanvas,
      gestureCanvas,
      countdown,
      dpr,
      isIntro,
      isTouched,
      isStarted,
      hideInterface,
      touchstart,
      touchmove,
      touchend,
      update,
      touch,
      total,
      resultMode
      // resultWatch
    }
  }
}
