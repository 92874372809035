import TextureExtensions from "./TextureExtensions";
import TextureData from "./TextureData";
import { BaseTextureResource } from "./TextureResource";
import { ITextureRequest, ITextureRequestSource, ITextureRequestLod } from "./TextureRequest";
import { TextureType } from "nanogl/texture-base";
import Deferred from "@/utils/Deferred";


export interface ITextureCodec {

  name : string;
  isSupported( extensions : TextureExtensions ) : boolean;
  // createTextureData( textureResource : BaseTextureResource, source : ITextureRequestSource ) : TextureData;
  decodeLod( source : ITextureRequestSource, lod:number, extensions: TextureExtensions ) : Promise<any>;
  decodeCube(source : ITextureRequestSource, extensions: TextureExtensions );

}




export class TextureCodecs {

  private static _codecs : Record<string, ITextureCodec> = {};

  static registerCodec( codec : ITextureCodec ) : void {
    if( this._codecs[codec.name] !== undefined ){
      console.warn( `TextureCodec.registerCodec() Codec ${codec.name} already exist` );
      return;
    }
    this._codecs[codec.name] = codec;
  }

  
  static getCodecForRequest( request : ITextureRequest, extensions: TextureExtensions ) : [ITextureCodec,ITextureRequestSource]  | null {
    const sources = request.sources;
    for (const source of sources ) {
      const codec = this._codecs[source.codec];
      if( codec !== undefined && codec.isSupported( extensions ) ) {
        return [codec, source];
      }
    }
    return null;
  }
}


