import Masks from "@/webgl/gl/masks";
import { Passes } from "@/webgl/glsl/Passes";
import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import Scene from "@/webgl/scene";
import GltfNode from "@webgl/lib/nanogl-gltf/lib/elements/Node";
import ShadowPass from "@/webgl/glsl/shadowPass";
import { BACK_FENCE_SLOWMO, SPEED_RUN } from '@webgl/game/gameConfig'
import Texture from "nanogl/texture";
import BaseMaterial from "nanogl-pbr/BaseMaterial";
import Node from "nanogl-node";
import ArrayBuffer from "nanogl/arraybuffer";
import { vec3 } from "gl-matrix";
import Program from "nanogl/program";
import vShaderFence       from '@webgl/glsl/basic/shader.vert'
import fShaderFence       from '@webgl/glsl/basic/shader.frag'
import IndexBuffer from "nanogl/indexbuffer";
import { GLContext } from "nanogl/types";
import Vao from "nanogl-vao";
import timeout from "@/utils/timeout";
import store from "@/store";

import { computed, nextTick, watch } from 'vue'
import gameStatic from "../../gameStatic";

const lerp = require('lerp')


export default class DogdeController {
    gltf:Gltf
    scene:Scene
    fence:GltfNode
    shadowPass:ShadowPass
    shadowMatList: Array<BaseMaterial>
    childrenList:Node[]
    lineIBuffers:IndexBuffer[]
    linePositions:Float32Array[]
    lineIndices:Uint16Array[]
    lineVaos:Vao[]
    fencePrg:Program
    fenceSpeed:number
    inGame:boolean
    isBreaking:boolean
    watchResult
    constructor(gltf:Gltf, scene:Scene, shadowTex:Texture) {
        this.gltf = gltf
        this.scene = scene
        this.fence = gltf.getElementByName(GltfTypes.NODE, 'Fence')
        
        this.fence.extras = { isRendered: false }
        this.fence._children.forEach((sh:Node, i:number) => {
            vec3.scale(sh.scale, sh.scale, 1.4)
            sh.invalidate()
            this.scene.game.parallaxCtrl.groundEntities[0].shadowNodeList.push(sh._children[0])
        })

        const c = computed(() => store.getters['game/isResultMode'])

        this.watchResult = watch(c, () => {
            if(store.getters['game/isResultMode'] && this.inGame) this.setEndAnim()
        })
    }

    async start() {
        this.inGame = true
        store.commit('game/setTempScore', { score: 0 })
        this.fence.extras.isRendered = true
        this.scene.game.root.add(this.fence)
        this.fence.x = -0.1


        this.lineIBuffers = []
        this.linePositions = []
        this.lineIndices = []
        this.lineVaos = []
        const lineWidth = 0.001
        const wPos = vec3.create()
        const nwPos = vec3.create()
        for (let j = 0; j < 3; j++) {
            this.fence._children.forEach((sh:Node, i:number) => {
                if(i < this.fence._children.length - 1) {
                    const next:Node = this.fence._children[i + 1]
                    sh._children[1].updateWorldMatrix()
                    vec3.transformMat4(wPos, sh._children[1].position, sh._children[1]._wmatrix)
                    next._children[1].updateWorldMatrix()
                    vec3.transformMat4(nwPos, next._children[1].position, next._children[1]._wmatrix)
                    const fY = wPos[1]
                    const fnY = nwPos[1]

                    sh.updateWorldMatrix()
                    vec3.transformMat4(wPos, sh.position, sh._wmatrix)
                    next.updateWorldMatrix()
                    vec3.transformMat4(nwPos, next.position, next._wmatrix)

                    let scaleY = (fY - wPos[1]) * 0.5
                    let nscaleY = (fnY - nwPos[1]) * 0.5
                    scaleY -= 0.05 + j * scaleY * 0.1
                    nscaleY -= 0.05 + j * nscaleY * 0.1

                    let position:Float32Array = new Float32Array(12)

                    const x = sh.position[0]
                    const y = sh.position[1]
                    const z = sh.position[2]

                    const nx = next.position[0]
                    const ny = next.position[1]
                    const nz = next.position[2]

                    position[0] = x
                    position[1] = y + scaleY - lineWidth
                    position[2] = z

                    position[3] = x
                    position[4] = y + scaleY + lineWidth
                    position[5] = z

                    position[6] = nx
                    position[7] = ny + nscaleY + lineWidth
                    position[8] = nz

                    position[9] = nx
                    position[10] = ny + nscaleY - lineWidth
                    position[11] = nz

                    const indices:Uint16Array = new Uint16Array([3,2,0,2,1,0])

                    this.linePositions.push(position)
                    this.lineIndices.push(indices)
                }
            })
                            
        }

        const gl:GLContext = this.scene.gl

        this.fencePrg = new Program(gl, vShaderFence(), fShaderFence());

        this.lineVaos = this.linePositions.map((positions, i) => {
            const buffer:ArrayBuffer = new ArrayBuffer(gl, positions)
            buffer.attrib( 'aPosition', 3, gl.FLOAT );
            const indexBuffer:IndexBuffer = new IndexBuffer(this.scene.gl,
                gl.UNSIGNED_SHORT,
                this.lineIndices[i],
                gl.STATIC_DRAW)
            this.lineIBuffers.push(indexBuffer)
            const vao:Vao = new Vao(gl)
            vao.setup(this.fencePrg, [buffer], indexBuffer)
           return vao
        })
        // this.scene.game.visualSpeedLerp = 1
        gameStatic.speed = 0.2
        this.fenceSpeed = 0.2
        this.scene.game.pronghornEntity.addSpeedFence = 0.9
        this.scene.game.pronghornEntity.addSpeedFenceStart = 0.9
        this.scene.game.pronghornEntity.fenceSpeed = false
        this.scene.game.pronghornEntity.beforeFenceSpeed = true
        this.isBreaking = false
        this.scene.game.pronghornEntity.currAnim = this.scene.game.pronghornEntity.pronghornRun
        this.scene.game.pronghornEntity.warpAnimTime = this.scene.game.pronghornEntity.warpAnimTimeArray[1]
        this.scene.game.pronghornEntity.resetLerp()

        await timeout(2500)
        this.isBreaking = true
        this.scene.game.pronghornEntity.addSpeedFence = 0.15
        await timeout(500)
        this.scene.game.visualSpeedLerp = 0.3
        // store.commit('game/setConstantSpeed', { speed: 0.6 })
    }

    async setEndAnim() {
        this.scene.game.visualSpeedLerp = 0
        this.fenceSpeed = 0.14
        this.scene.game.pronghornEntity.fenceSpeed = true
        this.scene.game.pronghornEntity.isFenceAnim = true
        this.scene.game.pronghornEntity.warpAnimTime = this.scene.game.pronghornEntity.warpAnimTimeArray[2]
        this.scene.game.pronghornEntity.resetLerp()
        // await timeout(500)
        // this.scene.game.pronghornEntity.resetLerp()
        await timeout(600)

        if(store.getters['game/percSuccess'] < 0.5) {
            this.scene.game.howler.$getSound('qte-fail', true).play()
            store.commit('game/enableFail')
        } else if(store.getters['game/percSuccess'] > 0.9) {
            this.scene.game.toggleSoundLayers(false)
            const winSound = this.scene.game.howler.$getSound('qte-win', true).play()
            this.scene.game.howler.$getSound('qte-win', true).once('end', () => this.scene.game.toggleSoundLayers(true))
        }

        await timeout(3000)
        store.commit('game/setaddScoreQte')
        // store.commit('game/addToScore', { score: store.getters['game/tempScore'] })
        // store.commit('game/setTempScore', { score: 0 })
    }

    stop() {
        this.scene.game.root.remove(this.fence)
        this.fence.extras.isRendered = false
        this.scene.game.pronghornEntity.isFenceAnim = false
        this.scene.game.pronghornEntity.fenceSpeed = false
        this.scene.game.pronghornEntity.beforeFenceSpeed = false
        gameStatic.speed = 0.5 + store.getters['game/percSuccess'] * 0.25
        this.inGame = false
    }

    updateNodes(dt:number, globalSpeed:number, changeSpeed:number) {
        const dtr =  dt * SPEED_RUN
        this.fence.x += (dtr + globalSpeed * dtr) * changeSpeed * BACK_FENCE_SLOWMO

        if(this.scene.game.pronghornEntity.fenceSpeed) {
            this.fenceSpeed = lerp(this.fenceSpeed, 0.4, dt * 4)
            gameStatic.speed = this.fenceSpeed
        }

        if(this.scene.game.pronghornEntity.beforeFenceSpeed && this.isBreaking) {
            this.fenceSpeed = lerp(this.fenceSpeed, 0.051, dt * 4)
            gameStatic.speed = this.fenceSpeed
        }
    }

    preRender(dt:number) {
    }

    render() {
        this.fence.updateWorldMatrix()
        for (let i = 0; i < this.lineVaos.length; i++) {
            const ibuffer:IndexBuffer = this.lineIBuffers[i]
            this.fencePrg.use()
            this.fencePrg.uM(this.fence._wmatrix);
            this.fencePrg.uVP(this.scene.game.getCamera()._viewProj);
            const vao:Vao = this.lineVaos[i]
            vao.bind();
            ibuffer.drawTriangles();
            vao.unbind();
        }
        for (const renderable of this.gltf.renderables) {
            if(renderable.node.name !== 'poll')
                renderable.render(this.scene, this.scene.game.getCamera(), Masks.BLENDED , Passes.COLOR, this.scene.game.baseConfig)
            // renderable.render(this.scene, this.scene.game.getCamera(), Masks.OPAQUE , Passes.COLOR)
        }
    }

    renderAfter() {
        for (const renderable of this.gltf.renderables) {
            if(renderable.node.name === 'poll')
                renderable.render(this.scene, this.scene.game.getCamera(), Masks.BLENDED , Passes.COLOR, this.scene.game.baseConfig)
            // renderable.render(this.scene, this.scene.game.getCamera(), Masks.OPAQUE , Passes.COLOR)
        }
    }

    release() {
        this.watchResult()
    }
}