import { BaseTextureInfo } from './TextureInfo';
export default class NormalTextureInfo extends BaseTextureInfo {
    constructor() {
        super(...arguments);
        this.gltftype = "normalTextureInfo" /* NORMAL_TEXTURE_INFO */;
    }
    async parse(gltfLoader, data) {
        var _a;
        await super.parse(gltfLoader, data);
        this.scale = (_a = data.scale) !== null && _a !== void 0 ? _a : 1.0;
    }
}
