import { BaseTextureInfo } from './TextureInfo';
export default class OcclusionTextureInfo extends BaseTextureInfo {
    constructor() {
        super(...arguments);
        this.gltftype = "occlusionTextureInfo" /* OCCLUSION_TEXTURE_INFO */;
    }
    async parse(gltfLoader, data) {
        var _a;
        await super.parse(gltfLoader, data);
        this.strength = (_a = data.strength) !== null && _a !== void 0 ? _a : 1;
    }
}
