<template>
  <div class="gesture-handling">
    <div v-if="qte === -1" class="gesture-handling-running">
      <gesture-running ref="gestureLeft" />
      <gesture-running ref="gestureRight" :is-right="true" />
      <speed-gauge ref="speedGauge" :is-loaded="isLoaded" />
    </div>
    <div v-else-if="qte === 0" class="gesture-handling-clean-up">
      <gesture-cleanup ref="gestureCleanUp" />
    </div>
    <div v-else-if="qte === 1" class="gesture-handling-dodge">
      <gesture-dodge ref="gestureDodge" />
    </div>
    <div v-else-if="qte === 2" class="gesture-handling-bisons">
      <gesture-bisons ref="gestureBisons" />
    </div>
    <game-head v-if="gameStarted" ref="gameHead" />
  </div>
</template>

<script lang="ts">
import { courseUpdate } from '@/hooks/gestures'
import { computed, defineComponent, onDeactivated, ref } from 'vue'
import GestureCleanUp from '../@gestures/GestureCleanUp.vue'
import GestureDodge from '../@gestures/GestureDodge.vue'
import GestureBisons from '../@gestures/GestureBisons.vue'
import GestureRunning from '../@gestures/GestureRunning.vue'
import SpeedGauge from './SpeedGauge.vue'
import GameHeader from './GameHeader.vue'
import store from '@/store'
export default defineComponent({
  components: {
    'game-head': GameHeader,
    'gesture-running': GestureRunning,
    'speed-gauge': SpeedGauge,
    'gesture-cleanup': GestureCleanUp,
    'gesture-dodge': GestureDodge,
    'gesture-bisons': GestureBisons
  },
  props: {
    isLoaded: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const speedGauge = ref(null)
    const gestureLeft = ref(null)
    const gestureRight = ref(null)
    const gestureDodge = ref(null)
    const gestureCleanUp = ref(null)
    const gestureBisons = ref(null)
    const gameHead = ref(null)
    const cycle = ref(0)
    const totalCount = ref(0)

    // Computed
    const qte = computed(() => store.getters['game/qte'])
    const gameStarted = computed(() => store.getters['game/isGameStarted'])

    // watch(qte, (qte: number) => {})

    const resize = () => {
      if (qte.value === -1) {
        ;(gestureLeft.value as any).resize()
        ;(gestureRight.value as any).resize()
      }
    }
    const update = (dt: number = 0) => {
      if (qte.value === -1 && gestureLeft.value) {
        courseUpdate(dt, gestureLeft.value, gestureRight.value)
        speedGauge.value.update(dt)
      }
      if (qte.value === 0 && gestureCleanUp.value)
        gestureCleanUp.value.update(dt)
      if (qte.value === 1 && gestureDodge.value) gestureDodge.value.update(dt)
      if (qte.value === 2 && gestureBisons.value) gestureBisons.value.update(dt)
      if (gameStarted.value) gameHead.value.update(dt)
    }

    return {
      gameStarted,
      qte,
      cycle,
      totalCount,
      speedGauge,
      gestureLeft,
      gestureRight,
      gestureDodge,
      gestureCleanUp,
      gestureBisons,
      gameHead,
      update
    }
  }
})
</script>

<style lang="scss">
.gesture-handling {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  // .force-landscape & {
  //   // transform: rotate(-90deg);
  //   transform-origin: top left;
  //   transform: rotate(90deg) translateY(-100%);
  //   width: 100vh;
  //   width: calc(var(--vh, 1vh) * 100);
  //   height: 100vw;
  //   overflow: hidden;
  //   position: absolute;
  //   // top: 100%;
  //   left: 0;
  // }
  &-circle {
    width: 200px;
    height: 200px;
    position: absolute;
    left: calc(50% - 100px);
    top: calc(50% - 100px);
    border-radius: 50%;
    background: #888;
  }
  &-speed {
    position: absolute;
    right: 20px;
    top: 20px;
    transform: translate(-50%, -50%);
  }
}
</style>
