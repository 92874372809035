import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import Scene from "@/webgl/scene";
import RibbonPass from "@webgl/glsl/ribbon"
import MeshRenderer from "@/webgl/lib/nanogl-gltf/lib/renderer/MeshRenderer";
import Texture from "nanogl/texture";
import { mat4, vec3 } from "gl-matrix";
import Node from "nanogl-node";
import gameStatic from "../gameStatic";

export default class RibbonEntity {
    gltf:Gltf
    scene:Scene
    rbPass:RibbonPass
    scrollChange:number
    rX:number
    scrollX:number
    node:Node
    scrollSpeed:number
    speedAdd:number
    id:number
    constructor(gltf:Gltf, scene:Scene, noiseTex:Texture, id:number) {
        this.gltf = gltf
        this.id = id
        this.scene = scene
        this.scrollX = 0
        this.node = gltf.root._children[0]
        this.scrollSpeed = 0.2 + Math.random() * 0.5
        this.scrollChange = Math.random() * -10
        this.rX = -3 - (6 * Math.random())
        this.scene.game.root.add(this.node)
        this.rbPass = new RibbonPass(noiseTex)
        this.rbPass.time = Math.random() * 10
        this.resetRibbon();
        (this.gltf.renderables[this.id] as MeshRenderer).materials[0].inputs.add(this.rbPass)
    }

    preRender(scroll, dt, changeSpeed) {
        this.rbPass.time += Math.max(dt * 0.01, (dt * 0.1 + gameStatic.speed * 0.001 * 2) * changeSpeed)
        const limit = Math.abs(this.rX * 2)
        vec3.set(this.node.scale, this.node.scale[0], 0.1 + gameStatic.speed, 1)
        this.node.x = this.rX + (this.scrollX % limit)
        this.scrollX += Math.max(dt * 0.1, (dt * this.speedAdd + gameStatic.speed * 0.075) * changeSpeed)
        if(this.scrollX % limit > limit - 0.2) 
            this.resetRibbon()
    }

    resetRibbon() {
        this.scrollChange = Math.random() * -10
        vec3.set(this.node.scale, (1 + (Math.random() * 2 - 1)) * 20, (0.5 - (Math.random() * 2 - 1) * 0.3) * 3, 1)
        this.node.y = -0.25 + Math.random() * 0.5
        this.node.z = Math.random() * 2
        this.rbPass.radius = 0.5 - Math.random() * 0.3;
        this.speedAdd = 2 + Math.random() * 2
        this.rbPass.alpha = 0.5;
        this.rbPass.delay = Math.random();
        this.rbPass.time = Math.random() * 10
    }
}