export default class Mesh {
    constructor() {
        this.gltftype = "meshes" /* MESH */;
    }
    async parse(gltfLoader, data) {
        const primPromises = data.primitives.map((data) => gltfLoader._loadElement(data));
        this.primitives = await Promise.all(primPromises);
        if (data.weights) {
            this.weights = new Float32Array(data.weights);
        }
    }
}
