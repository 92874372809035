import Material from "./PbrSpecularGlossinessMaterial";
const EXT_ID = 'KHR_materials_pbrSpecularGlossiness';
class Instance {
    constructor(gltfLoader) {
        this.name = EXT_ID;
        this.priority = 1;
        this.loader = gltfLoader;
    }
    loadElement(data) {
        if (data.gltftype === "materials" /* MATERIAL */ && data.extensions && data.extensions[EXT_ID]) {
            const material = new Material();
            return material.parse(this.loader, data).then(() => material);
        }
        return null;
    }
    acceptElement(data, element) {
        return null;
    }
}
export default class KHR_texture_transform {
    constructor() {
        this.name = EXT_ID;
    }
    createInstance(gltfLoader) {
        return new Instance(gltfLoader);
    }
}
