
import {
  inject,
  onActivated,
  onDeactivated,
  onMounted,
  onUnmounted,
  ref
} from 'vue'
import CircleLoader from '@/components/CircleLoader.vue'
// import HeadphonesIcon from '@/assets/svg/headphones.svg'
import anime from 'animejs/lib/anime.es.js'
import { watchResize } from '@/hooks/global'
import store from '@/store'
import timeout from '@/utils/timeout'
import { useGtm } from 'vue-gtm'

export default {
  name: 'GameLoader',
  components: {
    CircleLoader
    // HeadphonesIcon
  },
  setup() {
    const t = inject('$t')
    const active = ref(true)
    const showCircle = ref(false)
    const circleLoader = ref(null)
    const gtm = useGtm()

    const tl = anime.timeline({
      delay: 0
    })

    let unwatch = null

    onMounted(() => {
      active.value = true
      onEnter()
      runAnim()

      unwatch = watchResize((w, h, isLandscape) => {
        runAnim()
      })
    })

    onUnmounted(() => {
      unwatch()
      showCircle.value = false
      active.value = false
    })

    const runAnim = () => {
      if (store.getters['global/forceLandscape']) {
        anime.set('.game-loader-title-container', {
          opacity: 1
        })
        tl.play()
        showCircle.value = true
      } else {
        if (store.getters['global/getOrientation']) {
          tl.pause()
          showCircle.value = false
        } else {
          anime.set('.game-loader-title-container', {
            opacity: 1
          })
          tl.play()
          showCircle.value = true
        }
      }
    }

    const onEnter = () => {
      gtm.trackEvent({
        event: 'vpv',
        VPV: 'gameLoader'
      })
      anime.set('.game-loader-title-container', {
        opacity: 1
      })

      tl.add(
        {
          duration: 1200,
          targets: '.game-loader-background',
          easing: 'linear',
          opacity: [0, 1]
        },
        0
      )
        .add(
          {
            duration: 3000,
            targets: '.game-loader-background',
            easing: 'easeOutCubic',
            scale: [1.1, 1]
          },
          0
        )
        // Footer animation
        .add(
          {
            targets: '.game-loader-footer',
            duration: 1367,
            translateY: ['100%', 0],
            easing: 'cubicBezier(0.78, 0, 0.14, 1)'
          },
          700
        )

        .add(
          {
            targets: '.game-loader-headphones',
            duration: 760,
            opacity: [0, 1],
            easing: 'linear'
          },
          1400
        )
        .add(
          {
            targets: '.game-loader-title-container p > span',
            duration: 1433,
            translateY: [60, 0],
            easing: 'cubicBezier(0.51, 0, 0.18, 1)',
            delay: anime.stagger(133)
          },
          500
        )
        .add(
          {
            targets: '.game-loader-divider',
            duration: 733,
            scaleX: [0, 1],
            easing: 'cubicBezier(0.09, 0.55, 0.1, 1)'
          },
          1200
        )
        .add(
          {
            targets: '.game-loader-text',
            duration: 733,
            opacity: [0, 1],
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          1200
        )
    }

    const onLeave = async cb => {
      // anime({
      //   targets: '.game-loader',
      //   duration: 800,
      //   easing: 'linear',
      //   opacity: 0,
      //   complete: () => cb
      // })

      await timeout(500)
      if (circleLoader.value && showCircle) circleLoader.value.onLeave()
      const tl = anime.timeline({
        delay: 0,
        complete: () => {
          cb()
        }
      })
      tl.add(
        {
          targets: '.game-loader-footer',
          duration: 1367,
          translateY: '100%',
          easing: 'cubicBezier(0.9, 0, 0.1, 1)'
        },
        0
      )
        .add(
          {
            targets: '.game-loader-title-container',
            duration: 1119,
            opacity: 0,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          100
        )
        .add(
          {
            targets: '.game-loader-divider',
            duration: 1119,
            opacity: 0,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          130
        )

        .add(
          {
            targets: '.game-loader-text',
            duration: 1119,
            opacity: 0,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          160
        )
        .add(
          {
            targets: '.game-loader-headphones',
            duration: 1119,
            opacity: 0,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          190
        )
        .add(
          {
            targets: '.game-loader',
            duration: 800,
            easing: 'linear',
            opacity: 0
          },
          600
        )
    }

    return { t, showCircle, circleLoader, onLeave, active }
  }
}
