import { ITextureCodec } from "./TextureCodec";
import { ITextureRequestSource } from "./TextureRequest";
import TextureExtensions from "./TextureExtensions";
import { TextureType } from "nanogl/texture-base";


import BasisTranscoderBinaryUrl from '!!file-loader!../../vendors/basis/basis_transcoder.wasm'
import BasisTranscoder from "@/vendors/basis";
import { IBasisResponse } from "@/vendors/basis/BasisResponse";
import TextureData, { TextureDataType, TextureMip } from "./TextureData";
import { BasisOutputFormat } from "@/vendors/basis/BasisRequest";


export class TextureCodecBasis implements ITextureCodec {
  
    name: 'basis' = 'basis';

    private static _transcoder : BasisTranscoder = null;
    
    private static GetTranscoder() : BasisTranscoder {
        if( this._transcoder === null ) {
            this._transcoder = new BasisTranscoder(BasisTranscoderBinaryUrl);
        }
        return this._transcoder;
    }

  
    
    getBasisFormat( extensions : TextureExtensions ) : BasisOutputFormat {
        if( extensions.astc !== null ) return BasisTranscoder.BasisOutputFormat.ASTC ;
        if( extensions.dxt  !== null ) return BasisTranscoder.BasisOutputFormat.DXT  ;
        if( extensions.etc  !== null ) return BasisTranscoder.BasisOutputFormat.ETC  ;
        if( extensions.pvr  !== null ) return BasisTranscoder.BasisOutputFormat.PVRTC;
        return BasisTranscoder.BasisOutputFormat.RGB565;
    }
  
  
    async decodeLod(source: ITextureRequestSource, lod: number, extensions : TextureExtensions ): Promise<any> {
  
  
      const requestLod = source.lods[lod];

      const basisFormat = this.getBasisFormat( extensions );
      const response :IBasisResponse = await TextureCodecBasis.GetTranscoder().transcode( requestLod.buffers[0], basisFormat );
        
      const image = response.images[0];

      const mips : TextureMip<ArrayBufferView>[] = image.levels.map( l=>{
        return {
          width : l.alignedWidth,
          height : l.alignedHeight,
          data : new Uint8Array(l.data),
        }
      })
        
      const datas : TextureData = {
        
        datatype       : (basisFormat !== BasisTranscoder.BasisOutputFormat.RGB565) ? TextureDataType.RAW_COMPRESSED : TextureDataType.RAW,
        textureType    : TextureType.TEXTURE_2D,
        width          : image.width,
        height         : image.height,
        internalformat : image.format.internalformat,
        format         : image.format.format,
        type           : image.format.type,
  
        sources : [{
          surfaces : [mips]
        }]
      } 


      source.datas = datas;
  
  
    }



    async decodeCube(source: ITextureRequestSource, extensions : TextureExtensions): Promise<any>{

      const requestLod = source.lods[0];
      const transcoder : BasisTranscoder = TextureCodecBasis.GetTranscoder();

      const basisFormat = this.getBasisFormat( extensions );
      const response :IBasisResponse = await transcoder.transcode( requestLod.buffers[0], basisFormat );


      const image = response.images[0];

      const mips : TextureMip<ArrayBufferView>[] = image.levels.map( l=>{
        return {
          width : l.alignedWidth,
          height : l.alignedHeight,
          data : new Uint8Array(l.data),
        }
      })
        
      const datas : TextureData = {
        
        datatype       : (basisFormat !== BasisTranscoder.BasisOutputFormat.RGB565) ? TextureDataType.RAW_COMPRESSED : TextureDataType.RAW,
        textureType    : TextureType.TEXTURE_CUBE,
        width          : image.width,
        height         : image.height,
        internalformat : image.format.internalformat,
        format         : image.format.format,
        type           : image.format.type,
  
        sources : [{
          surfaces: []
        }]
      } 

      datas.sources[0].surfaces.push(mips as TextureMip<ArrayBufferView>[] & TextureMip<TexImageSource>[]);
      for(let i = 1; i < source.lods.length; i++){
        const requestLod = source.lods[i];

        const basisFormat = this.getBasisFormat( extensions );
        const response :IBasisResponse = await transcoder.transcode( requestLod.buffers[0], basisFormat );
          
        const image = response.images[0];
  
        const mips : TextureMip<ArrayBufferView>[] = image.levels.map( l=>{
          return {
            width : l.alignedWidth,
            height : l.alignedHeight,
            data : new Uint8Array(l.data),
          }
        });

        datas.sources[0].surfaces.push(mips as TextureMip<ArrayBufferView>[] & TextureMip<TexImageSource>[]);
          
      }

      source.datas = datas;
  
    }
    


    isSupported(extensions: TextureExtensions): boolean {
      return window.WebAssembly !== undefined;
    }
  
    
  }
  