<template>
  <canvas id="gameCanvas" ref="canvas" />
</template>

<script lang="ts">
import GLApp from '@/webgl/main'
import { CATCH_ASYNC } from '../../utils/Async'
import {
  computed,
  nextTick,
  onDeactivated,
  onMounted,
  onUnmounted,
  ref,
  watch
} from 'vue'
import store from '@/store'
import { useHowler } from '../../plugins/Howler'

// const PVue: VueConstructor<Props> = Vue as any

export default {
  props: {
    quality: {
      type: String,
      default: 'auto',
      validator: v => ['high', 'low', 'auto'].indexOf(v as any) !== -1
    },

    assetsUrl: {
      type: String,
      default: ''
    },
    ilayer: {
      type: String,
      default: ''
    },
    check: {
      type: Boolean,
      default: false
    }
  },
  emits: ['onframe', 'loaded'],

  setup(props: any, ctx: any) {
    const loaded = ref(false)
    const loadProgress = ref(0)
    const canvas = ref(null)
    let viewer = null

    const isPortrait = computed(() => {
      return store.getters['global/getOrientation']
    })

    const forceLandscape = computed(
      () => store.getters['global/forceLandscape']
    )

    const isLoaded = computed(() => {
      return store.getters['game/isLoaded']
    })

    const frameCallback = (dt: number) => {
      ctx.emit('onframe', dt)
    }
    const watchLoaded = watch(isLoaded, (isLoaded: boolean) => {
      if (isPortrait.value && !isLoaded)
        store.commit('game/startStop', { startStop: false })
      else if ((!isPortrait.value || forceLandscape.value) && isLoaded)
        store.commit('game/startStop', { startStop: true })
    })

    const watchisPortrait = watch(isPortrait, (isPortrait: boolean) => {
      if (isPortrait && !isLoaded.value)
        store.commit('game/startStop', { startStop: false })
      else if ((!isPortrait || forceLandscape.value) && isLoaded.value)
        store.commit('game/startStop', { startStop: true })
    })

    onMounted(() => {
      if (!props.check) return
      window['howlerRef'] = useHowler()
      nextTick(() => {
        const cnv = document.getElementById('gameCanvas')
        viewer = new GLApp({
          assetsUrl: props.assetsUrl,
          canvas: canvas.value,
          ilayer: canvas.value,
          quality: props.quality,
          frameCallback: frameCallback
        })

        if ((!isPortrait.value || forceLandscape.value) && isLoaded.value)
          store.commit('game/startStop', { startStop: true })

        CATCH_ASYNC(viewer.load()).then(() => ctx.emit('loaded'))
      })
    })

    onUnmounted(() => {
      store.commit('game/startStop', { startStop: false })
      store.commit('game/setUnLoaded')
      viewer.glview.stop()
      viewer.glview.scene.release()
      viewer.glview.scene.gl.getExtension('WEBGL_lose_context').loseContext()

      /// #if DEBUG
      document.body.removeChild(viewer.glview.scene.stats.dom)
      /// #endif

      viewer.unload()

      viewer = null
    })

    onDeactivated(() => {
      watchisPortrait()
      watchLoaded()
    })

    return {
      forceLandscape,
      isPortrait,
      loaded,
      loadProgress,
      viewer,
      canvas
    }
  }
}
</script>

<style lang="scss" scoped>
canvas {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  .force-landscape & {
    z-index: -1;
  }
}
</style>
