import Gltf2 from '../types/Gltf2';
import PerspectiveLens from 'nanogl-camera/perspective-lens';
import OrthographicLens from 'nanogl-camera/ortho-lens';
export default class Camera {
    constructor() {
        this.gltftype = "cameras" /* CAMERA */;
    }
    parse(gltfLoader, data) {
        this.type = data.type;
        switch (this.type) {
            case Gltf2.CameraType.PERSPECTIVE:
                this.projectionData = data.perspective;
                this.lens = this.createPerpective(this.projectionData);
                break;
            case Gltf2.CameraType.ORTHOGRAPHIC:
                this.projectionData = data.orthographic;
                this.lens = this.createOrtho(this.projectionData);
                break;
            default:
                throw new Error('Camera - unsupported type ' + this.type);
        }
        return Promise.resolve();
    }
    createPerpective(data) {
        var _a, _b;
        const lens = new PerspectiveLens();
        lens.near = data.znear;
        lens.far = (_a = data.zfar) !== null && _a !== void 0 ? _a : 100; // todo: infinite projection
        lens.setVerticalFov(data.yfov);
        lens.aspect = (_b = data.aspectRatio) !== null && _b !== void 0 ? _b : 1;
        return lens;
    }
    createOrtho(data) {
        const lens = new OrthographicLens();
        lens.near = data.znear;
        lens.far = data.zfar;
        lens._xMin = -data.xmag;
        lens._xMax = data.xmag;
        lens._yMin = -data.ymag;
        lens._yMax = data.ymag;
        return lens;
    }
}
