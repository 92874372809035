export default {
    time: 0,
    score: 0,
    speed: 0,
    gestureRight: 0,
    gestureLeft: 0,
    scoreScale: 0.115,
    dodgeScore: 500,
    cleanScore: 50,
    duckScore: 10,
    runningScore: 10,
}