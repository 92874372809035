import Config, { FX_URL, MUSIC_URL } from "../config";
import {TEXTURE_URL} from "../config";
import { DODGE_NB_GLYPHS } from "./gameConfig";

const assets = [
    {
        type: 'gltf',
        name: 'mainscene',
        path: Config.asset_url('game/highwest-pronghorn-game.gltf')
    },
    {
        type: 'gltf',
        name: 'dynElements',
        path: Config.asset_url('game/highwest-pronghorn-game-dynamic.gltf')
    },
    {
        type: 'gltf',
        name: 'pronghorn',
        path: Config.asset_url('game/highwest-pronghorn-game-pronghorn.gltf')
    },
    {
        type: 'gltf',
        name: 'cleanupScene',
        path: Config.asset_url('game/highwest-pronghorn-game-cleanup.gltf')
    },
    {
        type: 'gltf',
        name: 'dodgeScene',
        path: Config.asset_url('game/highwest-pronghorn-game-dodge.gltf')
    },
    {
        type: 'gltf',
        name: 'bisonsScene',
        path: Config.asset_url('game/highwest-pronghorn-game-bisons.gltf')
    },
    {
        type: 'gltf',
        name: 'wordsScene',
        path: Config.asset_url('game/highwest-pronghorn-game-words.gltf')
    },
    {
        type: 'gltf',
        name: 'introScene',
        path: Config.asset_url('game/highwest-pronghorn-game-intro.gltf')
    },
    {
        type: 'gltf',
        name: 'ribbonScene',
        path: Config.asset_url('game/highwest-pronghorn-game-ribbon.gltf')
    },
    {
        type: 'img',
        name: 'ground01',
        // path: Config.asset_url(TEXTURE_URL + 'checker.png')
        path: Config.asset_url(TEXTURE_URL + 'Ground_01_2048-gradient2.png')
    },
    {
        type: 'img',
        name: 'ground02',
        path: Config.asset_url(TEXTURE_URL + 'Ground_02_2048-gradient2.png')
        // path: Config.asset_url(TEXTURE_URL + 'checker2.png')
    },
    {
        type: 'img',
        name: 'fg',
        path: Config.asset_url(TEXTURE_URL + 'cloud.jpg')
    },
    {
        type: 'img',
        name: 'cloud',
        path: Config.asset_url(TEXTURE_URL + 'Clouds_Shadows.jpg')
    },
    {
        type: 'img',
        name: 'addSun',
        path: Config.asset_url(TEXTURE_URL + 'Add_sun.png')
    },
    {
        type: 'img',
        name: 'noise',
        path: Config.asset_url(TEXTURE_URL + 'noise.jpg')
    },
    {
        type: 'img',
        name: 'noise2',
        path: Config.asset_url(TEXTURE_URL + 'noise2.jpg')
    },
    {
        type: 'img',
        name: 'particle',
        path: Config.asset_url(TEXTURE_URL + 'particle.png')
    },
    // {
    //     type: 'sound',
    //     name: 'layer1',
    //     loop: true,
    //     path: MUSIC_URL + 'layer1.mp3'
    // },
    // {
    //     type: 'sound',
    //     name: 'layer2',
    //     loop: true,
    //     path: MUSIC_URL + 'layer2.mp3'
    // },
    {
        type: 'sound',
        name: 'layer3',
        html5: true,
        loop: true,
        path: MUSIC_URL + 'layer3.mp3'
    },
    {
        type: 'sound',
        name: 'layer4',
        html5: true,
        loop: true,
        path: MUSIC_URL + 'layer4.mp3'
    },
    {
        type: 'sound',
        name: 'layer5',
        html5: true,
        loop: true,
        path: MUSIC_URL + 'layer5.mp3'
    },
    {
        type: 'sound',
        name: 'qte-win',
        path: MUSIC_URL + 'qte-win.mp3'
    },
    {
        type: 'sound',
        name: 'dodge-tap',
        path: FX_URL + 'dodge-tap.mp3'
    },
    {
        type: 'sound',
        name: 'qte-fail',
        path: FX_URL + 'qte-fail.mp3'
    },
    {
        type: 'sound',
        name: 'run-loop',
        html5: true,
        loop: true,
        path: FX_URL + 'run-loop.mp3'
    },
    {
        type: 'sound',
        name: 'transition',
        path: FX_URL + 'transition.mp3'
    },
    // {
    //     type: 'sound',
    //     name: 'wind-home',
    //     path: FX_URL + 'wind-home.mp3'
    // }
]

for (let i = 0; i < DODGE_NB_GLYPHS; i++) {
    assets.push({
        type: 'img',
        name: 'glyph0'+(i+1),
        path: Config.image_url(`game/dodge/glyph0${i+1}.png`)
    })
    assets.push({
        type: 'img',
        name: 'glyph0'+(i+1) + '-show',
        path: Config.image_url(`game/dodge/glyph0${i+1}-show.png`)
    })
}

export default assets