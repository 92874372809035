/////////////
/////////////////////////////////////////////
//////////////////////////////////
//////////

import Scene from "@webgl/scene";

import Camera from "nanogl-camera";
import Masks from "@webgl/gl/masks";
import GLConfig from "nanogl-state/config";
import { Passes } from "@webgl/glsl/Passes";
import Gltf from "nanogl-gltf/lib/index";
import ResourceGroup from "@webgl/assets2/ResourceGroup";
import GLTFResource from "@webgl/assets2/GLTFResource";
import ParallaxController from "./controllers/ParallaxController";
import DynamicElementsController from "./controllers/DynamicElementsController";
import WordsController from "./controllers/WordsController";
import { ImageResource } from "../assets2/Net";
import Node from "nanogl-node";
import store from "@/store";
import { SPEED_MULT, SPEED_RUN, SPEED_SLOWMO, envs, GAME_GLOBAL_SPEED_SCALE } from './gameConfig'
import QTEController from "./controllers/QTEController";
import GLState from "nanogl-state";

import AssetsResources from './Resources'
import Texture from "nanogl/texture";
import PronghornEntity from "./entities/PronghornEntity";
import IRenderable from "../lib/nanogl-gltf/lib/renderer/IRenderable";
import timeout from "@/utils/timeout";
import gameStatic from "./gameStatic";
import { Howler } from 'howler'
import { computed, watch } from "vue";
import { useGtm } from "vue-gtm";

const lerp = require('lerp')
const smoothstep = require('smoothstep')

export default class Game {
  scene: Scene;

  parallaxGltf: Gltf;
  dynsGltf: Gltf;
  prongGltf: Gltf;
  ribbonGltf: Gltf;

  root: Node
  baseConfig:GLConfig
  prongCfg:GLConfig
  partCfg:GLConfig
  ribbonCfg:GLConfig

  loaded:boolean

  parallaxCtrl: ParallaxController
  dynamicElementCtrl: DynamicElementsController
  qteCtrl: QTEController
  wordsCtrl: WordsController

  visualSpeed:number
  visualSp:number
  visualSpeedLerp:number

  groundY:number
  groundNode:Node

  shadowTex:Texture

  pronghornEntity:PronghornEntity
  time:number
  baseSpeed:number

  introOutro:number;
  introOutroLerp:number;
  isInIntro:boolean
  isInOutro:boolean

  howler:any

  layer3Play:any
  layer4Play:any
  layer5Play:any
  runloopPlay:any

  lerpFov:number

  resources:ResourceGroup

  hash:number

  prevTime:number = 0

  disableLoopVolume:boolean
  playingSoundVolume:boolean

  watchStartGame

  gtm

  constructor(scene: Scene) {
    gameStatic.time = 0
    this.scene = scene;
    this.resources = new ResourceGroup()

    this.gtm = useGtm()

    this.shadowTex = new Texture(scene.gl, scene.gl.RGBA)

    this.parallaxCtrl = new ParallaxController()
    this.qteCtrl = new QTEController(this.onChangeQte, this.onTransitionQte, this.scene)

    this.groundY = -0.15

    this.disableLoopVolume = false
    this.playingSoundVolume = false

    this.hash = Math.random()

    this.baseConfig = GLState.config()
      .enableBlend()
      .blendFunc( this.scene.gl.SRC_ALPHA, this.scene.gl.ONE_MINUS_SRC_ALPHA )
      .depthMask( false )
      .enableDepthTest(false)

      this.prongCfg = GLState.config()
      .depthMask( true )
      .enableDepthTest(true)

      this.partCfg = GLState.config()
      .enableBlend()
      .blendEquation(this.scene.gl.FUNC_ADD)
      .blendFunc( this.scene.gl.SRC_ALPHA, this.scene.gl.ONE_MINUS_SRC_ALPHA )
      .depthMask(true)
      .enableDepthTest(true)

      this.ribbonCfg = GLState.config()
        .enableBlend()
        .blendFunc( this.scene.gl.SRC_ALPHA, this.scene.gl.ONE_MINUS_SRC_ALPHA )
        .depthMask( false )
        .enableDepthTest(false)
        .enableCullface(false)

    this.visualSp = this.visualSpeed = this.visualSpeedLerp = 0

    this.time = 0
    this.baseSpeed = 2.4

    this.introOutro = this.introOutroLerp = 0;

    this.lerpFov = 0

    this.howler = window['howlerRef']
    const st = computed(() => store.getters['game/isGameStarted'])
    this.watchStartGame = watch(st, () => {
      if(st) this.startGame()
  })
    this.createResources();
/////////////////
///////////////
//////////////
  }
  
  
  getCamera(): Camera {
    return this.qteCtrl.qteHasScene ? this.qteCtrl.activeCtrl?.gltf?.cameraInstances[0] : this.parallaxGltf?.cameraInstances[0];
  }

  getResources() : ResourceGroup {
    return this.resources;
  }
  
  createResources(){
    window['resources'] = this.resources

    AssetsResources.filter(asset => asset.type === 'gltf').forEach(asset => {
      const gltf = new GLTFResource(asset.path, this.scene)
      this.resources.add( gltf, asset.name )
    })

    AssetsResources.filter(asset => asset.type === 'img').forEach(asset => {
      const img = new ImageResource(asset.path)
      this.resources.add( img, asset.name )
    })
  }

  async loadSounds(callbackLoaded = null) {
    return Promise.all(AssetsResources.filter(asset => asset.type === 'sound').map(asset => {
      return new Promise((resolve:any) => {
        this.howler.$getSound(asset.name, true, {
          srcs: [asset.path],
          preload: true,
          html5: false,
          preloadCallback: () => {
            if(callbackLoaded !== null) callbackLoaded()
            resolve()
          },
          opts: {
            loop: asset.loop
          }
        })

        // return resolve()
      })
    }))
  }


  onLoaded(){
    gameStatic.time = 0
    gameStatic.speed = 0
    gameStatic.score = 0

    this.parallaxGltf = this.resources.get('mainscene')
    this.dynsGltf = this.resources.get('dynElements')
    this.prongGltf = this.resources.get('pronghorn')
    // this.wordsGltf = this.resources.get('wordsScene')
    this.root = this.parallaxGltf.root

    this.pronghornEntity = new PronghornEntity(this.prongGltf, this.scene)

    //Init parallax
    this.ribbonGltf = this.resources.get('ribbonScene')
    const groundTexs: Array<HTMLImageElement> = [
      this.resources.get('ground01'),
      this.resources.get('ground02'),
    ]
    this.parallaxCtrl.init(this.parallaxGltf, this.scene, [groundTexs], this.ribbonGltf)
    this.scene.root.add(this.parallaxGltf.root);
    this.groundNode = this.parallaxCtrl.groundEntities[0].node

    //Init dynamic elements
    this.dynamicElementCtrl = new DynamicElementsController(this.dynsGltf, this.resources.get('introScene'), this.parallaxGltf.root, this.scene)

    //Init qtes
    this.qteCtrl.init(this.resources.get('cleanupScene'), this.resources.get('dodgeScene'), this.resources.get('bisonsScene'), this.shadowTex)

    //Init Words
    // !!!!!Weird hack otherwise it looses the reference to the word controller ??????
    this.wordsCtrl = window['words'] = new WordsController()
    this.wordsCtrl.init(this.resources.get('wordsScene'), this.scene)

    store.commit('game/setLoaded')
    this.handleResize()

    this.parallaxCtrl.shadowsReady()

    this.loaded = true
    
    this.pronghornEntity.root.x = 2

    this.introOutro = this.introOutroLerp = 1;

    this.isInIntro = true;
    this.isInOutro = false;

/////////////////
////////////////////////////////////////////////////////////////
//////////////
  }

  handleResize() {
    this.getCamera()?.updateViewProjectionMatrix(this.scene.gl.canvas.width, this.scene.gl.canvas.height)
  }

  async startGame() {
    this.isInOutro = false;
    store.commit('game/setIsIntro', { isInIntro: true })
    store.commit('game/startTimer')
    gameStatic.time = 61000
    gameStatic.score = 0
    gameStatic.speed = 0
    this.manageStartSounds()
    this.gtm.trackEvent({
      'event': 'vpv',
      'VPV': 'gameRun'
    })
    this.gtm.trackEvent(
      {
        'event': 'eventPush',
        'eventAction': 'start',
        'eventLabel': '',
        'eventValue': '',
        'eventCategory': 'game'
      }
    )
  }

  async manageStartSounds() {
    this.layer3Play = this.howler.$getSound('layer3', true).play()
    this.layer4Play = this.howler.$getSound('layer4', true).play()
    this.layer5Play = this.howler.$getSound('layer5', true).play()
    this.runloopPlay = this.howler.$getSound('run-loop', true).play()

    this.howler.$getSound('layer3', true).volume(0.79, this.layer3Play)
    this.howler.$getSound('layer4', true).volume(0, this.layer4Play)
    this.howler.$getSound('layer5', true).volume(0, this.layer5Play)
    this.howler.$getSound('run-loop', true).volume(0.1, this.runloopPlay)
  }
  
  async toggleSoundLayers(b) {
    const volLayer3 = this.howler.$getSound('layer3', true).volume(this.layer3Play)
    const volLayer4 = this.howler.$getSound('layer4', true).volume(this.layer4Play)
    const volLayer5 = this.howler.$getSound('layer5', true).volume(this.layer5Play)
    const volLayerR = this.howler.$getSound('run-loop', true).volume(this.runloopPlay)
    const speed = gameStatic.speed
    if(b) {
      let increaseSound = smoothstep(0.2, 0.4, speed)
      this.howler.$getSound('run-loop', true).fade(volLayerR, 0.1 + increaseSound * 0.71, 2000, this.runloopPlay)

      increaseSound = smoothstep(0.4, 0.5, speed)
      this.howler.$getSound('layer3', true).fade(volLayer3, 0.79 + increaseSound * 0.21, 2000, this.layer3Play)
      this.howler.$getSound('layer4', true).fade(volLayer4, increaseSound, 2000, this.layer4Play)
      
      await timeout(2000)
      this.disableLoopVolume = false
      this.playingSoundVolume = false
    } else {
      this.playingSoundVolume = true
      this.disableLoopVolume = true
      this.howler.$getSound('layer3', true).fade(volLayer3, 0, 500, this.layer3Play)
      this.howler.$getSound('layer4', true).fade(volLayer4, 0, 500, this.layer4Play)
      this.howler.$getSound('layer5', true).fade(volLayer5, 0, 500, this.layer5Play)
    }
  }

  onChangeQte = (isReset:boolean, isRunningQte:boolean, prevQte: number = -1, qte:number = -1) => {
    // console.log('onChangeQte', isRunningQte)
    window['words'].hideWord()
    if(qte === 1) {
      this.dynamicElementCtrl.makeSpace()
    }

    let qteLabel = 'gameRun'
    let eventAction = 'run'
    let eventLabel = ''

    if(qte === 0) {
      qteLabel = 'QTEclear'
      eventAction = 'clear'
      eventLabel = 'qte'
    }
    else if(qte === 1) {
      qteLabel = 'QTEduck'
      eventAction = 'duck'
      eventLabel = 'qte'
    }
    else if(qte === 2) {
      qteLabel = 'QTEdodge'
      eventAction = 'dodge'
      eventLabel = 'qte'
    }

    this.gtm.trackEvent({
      'event': 'vpv',
      'VPV': qteLabel
    })

    this.gtm.trackEvent({
      'event': 'eventPush',
      'eventAction': eventAction,
      'eventLabel': eventLabel,
      'eventValue': '',
      'eventCategory': 'game'
    })

    // if(isRunningQte) {
    //   this.visualSpeedLerp = 1
    // }
    this.introOutro = this.introOutroLerp = 0;
    if(isReset) this.visualSpeedLerp = 0
    this.handleResize()
  }

  onTransitionQte = async (qte:number) => {
    const speed = gameStatic.speed
    const volLayer3 = this.howler.$getSound('layer3', true).volume(this.layer3Play)
    const volLayer4 = this.howler.$getSound('layer4', true).volume(this.layer4Play)
    const volLayerR = this.howler.$getSound('run-loop', true).volume(this.runloopPlay)
    if(qte !== -1) {
      window['words'].showWord()
      this.disableLoopVolume = true
      this.howler.$getSound('layer3', true).fade(volLayer3, 0.79, 2000, this.layer3Play)
      this.howler.$getSound('layer4', true).fade(volLayer4, 0, 2000, this.layer4Play)
      this.howler.$getSound('run-loop', true).fade(volLayerR, 0.1, 2000, this.runloopPlay)

      this.howler.$getSound('layer5', true).fade(0, 1, 2000, this.layer5Play)
      await timeout(1000)
      this.introOutroLerp = 1;
    } else {
      if(this.playingSoundVolume) return
      let increaseSound = smoothstep(0.4, 0.5, speed)
      this.howler.$getSound('layer3', true).fade(0.4, 0.79 + increaseSound * 0.21, 3000, this.layer3Play)
      this.howler.$getSound('layer4', true).fade(0.4, increaseSound, 3000, this.layer4Play)

      increaseSound = smoothstep(0.2, 0.4, speed)
      this.howler.$getSound('run-loop', true).fade(0.4, 0.1 + increaseSound * 0.71, 3000, this.runloopPlay)
      
      this.howler.$getSound('layer5', true).fade(1, 0, 3000, this.layer5Play)
      await timeout(3000)
      this.disableLoopVolume = false
    }
  }

  updateNodes(dt: number, speed: number) {
    if(!this.loaded) return
    if(!store.getters['game/isGameStarted']) speed = 0
    // if(Howler.ctx.currentTime - this.prevTime === 0) {
    //   console.log('lost context :\\', Howler.ctx.state)
    // }
    this.prevTime = Howler.ctx.currentTime
    if(this.isInIntro && this.pronghornEntity.root.x <= 0.2) {
      this.isInIntro = false;
      this.introOutro = this.introOutroLerp = 0;
    }

    const isRunning = store.getters['game/qte'] === -1

    if(isRunning) {
      this.lerpFov = lerp(this.lerpFov, speed, 0.1)
      this.getCamera().lens['fov'] = 0.2 + this.lerpFov * 0.06
    } else this.getCamera().lens['fov'] = 0.25

    if(!store.getters['game/qteTransition'] && isRunning && !store.getters['game/isInIntro'] && !store.getters['game/isInOutro'] && store.getters['game/isGameStarted']) {
      gameStatic.time -= dt * 1000;
      // store.commit('game/setTimer', { time })

      if(gameStatic.time <= 0) {
        gameStatic.time = 0
        this.finishGame()
      }

      if(speed > 0.2 && !this.disableLoopVolume && !this.playingSoundVolume) {
        const increaseSound = smoothstep(0.2, 0.4, speed)
        this.howler.$getSound('run-loop', true).volume(0.1 + increaseSound * 0.71, this.runloopPlay)
      }

      if(speed > 0.4 && !this.disableLoopVolume && !this.playingSoundVolume) {
        const increaseSound = smoothstep(0.4, 0.5, speed)
        this.howler.$getSound('layer3', true).volume(0.79 + increaseSound * 0.21, this.layer3Play)
        this.howler.$getSound('layer4', true).volume(increaseSound, this.layer4Play)
      }

    }

    this.manageLoopSound('layer3', this.layer3Play)
    this.manageLoopSound('layer4', this.layer4Play)
    this.manageLoopSound('layer5', this.layer5Play)

    this.introOutro = lerp(this.introOutro, this.introOutroLerp, dt * 3)
    if(store.getters['game/isInIntro'] && this.introOutro < 0.1) {
      store.commit('game/setIsIntro', { isInIntro: false })
    }
    this.visualSp = lerp(this.visualSp, this.visualSpeedLerp, dt * 5)
    this.visualSpeed = lerp(SPEED_MULT, SPEED_SLOWMO, this.visualSp)
    if(!this.qteCtrl.qteHasScene) {
      this.dynamicElementCtrl.updateNodes(dt * SPEED_RUN, speed * 2 * GAME_GLOBAL_SPEED_SCALE, this.visualSpeed)
    }
    window['words'].updateNodes(dt, speed)
    this.qteCtrl.updateNodes(dt, speed, this.visualSpeed)
  }

  // called after node graph update
  preRender(dt: number, speed: number) {
    if(!this.loaded) return
    if(!store.getters['game/isGameStarted']) speed = 0
    
    if(store.getters['game/isGameStarted']) this.time += dt * 0.5 + 0.5 * dt * 0.8 * (1 - this.visualSp);
    if(!this.qteCtrl.qteHasScene) {
      this.parallaxCtrl.preRender(dt * SPEED_RUN, speed * 2 * GAME_GLOBAL_SPEED_SCALE, this.visualSpeed, 1 - this.introOutro)
      this.pronghornEntity.preRender(dt, speed * GAME_GLOBAL_SPEED_SCALE, this.visualSpeed - 1, this.baseSpeed)
    }
    this.qteCtrl.preRender(dt);
  }

  renderPronghornShadow(w:number, h:number) {
    this.pronghornEntity.renderShadow(w, h)
  }

  render(camera: Camera) {
    if(!this.loaded) return
    this.scene.glstate.push(this.baseConfig)
    this.scene.glstate.apply() 

    if(!this.qteCtrl.qteHasScene) {
      this.parallaxCtrl.render()
    }
    this.qteCtrl.render()
    if(!this.qteCtrl.qteHasScene) {
      this.dynamicElementCtrl.render()
      this.renderMainScene( camera, Masks.BLENDED , Passes.COLOR, this.dynamicElementCtrl.dynsBeforePronghorn, this.baseConfig);
      if(this.dynamicElementCtrl.renderIntro)
        this.renderMainScene( camera, Masks.BLENDED , Passes.COLOR, this.dynamicElementCtrl.intro.renderables, this.baseConfig);
      window['words'].render()
      this.pronghornEntity.render(this.prongCfg, this.getCamera())
      this.parallaxCtrl.renderParticles()
      this.renderMainScene( camera, Masks.BLENDED , Passes.COLOR, this.dynamicElementCtrl.dynsAfterPronghorn, this.baseConfig);
      this.qteCtrl.renderAfter()
    }

    this.scene.glstate.pop()
  }

  renderMainScene(camera: Camera, mask: Masks, passId: Passes = Passes.DEFAULT, renderables:IRenderable[], cfg?: GLConfig) {
    for (const renderable of renderables) {
      let noRender = renderable.node.extras && renderable.node.extras.noRender
      if((renderable.node._parent && renderable.node._parent._parent) && !noRender) {
        renderable.render(this.scene, camera, mask, passId, cfg)        
      }
    }
  }

  manageLoopSound(key, id) {
    const sec = this.howler.$getSound(key, true).seek(id)
    if(sec > 38.850)
      this.howler.$getSound(key, true).seek(1.204, id)
  }

  async finishGame() {
    this.gtm.trackEvent({
      'event': 'eventPush',
      'eventAction': 'end',
      'eventLabel': '',
      'eventValue': store.getters['game/countQte'],
      'eventCategory': 'game'
    })
    store.commit('game/setScore', { score: gameStatic.score })
    store.commit('game/setIsOutro')
    await timeout(10)

    let vol = this.howler.$getSound('layer3', true).volume(this.layer3Play)
    this.howler.$getSound('layer3', true).fade(vol, 0, 2000, this.layer3Play)

    vol = this.howler.$getSound('layer4', true).volume(this.layer4Play)
    this.howler.$getSound('layer4', true).fade(vol, 0, 2000, this.layer4Play)

    vol = this.howler.$getSound('layer5', true).volume(this.layer5Play)
    this.howler.$getSound('layer5', true).fade(vol, 0, 2000, this.layer5Play)

    vol = this.howler.$getSound('run-loop', true).volume(this.runloopPlay)
    this.howler.$getSound('run-loop', true).fade(vol, 0, 2000, this.runloopPlay)

    await timeout(500)
    window['words'].showWord(true)
    await timeout(1000)
    this.introOutroLerp = 1
    await timeout(1000)
    store.commit('game/setGameDone')
  }

  doUnload() {
    console.log('kill all resources')
    this.watchStartGame()
    if(this.qteCtrl && this.qteCtrl.release) this.qteCtrl.release()
    this.resources.doUnload()
    this.howler.$unloadGame()
    window['resources'] = undefined
    window['words'] = undefined
    this.resources = undefined
  }

  _gui() {
      if(gui.__folders['parallax']) return
      const opts = {
        sunPos: envs.SUN_POS
      }
      var pf = gui.addFolder('parallax');
      pf.add(this, 'groundY', -1.01, 1.01).step(0.01)
      pf.add(opts, 'sunPos', -4, 4).name('sun position x').step(0.01).onChange(v => {
        envs.SUN_POS = v
      })
      pf.add(this, 'baseSpeed', 2, 3.5).step(0.01)
      // pf.add(this.wordsCtrl, 'showWord')
  }

}

