import ShadowPass from "@/webgl/glsl/shadowPass";
import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import Scene from "@/webgl/scene";
import { mat4, vec3 } from "gl-matrix";
import Node from "nanogl-node";
import BaseMaterial from "nanogl-pbr/BaseMaterial";
import Rect from "nanogl-primitives-2d/rect";
import GltfNode from "@webgl/lib/nanogl-gltf/lib/elements/Node";
import { nextTick } from "vue";

export default class DynamicElementEntity {
    els:Array<GltfNode>
    originScales: Array<vec3>
    shadowMatList: Array<Array<BaseMaterial>>
    quad:Rect
    scene:Scene
    shadowPass:ShadowPass
    sceneNode:Node
    currEls:Array<GltfNode>
    zList:Array<number>
    currIds:Array<number>
    randXAdd:Array<number>
    isWaiting:Array<boolean>
    waitRandom:number
    nbEls:number
    launchWith:number
    randPos:Array<vec3>|string
    tmpV:vec3
    screenPos:vec3
    onPlaceElement:Function
    gId:number
    invScreenProj:mat4
    nextLaunchId:number
    constructor(
        scene:Scene,
        elements:Gltf,
        sceneNode:Node,
        nodeNames:Array<string>,
        nbEls:number,
        quad:Rect,
        id:number,
        randPos:Array<vec3>|string,
        launchWith:number,
        onPlaceElement:Function) {
        this.onPlaceElement = onPlaceElement
        this.randPos = randPos
        this.randXAdd = []
        this.tmpV = vec3.create()
        this.screenPos = vec3.create()
        this.scene = scene
        this.launchWith = launchWith
        this.originScales = []
        this.zList = []
        this.gId = id
        this.sceneNode = sceneNode
        this.invScreenProj = mat4.create()
        this.els = nodeNames.map((node, i) => {
            const n:GltfNode = elements.getElementByName(GltfTypes.NODE, node)
            n.extras = {}
            n.extras.noRender = false
            n.extras.noRenderLimit = this.randPos !== 'close' ? 1.3 : 1.9
            const s:vec3 = vec3.create()
            let isGroup = false
            if(n.name.indexOf('Group-') > -1) isGroup = true

            this.originScales.push(s)
            this.zList.push(n.z)
            if(n._parent) n._parent.remove(n)
            return n
        })
        if(typeof this.randPos !== 'string') {
            this.els.forEach(node => {
                let isGroup = false
                if(node.name.indexOf('Group-') > -1) isGroup = true
                const shadowList = this.scene.game.parallaxCtrl.groundEntities[0].shadowNodeList
                if(!isGroup && node._children.length > 0) {
                    const shadowNode = node._children[0]
                    shadowList.push(shadowNode)
                } else {
                    node.extras.isRendered = false
                    node._children.forEach((sh) => {
                        const shadowNode = sh._children[0]
                        shadowList.push(shadowNode)
                    })
                }
            })
        }
        this.waitRandom = 500 + nbEls * 500
        this.currIds = []
        this.currEls = []
        this.isWaiting = []
        this.nbEls = nbEls
       this.startAll(nbEls, id)
    }

    async startAll(nbEls:number, id:number) {
        for (let i = 0; i < this.els.length; i++) {
            this.currEls[i] = null
        }
        
        if(this.launchWith !== -1) {
            this.nextLaunchId = Math.floor(Math.random() * this.els.length)
            return;
        }

        nextTick(() => {
            for (let i = 0; i < nbEls; i++) {
                // this.isWaiting[i] = true
                this.randXAdd[i] = 1 + Math.random() * 6
                // await timeout(id * 300 + Math.random() * 750)
                this.startPlaceElement(i, -1, true)
            }
        })
    }

    makeSpace() {
        if(this.launchWith === -1) {
            this.currIds.forEach((id,i) => {
                const c = this.currEls[id]
                if(c.x > -2 && c.x < 2) {
                    this.startPlaceElement(this.currIds[i])
                }
            })
        }
    }

    startPlaceElement(id:number = -1, rand:number = -1, start:boolean = false, position = [], intId:number = -1) {
        // this.isWaiting[id] = true

        if(id === -1) {
            id = intId
        }

        
        this.cleanUp(id)

        // const speed = gameStatic.speed

        // const tout = Math.random() * this.waitRandom + (this.gId) * 1500 + id * 2000 + id * 10000 * Math.random() + (1 - speed) * 1500 * id
        // if(this.launchWith === -1) await timeout(tout)
        let currId = this.nbEls === 1 ? Math.floor(Math.random() * this.currEls.length) : id
        if(this.launchWith !== -1) currId = id
        this.currIds.push(id)
        this.currEls[id] = this.els[currId]
        if(this.currEls[id].name.indexOf('Group-') > -1) 
            this.currEls[id].extras.isRendered = true
        this.sceneNode.add(this.currEls[id])
        // this.currEls[id].x = -3 + Math.random() * -0.5

        this.currEls[id].x = -4 - (Math.random() * 2 + id * 2 + id * Math.random() * 0.8 + this.gId * 0.5 + this.gId * Math.random() * 3) * 3

        if(this.randPos !== 'close') {
            const randP = rand !== -1 ? rand : Math.random()
            if(this.randPos !== 'fly') {
                vec3.scale(this.tmpV, (this.randPos as vec3[])[1], randP)
                vec3.add(this.tmpV, (this.randPos as vec3[])[0], this.tmpV)
                this.currEls[id].y = this.tmpV[1]
                this.currEls[id].y += this.originScales[currId][1] * 0.5
                this.currEls[id].z = this.tmpV[2]
            } else {
                this.currEls[id].z = 35 + 20 * Math.random()
                this.currEls[id].y = 1 + Math.random() * 2
            }
            
            if(this.launchWith !== -1) {
                vec3.copy(this.currEls[id].position, position)
                this.currEls[id].z -= 0.1 + Math.random() * 0.15
                this.currEls[id].x += (Math.random() * 2 - 1) * 0.1
            }

            vec3.transformMat4(this.screenPos, this.currEls[id].position, this.scene.game.getCamera()._viewProj)
            vec3.set(this.screenPos, 1.3, this.screenPos[1], this.screenPos[2])
            mat4.invert(this.invScreenProj, this.scene.game.getCamera()._viewProj)
            vec3.transformMat4(this.tmpV,this.screenPos, this.invScreenProj)
            this.tmpV[0] = this.currEls[id].x
            vec3.copy(this.currEls[id].position, this.tmpV)
            this.currEls[id].invalidate()
            this.onPlaceElement(this.gId, randP, this.currEls[id].position, id)
        }
        // this.isWaiting[id] = false
    }

    cleanUp(id:number) {
        const fId = this.currIds.filter(nid => nid === id)
        if(fId.length > 0) {
            if(this.currEls[id]._parent) this.currEls[id]._parent.remove(this.currEls[id])
            this.currEls[id] = null
            for (let i = 0; i < this.currIds.length; i++) {
                if(this.currIds[i] === id) {
                    this.currIds.splice(i, 1)
                    break;
                }                
            }
        }
    }

    updateNode(dt: number, scrollSpeed:number = 0) {
        this.currIds.forEach((id,i) => {
            const c = this.currEls[id]
            if(!c) return
            c.x += scrollSpeed
            if(this.randPos === 'fly') c.x += scrollSpeed + 0.01
            vec3.transformMat4(this.screenPos, c.position, this.scene.game.getCamera()._viewProj)
            c.extras.noRender = this.screenPos[0] < -c.extras.noRenderLimit || this.screenPos[0] > c.extras.noRenderLimit 
            if(this.screenPos[0] < -(c.extras.noRenderLimit) && !this.isWaiting[i] && this.launchWith === -1) this.startPlaceElement(this.currIds[i])
        })
    }
}