import { mat4 } from 'gl-matrix';
const M4 = mat4.create();
export default class Skin {
    constructor() {
        this.gltftype = "skins" /* SKIN */;
    }
    async parse(gltfLoader, data) {
        const jointPromises = data.joints.map(idx => gltfLoader.getElement("nodes" /* NODE */, idx));
        this.joints = await Promise.all(jointPromises);
        this.inverseBindMatrices = this.joints.map(mat4.create);
        if (data.inverseBindMatrices !== undefined) {
            const ibmAccessor = await gltfLoader.getElement("accessors" /* ACCESSOR */, data.inverseBindMatrices);
            this.inverseBindMatrices.forEach((m, i) => ibmAccessor.getValue(m, i));
        }
        if (data.skeleton !== undefined) {
            this.skeletonRoot = await gltfLoader.getElement("nodes" /* NODE */, data.skeleton);
        }
    }
    computeJoints(skinnedNode, jointMatrices) {
        if (jointMatrices.length !== this.joints.length) {
            throw new Error("Skin.computeJoints(), jointMatrices size must match joints size");
        }
        mat4.invert(M4, skinnedNode._wmatrix);
        for (let i = 0; i < this.joints.length; i++) {
            const joint = this.joints[i];
            const ibm = this.inverseBindMatrices[i];
            const jointMatrix = jointMatrices[i];
            mat4.mul(jointMatrix, joint._wmatrix, ibm);
            mat4.mul(jointMatrix, M4, jointMatrix);
        }
    }
}
