import { createNativeSignal } from '../lib/cancellation';
const _HAS_CIB = (window.createImageBitmap !== undefined);
export default class Image {
    constructor() {
        this.gltftype = "images" /* IMAGE */;
    }
    async parse(gltfLoader, data) {
        if (data.uri) {
            this.uri = data.uri;
            if (this.uri.indexOf('data:') == 0) {
                this.resolvedUri = this.uri;
            }
            else {
                this.resolvedUri = gltfLoader.resolveUri(this.uri);
            }
        }
        this.mimeType = data.mimeType;
        if (data.bufferView !== undefined) {
            this.bufferView = await gltfLoader.getElement("bufferViews" /* BUFFERVIEW */, data.bufferView);
        }
        const blob = await this.loadImageBlob(gltfLoader.abortSignal);
        this.texImageSource = await gltfLoader.gltfIO.loadImageBlob(blob, gltfLoader.abortSignal);
    }
    async loadImageBlob(abortSignal) {
        if (this.bufferView) {
            // mimeType is guaranted here
            const arrayView = new Uint8Array(this.bufferView.buffer._bytes, this.bufferView.getByteOffset(), this.bufferView.byteLength);
            return new Blob([arrayView], { type: this.mimeType });
        }
        else {
            // assume uri is defained as uri or data uri
            const signal = createNativeSignal(abortSignal);
            const request = await fetch(this.resolvedUri, { signal });
            const blob = await request.blob();
            return blob;
        }
    }
}
