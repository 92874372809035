import base64 from 'base64-js';
import UTF8 from '../lib/utf8-decoder';
import GltfIO from ".";
import { AbortSignal } from '@azure/abort-controller';
import { cancellablePromise, createNativeSignal } from '../lib/cancellation';
/**
 * we need a createImageBitmap implementation which support options
 * make test with small data uri png
 */
async function checkCreateImageBitmapCapability() {
    if (window.createImageBitmap === undefined) {
        return false;
    }
    const uri = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=";
    const request = await fetch(uri);
    const blob = await request.blob();
    try {
        //@ts-ignore
        await window.createImageBitmap(blob, {
            premultiplyAlpha: 'none',
            colorSpaceConversion: 'none'
        });
    }
    catch (e) {
        return false;
    }
    return true;
}
const CreateImageBitmapAvailable = checkCreateImageBitmapCapability();
function baseDir(p) {
    const sep = p.lastIndexOf("/");
    return [
        p.substr(0, sep),
        p.substr(sep + 1),
    ];
}
class WebImpl {
    isDataURI(uri) {
        return (uri.indexOf('data:') === 0);
    }
    decodeDataURI(uri) {
        if (uri.indexOf('data:') !== 0) {
            throw new Error('invalid dataURI');
        }
        var b64 = uri.substr(uri.indexOf(',') + 1);
        return base64.toByteArray(b64).buffer;
    }
    resolveBaseDir(path) {
        return baseDir(path);
    }
    resolvePath(path, baseurl) {
        if (baseurl === undefined || this.isDataURI(path))
            return path;
        return baseurl + '/' + path;
    }
    decodeUTF8(buffer, offset = 0, length = undefined) {
        if (length === undefined)
            length = buffer.byteLength - offset;
        return UTF8(new Uint8Array(buffer, offset, length));
    }
    async loadResource(path, abortSignal = AbortSignal.none) {
        const signal = createNativeSignal(abortSignal);
        const response = await fetch(path, { signal });
        return response.text();
    }
    async loadBinaryResource(path, abortSignal = AbortSignal.none) {
        if (this.isDataURI(path)) {
            return this.decodeDataURI(path);
        }
        const signal = createNativeSignal(abortSignal);
        const response = await fetch(path, { signal });
        return response.arrayBuffer();
    }
    async loadImageBuffer(arrayView, mimetype, abortSignal = AbortSignal.none) {
        const blob = new Blob([arrayView], { type: mimetype });
        return this.loadImageBlob(blob, abortSignal);
    }
    async loadImage(uri, abortSignal = AbortSignal.none) {
        const signal = createNativeSignal(abortSignal);
        const request = await fetch(uri, { signal });
        const blob = await request.blob();
        return this.loadImageBlob(blob, abortSignal);
    }
    async loadImageBlob(blob, abortSignal) {
        let promise;
        const hasCIB = await CreateImageBitmapAvailable;
        if (hasCIB) {
            //@ts-ignore
            promise = createImageBitmap(blob, {
                premultiplyAlpha: 'none',
                colorSpaceConversion: 'none'
            });
        }
        else {
            const img = new window.Image();
            const src = URL.createObjectURL(blob);
            const loadPromise = new Promise((resolve, reject) => {
                img.onload = resolve;
                img.onerror = reject;
                img.src = src;
            }).finally(() => URL.revokeObjectURL(src));
            promise = loadPromise.then(() => img);
        }
        return cancellablePromise(promise, abortSignal);
    }
    writeResource(path, data) {
        throw new Error("Method not implemented.");
    }
    writeBinaryResource(path, data) {
        throw new Error("Method not implemented.");
    }
}
export const IO = new WebImpl();
const _instance = new GltfIO(IO);
export default _instance;
