import Assert from '../lib/assert';
export default class Buffer {
    constructor() {
        this.gltftype = "buffers" /* BUFFER */;
    }
    async parse(gltfLoader, data) {
        this.byteLength = data.byteLength;
        this.uri = data.uri;
        this._byteOffset = 0;
        this._bytes = await gltfLoader.loadBufferUri(data.uri);
        Assert.isTrue(this._bytes !== null);
    }
}
