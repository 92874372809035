import Gltf2 from "../types/Gltf2";
import { BaseAccessor, getArrayForDataType } from "./Accessor";
import Assert from "../lib/assert";
export default class AccessorSparseIndices extends BaseAccessor {
    constructor() {
        super(...arguments);
        this.gltftype = "sparseIndices" /* ACCESSOR_SPARSE_INDICES */;
    }
    async parse(gltfLoader, data) {
        var _a;
        const sparseData = data.elementParent;
        this.normalized = false;
        this.byteOffset = (_a = data.byteOffset) !== null && _a !== void 0 ? _a : 0;
        this.componentType = data.componentType;
        this.count = sparseData.count;
        this.type = Gltf2.AccessorType.SCALAR;
        this.sparse = null;
        this.bufferView = await gltfLoader.getElement("bufferViews" /* BUFFERVIEW */, data.bufferView);
        const Arr = getArrayForDataType(this.componentType);
        if (this.bufferView.byteStride === 0) {
            this._stride = this.numComps * this.bytesPerElem;
            this._strideElem = this.numComps;
        }
        else {
            this._stride = this.bufferView.byteStride;
            this._strideElem = this._stride / Arr.BYTES_PER_ELEMENT;
            Assert.isTrue(this._strideElem === Math.round(this._strideElem));
        }
        this._array = new Arr(this.bufferView.buffer._bytes, this.byteOffset + this.bufferView.getByteOffset(), this.count * this._strideElem);
        this._valueHolder = this.createElementHolder();
        return Promise.resolve();
    }
}
