import { Uniform } from 'nanogl-pbr/Input';
import { SpecularSurface } from 'nanogl-pbr/PbrSurface';
import { isAllOnes } from '../../lib/Utils';
export default class PbrSpecularGlossiness {
    async parse(gltfLoader, data) {
        var _a;
        this.diffuseFactor = new Float32Array(data.diffuseFactor || [1, 1, 1, 1]);
        this.specularFactor = new Float32Array(data.specularFactor || [1, 1, 1]);
        this.glossinessFactor = (_a = data.glossinessFactor) !== null && _a !== void 0 ? _a : 1;
        if (data.diffuseTexture !== undefined) {
            gltfLoader.prepareGltfProperty(data.diffuseTexture, "textureInfo" /* TEXTURE_INFO */, -1, null);
            this.diffuseTexture = await gltfLoader._loadElement(data.diffuseTexture);
        }
        if (data.specularGlossinessTexture !== undefined) {
            gltfLoader.prepareGltfProperty(data.specularGlossinessTexture, "textureInfo" /* TEXTURE_INFO */, -1, null);
            this.specularGlossinessTexture = await gltfLoader._loadElement(data.specularGlossinessTexture);
        }
    }
    setupPass(pass) {
        const surface = new SpecularSurface();
        pass.setSurface(surface);
        if (this.diffuseTexture) {
            const diffuseSampler = this.diffuseTexture.createSampler('diffuse');
            surface.baseColor.attach(diffuseSampler, 'rgb');
            pass.alpha.attach(diffuseSampler, 'a');
        }
        if (!isAllOnes(this.diffuseFactor)) {
            const cFactor = new Uniform('uBasecolorFactor', 4);
            cFactor.set(...this.diffuseFactor);
            surface.baseColorFactor.attach(cFactor, 'rgb');
            pass.alphaFactor.attach(cFactor, 'a');
        }
        if (this.specularGlossinessTexture) {
            const mrSampler = this.specularGlossinessTexture.createSampler('specgloss');
            surface.specular.attach(mrSampler, 'rgb');
            surface.glossiness.attach(mrSampler, 'a');
        }
        if (!isAllOnes(this.specularFactor)) {
            surface.specularFactor.attachUniform('uMetalnessFactor').set(...this.specularFactor);
        }
        if (this.glossinessFactor !== 1) {
            surface.glossinessFactor.attachUniform('uRoughnessFactor').set(this.glossinessFactor);
        }
    }
}
