/////////////
//////////////////////////////////
//////////

import store from '@/store'
import Gltf from '@/webgl/lib/nanogl-gltf/lib'
import Scene from '@/webgl/scene'
import { GAME_GLOBAL_SPEED_SCALE } from '../gameConfig'
import BisonController from './@qte/BisonController'
import CleanupController from './@qte/CleanupController'
import DogdeController from './@qte/DodgeController'
import Texture from 'nanogl/texture'
import timeout from '@/utils/timeout'
import gameStatic from '../gameStatic'

export default class QTEController {
    _isQTEMode: boolean
    _isQTERuningMode: boolean
    _callbackChange: Function
    _onTransitionQte: Function
    _currQte:number
    isCountDown:boolean
    countDown:number
    scene:Scene
    currCtrl:any
    newQte:number
    countQte:number

    cleanUpCtrl:CleanupController
    dodgeCtrl:DogdeController
    bisonCtrl:BisonController
    
    constructor(callbackChange:Function, onTransitionQte:Function, scene:Scene) {
        this.scene = scene
        this.newQte = Math.floor(Math.random() * 3)
        this.countQte = 0
        this._currQte = store.getters['game/qte']
        this._callbackChange = callbackChange
        this._onTransitionQte = onTransitionQte
/////////////////////
///////////////////////
//////////////////
    }

    get isQte():boolean {
        return this._isQTEMode
    }

    get qteHasScene():boolean {
        return this._currQte === 2
    }

    get activeCtrl():any {
        return this.currCtrl
    }

    resetToRunning() {
        this.scene.game.pronghornEntity.leaveQte()
        // store.commit('game/setSpeedAfterQte')
        this._isQTEMode = this._isQTERuningMode = false

    }

    setActiveQte(id:number) {
        console.log('setActiveQte', id)
        this._isQTEMode = true
        this._isQTERuningMode = id === 0 || id === 1

        switch(id) {
            // remove junk
            case 0:
                this.currCtrl = this.cleanUpCtrl
                this.setCleanUp()
            break;
            // dodge
            case 1:
                this.currCtrl = this.dodgeCtrl
                this.setDodge()
            break;
            // bisons
            case 2:
                this.currCtrl = this.bisonCtrl
                this.setBisons()
            break;
        }
    }

    setQte(qte:number, prevQte: number) {
        this.stopCurrentQte(prevQte)
        if(qte < 0) {
            this._onTransitionQte(-1)
            this.resetToRunning()
        }
        else this.setActiveQte(qte)
        if(this._callbackChange) this._callbackChange( !this._isQTEMode, this._isQTERuningMode, prevQte, qte)
    }

    stopCurrentQte(prevQte:number) {
        switch(prevQte) {
            case 0:
                this.cleanUpCtrl.stop()
                break
            case 1:
                this.dodgeCtrl.stop()
                break
            case 2:
                this.bisonCtrl.stop()
                break
        }
    }

    // Clean up
    async setCleanUp() {
        this.scene.game.pronghornEntity.resetBeforeQte()
        this.cleanUpCtrl.start()
        await timeout(1200)
        this.scene.game.pronghornEntity.startQte()
        // await timeout(600)
        // this.isCountDown = true
        // this.countDown = CLEANUP_TIME
    }

    // Dodge
    async setDodge() {
        this.scene.game.pronghornEntity.resetBeforeQte()
        this.dodgeCtrl.start()
        await timeout(1200)
        this.scene.game.pronghornEntity.startQte()
        // await timeout(600)
        // this.isCountDown = true
        // this.countDown = DODGE_TIME
    }

    //Bisons
    async setBisons() {
        this.bisonCtrl.start()
        // await timeout(1800)
        // this.isCountDown = true
        // this.countDown = DODGE_TIME
    }

    init(cleanupScene:Gltf, dodgeScene:Gltf, bisonsScene:Gltf, shadowTex:Texture) {
        this.cleanUpCtrl = new CleanupController(cleanupScene, this.scene);
        this.dodgeCtrl = new DogdeController(dodgeScene, this.scene, shadowTex);
        this.bisonCtrl = new BisonController(bisonsScene, this.scene);
    }

    updateNodes(dt:number, globalSpeed:number, changeSpeed:number) {
        if(globalSpeed === 1 && this._currQte === -1 && !store.getters['game/qteTransition'] && gameStatic.time > 1000) {
            store.commit('game/setQTE', { qte: this.newQte % 3})
            this._onTransitionQte(this.newQte % 3)
            this.countQte++
            if(this.countQte === 4) {
                this.countQte = 0
                store.commit('game/addLevel')
            }
            this.newQte++
        }
        const qte = store.getters['game/qte']
        if(qte !== this._currQte) {
            this.setQte(qte, this._currQte)
            this._currQte = qte
        }

        if(this._currQte > -1) {
            switch(this._currQte) {
                case 0:
                    this.cleanUpCtrl.updateNodes(dt, globalSpeed * GAME_GLOBAL_SPEED_SCALE, changeSpeed)
                    break
                case 1:
                    this.dodgeCtrl.updateNodes(dt, globalSpeed * GAME_GLOBAL_SPEED_SCALE, changeSpeed)
                    break
                case 2:
                    this.bisonCtrl.updateNodes(dt, globalSpeed * GAME_GLOBAL_SPEED_SCALE, changeSpeed)
                    break
            }
        }
    }

    preRender(dt:number) {
        if(this._currQte > -1) {
            switch(this._currQte) {
                case 0:
                    this.cleanUpCtrl.preRender(dt)
                    break
                case 1:
                    this.dodgeCtrl.preRender(dt)
                    break
                case 2:
                    this.bisonCtrl.preRender(dt)
                    break
            }
        }
    }

    render() {
        if(this._currQte > -1) {
            switch(this._currQte) {
                case 0:
                    this.cleanUpCtrl.render()
                    break
                case 1:
                    this.dodgeCtrl.render()
                    break
                case 2:
                    this.bisonCtrl.render()
                    break
            }
        }
    }

    renderAfter() {
        if(this._currQte > -1) {
            switch(this._currQte) {
                case 0:
                    this.cleanUpCtrl.renderAfter()
                    break
                case 1:
                    this.dodgeCtrl.renderAfter()
                    break
                // case 2:
                //     this.bisonCtrl.render()
                //     break
            }
        }
    }

/////////////////
////////////////
////////////////////////////
///////////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////////
///////////////////
/////
////////////////
////////////////////////////
///////////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////////
///////////////////
/////
////////////////
////////////////////////////
///////////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////////
///////////////////
/////

////////////////
////////////////////////////
///////////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////////
///////////////////
/////
////
////////////////
////////////////////////////
///////////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////////
///////////////////
/////
////
////////////////
////////////////////////////
///////////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////////
///////////////////
/////

////////////////
////////////////////////////
///////////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////////
///////////////////
/////

////////////////
////////////////////////////
///////////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////////
///////////////////
/////

////////////////
////////////////////////////
///////////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////////
///////////////////
/////

////////////////
///////////////////////////////////////////////
///////////////////
/////

///////////////
//////////////////////////////////
////////////////////////////////
////////////////////////////////
/////


////////////
///////////////////////////////////////
//////////////////////////////////////
/////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
/////
////////////
}