<template>
  <div class="gesture-bisons">
    <div
      v-for="(target, i) in bisonsTarget"
      :key="'bison' + i"
      class="gesture-bisons-target"
      :class="{
        clickable: target.started && getValue(i) > 0 && getValue(i) < 1,
        dead: getValue(i) >= 1 && !success[i],
        good: success[i]
      }"
      :style="{
        transform: `translate(${target.x + 52 / 2}px, ${target.y + 52 / 2}px)`
      }"
    >
      <div
        class="gesture-bisons-target-click-timing"
        :style="{
          transform: `translate(-50%, -50%)`,
          width: `${success[i] ? 35 : 15 + (1 - getValue(i)) * 6 * 52}px`,
          height: `${success[i] ? 35 : 15 + (1 - getValue(i)) * 6 * 52}px`,
          opacity: 0.3 + getValue(i) * 0.7
        }"
      />
      <div class="gesture-bisons-target-middle">
        <div>Tap</div>
      </div>
      <button
        class="t-btn gesture-bisons-target-button"
        @click="event => targetClick(event, i)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  onActivated,
  onDeactivated,
  onMounted,
  ref,
  watch
} from 'vue'
import store from '@/store'
import timeout from '@/utils/timeout'
import {
  CLICK_START_TIME,
  CLICK_END_TIME,
  TARGET_SPEED
} from '@webgl/game/gameConfig'
import { useHowler } from '@/plugins/Howler'
import gameStatic from '@/webgl/game/gameStatic'
const expoIn = require('eases/quart-in')
const smoothstep = require('smoothstep')
export default {
  setup() {
    const bisonsTarget = computed(() => store.getters['game/bisonsPosition'])
    const times = ref([])
    const isResults = ref([])
    const success = ref([])
    const failed = ref([])
    const count = ref(0)
    const currStart = ref(0)

    const results = computed(() => store.getters['game/isResultMode'])

    const howler = useHowler()

    const getValue = i => {
      return expoIn(
        smoothstep(CLICK_START_TIME, CLICK_END_TIME, times.value[i])
      )
    }

    const watchResults = watch(results, async () => {
      if (results.value) {
        for (let i = 0; i < bisonsTarget.value.length; i++) {
          await timeout(50 * i)
          isResults.value[i] = true
        }
      }
    })

    const fillTargets = (delay = 0) => {
      for (let i = 0; i < bisonsTarget.value.length; i++) {
        times.value.push(0)
        success.value.push(false)
      }
      start(bisonsTarget.value[count.value], delay)
    }

    const watchBisonsTarget = watch(bisonsTarget, () => {
      if (times.value.length === 0) fillTargets(2500)
    })

    const update = (dt: number) => {
      bisonsTarget.value.forEach((t, i) => {
        if (t.started) {
          times.value[i] = Math.min(
            1,
            times.value[i] +
              (dt / 1000) * (TARGET_SPEED + 0.3 * store.getters['game/level'])
          )

          if (getValue(i) >= 1 && !success.value[i] && !failed.value[i]) {
            store.commit('game/enableFail')
            howler.$getSound('qte-fail', true).play()
            failed.value[i] = true
            currStart.value++
            if (currStart.value < bisonsTarget.value.length)
              start(bisonsTarget.value[currStart.value], 0)
          }
        }
      })

      if (
        getValue(bisonsTarget.value.length - 1) >= 1 &&
        !store.getters['game/isResultMode']
      )
        store.commit('game/setToQteResult')
    }

    const start = async (target, delay) => {
      await timeout(delay)
      target.started = true
    }

    const targetClick = (event: TouchEvent, id: number) => {
      success.value[id] = true
      count.value++
      const score = store.getters['game/tempScore']

      store.commit('game/setTempScore', {
        score: score + Math.round(gameStatic.dodgeScore * gameStatic.scoreScale)
      })

      const resArr = success.value.filter(v => v === true)
      store.commit('game/setQteSuccess', {
        percSuccess: resArr.length / success.value.length
      })
      currStart.value++
      if (currStart.value < bisonsTarget.value.length)
        start(bisonsTarget.value[currStart.value], 0)
    }

    onActivated(async () => {
      store.commit('game/setQteSuccess', { percSuccess: 0 })
      if (times.value.length === 0) fillTargets(1500)
    })

    onDeactivated(() => {
      watchBisonsTarget()
      watchResults()
    })

    return {
      bisonsTarget,
      update,
      results,
      times,
      isResults,
      success,
      failed,
      getValue,
      count,
      currStart,
      targetClick,
      CLICK_START_TIME,
      CLICK_END_TIME
    }
  }
}
</script>

<style lang="scss">
.gesture-bisons {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &-target {
    position: absolute;
    top: -25px;
    left: -25px;
    border-radius: 50%;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s $ease-out-sine;
    &-button {
      pointer-events: none;
      width: 200px;
      height: 200px;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);
    }
    &-middle {
      border-radius: 50%;
      background: color(red);
      width: 45px;
      height: 45px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0.75);
      color: #ffeed5;
      text-transform: uppercase;
      justify-content: center;
      align-items: center;
      display: flex;
      div {
        opacity: 0;
      }
    }
    &-click-timing {
      position: absolute;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      width: 52px;
      height: 52px;
      border: 2px solid white;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(255, 236, 210, 0) 0%,
        #ffecd2 100%
      );
    }
    &.clickable {
      opacity: 1;
      transition: opacity 0.3s $ease-out-sine;
      .gesture-bisons-target-middle {
        transform: translate(-50%, -50%) scale(1);
        transition: transform 0.6s $ease-in-out-quart;
        div {
          opacity: 0.8;
        }
      }

      .gesture-bisons-target-button {
        pointer-events: all;
      }
    }
    &.dead,
    &.good {
      pointer-events: none;
      opacity: 1;
      .gesture-bisons-target-click-timing {
        opacity: 0 !important;
        transition: opacity 0.1ms;
      }
      .gesture-bisons-target-middle {
        transform: translate(-50%, -50%) scale(0.5);
        transition: transform 0.6s $ease-in-out-quart,
          opacity 0.1s $ease-out-sine;
        opacity: 0.3 !important;
      }
    }

    &.dead {
      .gesture-bisons-target-middle {
        background: #ff0000;
        transition: background 0.25s $ease-out-sine;
        opacity: 1 !important;
      }
    }

    &.good {
      opacity: 0.5 !important;
      transition: opacity 0.4s $ease-out-sine 0.5s;
      .gesture-bisons-target-middle {
        background: #7afc87;
        transition: background 0.5s $ease-out-sine;
        opacity: 1 !important;
      }
      .gesture-bisons-target-click-timing {
        opacity: 1 !important;
        background: transparent;
        transition: opacity 0.4s $ease-out-sine, width 0.5s $ease-out-sine,
          height 0.5s $ease-out-sine, background 0.5s $ease-out-sine;
      }
    }
    // &.dead {
    //   pointer-events: none;

    // }
    // &.good {
    //   pointer-events: none;
    //   .gesture-bisons-target-click-timing {
    //     opacity: 1;
    //   }
    //   .gesture-bisons-target-middle {
    //     transform: translate(-50%, -50%) scale(1);
    //     transtion: transform 0.6s $ease-in-out-quart;
    //   }
    // }

    // &.hide {
    //   transition: opacity 0.2s $ease-out-sine;
    //   opacity: 0.2;
    //   .gesture-bisons-target-middle {
    //     transform: translate(-50%, -50%) scale(0.5);
    //     transition: transform 0.4s $ease-out-quad;
    //   }
    //   .gesture-bisons-target-click-timing {
    //     opacity: 0 !important;
    //     transition: opacity 0.1s $ease-out-sine;
    //   }
    // }
  }
  &-count {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    mix-blend-mode: difference;
    color: white;
    opacity: 0.8;
    font-size: 15vw;
  }
}
</style>
