import { webpSupport } from "./webpSupport";
/**
 * this extension actually do nothing
 * vanilla parser already support arbitrary attributes type and normalization
 */
const EXT_ID = 'EXT_texture_webp';
class Instance {
    constructor(gltfLoader) {
        this.name = EXT_ID;
        this.priority = -10;
        this.loader = gltfLoader;
    }
    async acceptElement(data, element) {
        if (element.gltftype === "textures" /* TEXTURE */ && data.extensions && data.extensions[EXT_ID]) {
            const webpSupported = await webpSupport();
            if (webpSupported) {
                element.source = await this.loader.getElement("images" /* IMAGE */, data.extensions[EXT_ID].source);
            }
        }
        return element;
    }
    loadElement(data) {
        return null;
    }
}
export default class KHR_texture_transform {
    constructor() {
        this.name = EXT_ID;
    }
    createInstance(gltfLoader) {
        return new Instance(gltfLoader);
    }
}
