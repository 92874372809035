const GL_REPEAT = 10497;
export default class Sampler {
    constructor() {
        this.gltftype = "samplers" /* SAMPLER */;
    }
    parse(gltfLoader, data) {
        var _a, _b;
        this.magFilter = data.magFilter;
        this.minFilter = data.minFilter;
        this.wrapS = (_a = data.wrapS) !== null && _a !== void 0 ? _a : GL_REPEAT;
        this.wrapT = (_b = data.wrapT) !== null && _b !== void 0 ? _b : GL_REPEAT;
        return Promise.resolve();
    }
}
