import Animation from "../elements/Animation";
import AnimationChannel from "../elements/AnimationChannel";
import AnimationSampler from "../elements/AnimationSampler";
import Accessor from "../elements/Accessor";
import Camera from "../elements/Camera";
import Material from "../elements/Material";
import Mesh from "../elements/Mesh";
import NormalTextureInfo from "../elements/NormalTextureInfo";
import OcclusionTextureInfo from "../elements/OcclusionTextureInfo";
import PbrMetallicRoughness from "../elements/PbrMetallicRoughness";
import Primitive from "../elements/Primitive";
import Sampler from "../elements/Sampler";
import Scene from "../elements/Scene";
import Skin from "../elements/Skin";
import Texture from "../elements/Texture";
import TextureInfo from "../elements/TextureInfo";
import Node from "../elements/Node";
import Image from "../elements/Image";
import Gltf from "..";
import Asset from "../elements/Asset";
import Buffer from "../elements/Buffer";
import BufferView from "../elements/BufferView";
import AccessorSparse from "../elements/AccessorSparse";
import AccessorSparseIndices from "../elements/AccessorSparseIndices";
import AccessorSparseValues from "../elements/AccessorSparseValues";
class DefaultExtensionInstance {
    constructor(gltfLoader) {
        this.name = 'default';
        this.priority = 0;
        this.loader = gltfLoader;
    }
    acceptElement(data, element) {
        return null;
    }
    loadElement(data) {
        switch (data.gltftype) {
            case "accessors" /* ACCESSOR */: return this.createAccessor(data);
            case "sparse" /* ACCESSOR_SPARSE */: return this.createAccessorSparse(data);
            case "sparseIndices" /* ACCESSOR_SPARSE_INDICES */: return this.createAccessorSparseIndices(data);
            case "sparseValues" /* ACCESSOR_SPARSE_VALUES */: return this.createAccessorSparseValues(data);
            case "animations" /* ANIMATION */: return this.createAnimation(data);
            case "animationSamplers" /* ANIMATION_SAMPLER */: return this.createAnimationSampler(data);
            case "animationChannels" /* ANIMATION_CHANNEL */: return this.createAnimationChannel(data);
            case "asset" /* ASSET */: return this.createAsset(data);
            case "buffers" /* BUFFER */: return this.createBuffer(data);
            case "bufferViews" /* BUFFERVIEW */: return this.createBufferview(data);
            case "cameras" /* CAMERA */: return this.createCamera(data);
            case "images" /* IMAGE */: return this.createImage(data);
            case "materials" /* MATERIAL */: return this.createMaterial(data);
            case "meshes" /* MESH */: return this.createMesh(data);
            case "nodes" /* NODE */: return this.createNode(data);
            case "normalTextureInfo" /* NORMAL_TEXTURE_INFO */: return this.createNormalTextureInfo(data);
            case "occlusionTextureInfo" /* OCCLUSION_TEXTURE_INFO */: return this.createOcclusionTextureInfo(data);
            case "primitives" /* PRIMITIVE */: return this.createPrimitive(data);
            case "samplers" /* SAMPLER */: return this.createSampler(data);
            case "scenes" /* SCENE */: return this.createScene(data);
            case "skins" /* SKIN */: return this.createSkin(data);
            case "textures" /* TEXTURE */: return this.createTexture(data);
            case "textureInfo" /* TEXTURE_INFO */: return this.createTextureInfo(data);
        }
    }
    async createAccessor(data) {
        const el = new Accessor();
        await el.parse(this.loader, data);
        return el;
    }
    async createAccessorSparse(data) {
        const el = new AccessorSparse();
        await el.parse(this.loader, data);
        return el;
    }
    async createAccessorSparseIndices(data) {
        const el = new AccessorSparseIndices();
        await el.parse(this.loader, data);
        return el;
    }
    async createAccessorSparseValues(data) {
        const el = new AccessorSparseValues();
        await el.parse(this.loader, data);
        return el;
    }
    async createAsset(data) {
        const el = new Asset();
        await el.parse(this.loader, data);
        return el;
    }
    async createBuffer(data) {
        const el = new Buffer();
        await el.parse(this.loader, data);
        return el;
    }
    async createBufferview(data) {
        const el = new BufferView();
        await el.parse(this.loader, data);
        return el;
    }
    async createAnimationChannel(data) {
        const el = new AnimationChannel();
        await el.parse(this.loader, data);
        return el;
    }
    async createAnimationSampler(data) {
        const el = new AnimationSampler();
        await el.parse(this.loader, data);
        return el;
    }
    async createAnimation(data) {
        const el = new Animation();
        await el.parse(this.loader, data);
        return el;
    }
    async createCamera(data) {
        const el = new Camera();
        await el.parse(this.loader, data);
        return el;
    }
    async createImage(data) {
        const el = new Image();
        await el.parse(this.loader, data);
        return el;
    }
    async createMaterial(data) {
        const el = new Material();
        await el.parse(this.loader, data);
        return el;
    }
    async createMesh(data) {
        const el = new Mesh();
        await el.parse(this.loader, data);
        return el;
    }
    async createNode(data) {
        const el = new Node();
        await el.parse(this.loader, data);
        return el;
    }
    async createNormalTextureInfo(data) {
        const el = new NormalTextureInfo();
        await el.parse(this.loader, data);
        return el;
    }
    async createOcclusionTextureInfo(data) {
        const el = new OcclusionTextureInfo();
        await el.parse(this.loader, data);
        return el;
    }
    async createPbrMetallicRoughness(data) {
        const el = new PbrMetallicRoughness();
        await el.parse(this.loader, data);
        return el;
    }
    async createPrimitive(data) {
        const el = new Primitive();
        await el.parse(this.loader, data);
        return el;
    }
    async createSampler(data) {
        const el = new Sampler();
        await el.parse(this.loader, data);
        return el;
    }
    async createScene(data) {
        const el = new Scene();
        await el.parse(this.loader, data);
        return el;
    }
    async createSkin(data) {
        const el = new Skin();
        await el.parse(this.loader, data);
        return el;
    }
    async createTexture(data) {
        const el = new Texture();
        await el.parse(this.loader, data);
        return el;
    }
    async createTextureInfo(data) {
        const el = new TextureInfo();
        await el.parse(this.loader, data);
        return el;
    }
}
class DefaultExtensionFactory {
    constructor() {
        this.name = 'default';
    }
    createInstance(gltfLoader) {
        return new DefaultExtensionInstance(gltfLoader);
    }
}
const defaultExtension = new DefaultExtensionFactory();
Gltf.addExtension(defaultExtension);
