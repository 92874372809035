import store from "@/store";
import gameStatic from "@/webgl/game/gameStatic";

export const courseUpdate = (dt: number, gestureLeft:any, gestureRight:any) => {
    const speed = gameStatic.speed
    // store.commit('game/resetCount')
    const objCount = { v: 0 }
    gestureLeft.update(dt, speed, objCount);
    gestureRight.update(dt, speed, objCount);
    if(objCount.v !== 0) {
        gameStatic.speed += objCount.v * 0.0017
        gameStatic.speed = Math.max(0, Math.min(1, gameStatic.speed))
        gameStatic.score += Math.round(Math.max(objCount.v * 0.1, 0) * (0.5 + speed * 0.5) * gameStatic.runningScore * gameStatic.scoreScale * 2)
    }
        // store.commit('game/setSpeed', {
        //     speed: objCount.v
        // })
}
