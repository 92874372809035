export default class Asset {
    constructor() {
        this.gltftype = "asset" /* ASSET */;
    }
    parse(gltfLoader, data) {
        this.version = data.version;
        this.copyright = data.copyright;
        this.generator = data.generator;
        this.minVersion = data.minVersion;
        return Promise.resolve();
    }
}
