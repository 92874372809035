
import GestureHandling from '@/components/@game/GestureHandling.vue'
import GLViewer from '@/components/@game/GLViewer.vue'
import GameLoader from '@/components/@game/GameLoader.vue'
import GameTuto from '@/components/@game/GameTuto.vue'
import GameTransition from '@/components/@game/GameTransition.vue'
import {
  computed,
  nextTick,
  onActivated,
  onDeactivated,
  onMounted,
  ref,
  watch,
  watchEffect
} from 'vue'
import { checkPage, isAutoRunMode, watchResize } from '@/hooks/global'
import store from '@/store'
import { useHowler } from '../plugins/Howler'
import { useRouter } from 'vue-router'
import timeout from '@/utils/timeout'
import gameStatic from '@/webgl/game/gameStatic'
import { useGtm } from 'vue-gtm'

export default {
  name: 'Game',
  components: {
    'game-tuto': GameTuto,
    'game-transition': GameTransition,
    'gesture-handling': GestureHandling,
    'gl-viewer': GLViewer,
    'game-loader': GameLoader
  },
  setup() {
    const check = ref(checkPage())
    const router = useRouter()
    const isAutoMode = isAutoRunMode()
    const active = ref(false)
    const isTuto = ref(false)
    const activeLoader = ref(true)
    const showWhiteScreen = ref(false)
    const showDirectWhiteScreen = ref(false)
    const howler = useHowler()
    const gameLoader = ref(null)
    const ctn = ref(null)
    const gtm = useGtm()

    const transition = computed(() => store.getters['game/qteTransition'])

    // Dom Refs
    const gesture = ref(null)

    const isLoaded = ref(false)
    const isStarted = ref(false)
    watchEffect(() => {
      if (
        store.getters['game/isLoaded'] &&
        !isLoaded.value &&
        gameLoader.value
      ) {
        gameLoader.value.onLeave(() => {
          isLoaded.value = true
        })
      }
    })

    const isGameDone = computed(() => {
      return store.getters['game/isGameDone']
    })

    const watchisGameDone = watch(isGameDone, async (gameDone: boolean) => {
      if (gameDone) {
        showWhiteScreen.value = true
        await timeout(2000)
        gtm.trackEvent({
          event: 'eventPush',
          eventAction: 'score',
          eventLabel: '',
          eventValue: store.getters['game/score'],
          eventCategory: 'game'
        })
        router.push({
          path: '/score'
        })
      }
    })

    const update = (delta: number) => {
      if (isAutoMode) return
      ;(<any>gesture.value).update(delta * 1000)
    }

    const loaded = async () => {
      await timeout(300)
      showDirectWhiteScreen.value = false
      nextTick(() => (showWhiteScreen.value = false))
      await timeout(500)
      // Enable tuto
      if (!isAutoMode) isTuto.value = true
      else {
        isTuto.value = false
        store.commit('game/startGame')
      }
      // isTuto.value = false
      // store.commit('game/startGame')
    }

    const startGame = async () => {
      if (
        (!store.getters['global/getOrientation'] ||
          store.getters['global/forceLandscape']) &&
        !isStarted.value
      ) {
        showDirectWhiteScreen.value = true
        showWhiteScreen.value = true
        isStarted.value = true
        // active.value = true
        document.querySelector('html').classList.add('game')
        store.commit('game/reset')
        store.commit('global/toggleInGame', { inGame: true })
        await timeout(5000)
        active.value = true
        if (store.getters['global/howlerLayer1'] !== null) {
          howler.$getSound('layer2').once('fade', () => {
            howler.$getSound('layer2').stop()
          })
          howler
            .$getSound('layer2')
            .fade(1, 0, 2000, store.getters['global/howlerLayer1'])
        }
      }
      if (store.getters['global/forceLandscape']) forceLandscapeResizeFix()

      if (isAutoMode) {
        await timeout(4000)
        gameStatic.speed = 0.99
      }
    }

    const hideTuto = () => {
      isTuto.value = false
      store.commit('game/startGame')
    }

    let unwatch = null

    onActivated(async () => {
      isTuto.value = false
      activeLoader.value = true
      startGame()

      unwatch = watchResize(async (w, h, isLandscape) => {
        startGame()
      })

      if (store.getters['global/forceLandscape']) forceLandscapeResizeFix()
    })

    onMounted(() => {
      if (store.getters['global/forceLandscape']) forceLandscapeResizeFix()
    })

    const forceLandscapeResizeFix = async () => {
      await timeout(5)
      document.documentElement.style.display = 'none'
      store.commit('global/triggerResize', { triggeredResize: true })
      await timeout(5)
      document.documentElement.style.display = ''
      store.commit('global/triggerResize', { triggeredResize: true })
    }

    onDeactivated(() => {
      unwatch()
      isLoaded.value = false
      isStarted.value = false
      active.value = false
      activeLoader.value = false

      document.querySelector('html').classList.remove('game')
      store.commit('global/toggleInGame', { inGame: false })
    })

    return {
      showDirectWhiteScreen,
      showWhiteScreen,
      forceLandscapeResizeFix,
      isAutoMode,
      active,
      activeLoader,
      check,
      ctn,
      gesture,
      update,
      isLoaded,
      isTuto,
      loaded,
      gameLoader,
      hideTuto,
      transition
    }
  }
}
