/////////////
//////////////////////////////////
//////////

import Gltf from "@/webgl/lib/nanogl-gltf/lib"
import UnlitMaterial from "@/webgl/lib/nanogl-gltf/lib/extensions/KHR_materials_unlit/UnlitMaterial";
import IRenderable from "@/webgl/lib/nanogl-gltf/lib/renderer/IRenderable";
import MeshRenderer from "@/webgl/lib/nanogl-gltf/lib/renderer/MeshRenderer";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import Scene from "@/webgl/scene";
import GltfNode from "@webgl/lib/nanogl-gltf/lib/elements/Node";
import { vec3, vec4 } from "gl-matrix";
import BaseMaterial from "nanogl-pbr/BaseMaterial";
import UnlitPass from "nanogl-pbr/UnlitPass";
import Texture from "nanogl/texture";
import { GLContext } from "nanogl/types";
import BackgroundParallaxPass from "../../glsl/backgroundparallax";
import { ParallaxUnit } from "../../glsl/backgroundparallax";

const EL_Y = [
    0,
    -3.1,
    -5.6,
    -5.7,
    // -8.5
    -9
]

export default class BackgroundParallaxEntity {
    node:GltfNode
    mat:BaseMaterial
    bgPass:BackgroundParallaxPass
    scene:Scene
    constructor(gltf:Gltf, scene:Scene) {
        this.scene = scene
        this.node = (gltf.renderables[0] as MeshRenderer).node
        const diff = -4.4 - this.node.position[2]
        const refScale = (gltf.renderables[0] as MeshRenderer).node.scale
        const refPosition:vec3 = (gltf.renderables[0] as MeshRenderer).node.position
        const gl:GLContext = scene.gl
        const allUnits:Array<ParallaxUnit> = gltf.renderables.map((re:IRenderable, i:number) => {
            const r:MeshRenderer = re as MeshRenderer
            const tex:Texture = ((r.mesh.primitives[0].material as UnlitMaterial).materialPass as UnlitPass).baseColor.param['_tex']
            tex.bind()
            gl.texParameteri(tex._target, gl.TEXTURE_WRAP_S, gl.REPEAT);
            gl.texParameteri(tex._target, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
            tex.setFilter( true, false, false )
            const nodeScale:vec3 = r.node.scale
            const wTex = (nodeScale[0] / refScale[0])
            const hTex = (nodeScale[1] / refScale[1])
            const scalePos = vec4.create()
            vec4.set(scalePos, 
                wTex,
                hTex,
                EL_Y[i],
                (-4.4 -( r.node.z + diff)) * 0.1)
            return {
                texture: tex,
                scalePos
            }
        })
        this.node.z = -4.4

        this.mat = new BaseMaterial(scene.gl, 'bg-parallax' );
        this.bgPass = new BackgroundParallaxPass(allUnits, 'bg-parallax')
        this.mat.addPass(this.bgPass)

/////////////////////
///////////////////
//////////////////
    }

    preRender(scroll:number) {
        this.bgPass.preRender(scroll, 1)
    }

    render() {
        const prg = this.mat.getAllPasses()[0].getProgram()
        prg.use();
        this.bgPass.prepare(prg, this.node, this.scene.camera)
        for (let i = 0; i < this.node.mesh.primitives.length; i++) {
            const primitive = this.node.mesh.primitives[i];
            this.drawCall(this.scene.game.getCamera(), prg, primitive)
        }
    }
    drawCall(camera, prg, sub) {
        sub.bindVao(prg);
        sub.render();
        sub.unbindVao();
    }

/////////////////
////////////
//////////////////////////////////////////////
/////////////////////////////////////////////
////////////////////
//////////////////////////
/////////
/////////////////////////////////////////////////////////////////////////////
/////
//////////////
}