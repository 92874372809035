<template>
  <button
    ref="root"
    class="t-btn gesture-clean-up"
    :class="{ 'show-interface': showInterface, 'result-mode': resultMode }"
    @click="onClick"
  >
    <canvas ref="canvas" class="gesture-clean-up-canvas" />
    <button
      class="t-btn gesture-clean-up-middle-circle"
      :class="{ 'is-tapped': isTapped }"
    >
      <div class="gesture-clean-up-middle-circle-inside">
        <div>Tap</div>
        <div>Tap</div>
        <div>Tap</div>
      </div>
    </button>
  </button>
</template>

<script lang="ts">
import { computed, ref, watch, onMounted, onUnmounted } from 'vue'
import store from '@/store'
import { ADD_LEVEL_JUNK, TOTAL_JUNK } from '@/webgl/game/gameConfig'
import timeout from '@/utils/timeout'

import { useHowler } from '../../plugins/Howler'
import gameStatic from '@/webgl/game/gameStatic'

const quartout = require('eases/quart-in-out')

const CIRCLE_SIZE = 244
const CIRCLE_SIZE_HALF = CIRCLE_SIZE / 2

export default {
  setup() {
    const root = ref(null)
    const canvas = ref(null)
    const ctx = ref(null)
    const score = computed(() => store.getters['game/cleanUp'])
    const countdown = ref(0)
    const intro = ref(0)
    const isStarted = ref(false)
    const isTapped = ref(false)
    const scoreDone = computed(
      () =>
        TOTAL_JUNK + ADD_LEVEL_JUNK * store.getters['game/level'] - score.value
    )

    const howler = useHowler()

    const forceLandscape = computed(
      () => store.getters['global/forceLandscape']
    )

    const size = computed(() => store.getters['global/getViewportSize'])

    const resultMode = computed(() => store.getters['game/isResultMode'])
    const showInterface = ref(false)

    // const resultWatch = watch(resultMode, (isResults: boolean) => {
    //   if (isResults)
    //     store.commit('game/addToScore', {
    //       score: TOTAL_JUNK - store.getters['game/cleanUp']
    //     })
    // })

    const onClick = async () => {
      if (
        isStarted.value &&
        !resultMode.value &&
        store.getters['game/cleanUp'] > 0
      ) {
        store.commit('game/clickCleanUp')
        const s = store.getters['game/tempScore']
        store.commit('game/setTempScore', {
          score: s + Math.round(gameStatic.cleanScore * gameStatic.scoreScale)
        })
        store.commit('game/setQteSuccess', {
          percSuccess:
            1 -
            store.getters['game/cleanUp'] /
              (TOTAL_JUNK + ADD_LEVEL_JUNK * store.getters['game/level'])
        })
        isTapped.value = true
        const playClick = howler.$getSound('dodge-tap', true).play()
        howler
          .$getSound('dodge-tap', true)
          .rate(0.75 + Math.random() * 0.5, playClick)
        await timeout(250)
        isTapped.value = false
      }
    }

    const update = (dt: number) => {
      if (resultMode.value) return
      if (isStarted.value) countdown.value += dt
      else intro.value += dt
      const context = ctx.value
      const introVal = Math.min(1, Math.max(intro.value / 2000, 0))
      canvas.value.width = canvas.value.width * 1
      context.strokeStyle = '#FFFFFF'
      context.lineWidth = 4
      context.beginPath()
      context.arc(
        CIRCLE_SIZE_HALF + 3,
        CIRCLE_SIZE_HALF + 3,
        CIRCLE_SIZE_HALF,
        -Math.PI * 0.5 + Math.PI * 2 * quartout(1 - introVal),
        -Math.PI * 0.5 + Math.PI * 2
      )
      context.stroke()
      context.strokeStyle = '#D8635F'
      context.fillStyle = 'rgba(216,99,95,0.3)'
      context.beginPath()
      // context.moveTo(CIRCLE_SIZE_HALF + 3, CIRCLE_SIZE_HALF + 3)
      context.arc(
        CIRCLE_SIZE_HALF + 3,
        CIRCLE_SIZE_HALF + 3,
        CIRCLE_SIZE_HALF,
        -Math.PI * 0.5 + Math.PI * 2 * quartout(1 - introVal),
        -Math.PI * 0.5 +
          Math.PI * 2 * (1 - Math.min(1, Math.max(countdown.value / 5000, 0)))
      )
      if (countdown.value / 5000 >= 1 && !resultMode.value)
        store.commit('game/setToQteResult')

      if (resultMode.value) {
        intro.value -= dt
      }
      // context.lineTo(CIRCLE_SIZE_HALF + 3, CIRCLE_SIZE_HALF + 3)
      context.stroke()
      // context.fill()
    }

    onMounted(async () => {
      console.log('on activated')
      showInterface.value = false
      isStarted.value = false
      store.commit('game/setQteSuccess', { percSuccess: 0 })
      countdown.value = 0
      intro.value = -1000
      ctx.value = canvas.value.getContext('2d')
      canvas.value.width = 250
      canvas.value.height = 250
      // store.commit('game/clickCleanUp')
      store.commit('game/resetCleanUp')
      await timeout(2000)
      showInterface.value = true
      await timeout(1000)
      isStarted.value = true
    })
    onUnmounted(() => {})
    return {
      isStarted,
      size,
      forceLandscape,
      root,
      ctx,
      canvas,
      score,
      intro,
      countdown,
      showInterface,
      resultMode,
      isTapped,
      // resultWatch,
      quartout,
      onClick,
      update,
      scoreDone
    }
  }
}
</script>

<style lang="scss">
.gesture-clean-up {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &-canvas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
    height: 250px;
  }
  &-middle-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    &-inside {
      width: 60px;
      height: 60px;
      position: absolute;
      top: 50%;
      left: 50%;
      overflow: hidden;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      color: white;
      text-transform: uppercase;
      justify-content: center;
      align-items: center;
      background: color(light-red);
      border-radius: 50%;
      & > div {
        line-height: 0.8;
        opacity: 0.5;
        &:first-child {
          opacity: 1;
        }
        &:last-child {
          opacity: 0.2;
        }
      }
    }
  }
  &.show-interface {
    .gesture-clean-up-middle-circle {
      transform: translate(-50%, -50%) scale(1);
      transition: transform 0.4s $ease-out-quart;
      // .gesture-clean-up-middle-circle-inside {

      // }
      &.is-tapped {
        .gesture-clean-up-middle-circle-inside {
          transform: translate(-50%, -50%) scale(1.2);
          transition: transform 0.2s $ease-out-quart;
        }
      }
    }

    &.result-mode {
      .gesture-clean-up-middle-circle {
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s $ease-out-quart;
      }

      .gesture-clean-up-canvas {
        transform-origin: 50% 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s $ease-out-quart 0.1s;
      }
    }
  }
}
</style>
