
/////////////
///////////////////////////////////////////////
//////////////////////////////////////////////
//////////////////////////////////////////
////////////////////////////////////////////////
///////////////////////////////////////////////////

//////////////////////////////////////////////////////////
///////////
//////////


import   GLState           from 'nanogl-state'
import   Node              from 'nanogl-node'
import   Camera            from 'nanogl-camera'
import   Rect              from 'nanogl-primitives-2d/rect'
import { GLContext       } from 'nanogl/types'
import   PerspectiveLens   from 'nanogl-camera/perspective-lens'

import { mat4 } from 'gl-matrix'

import Programs          from '@/webgl/gl/programs'
import PostProcess       from '@/webgl/gl/post'
import Inputs            from '@/webgl/lib/inputs'
import Materials         from '@/webgl/assets/materials-lib'
import CamControler      from '@/webgl/camera/CameraController'
import MaxControler      from '@/webgl/camera/MaxController'
import Lens              from '@/webgl/camera/Lens'
import Reflect           from '@/webgl/gl/Reflect'
import GLView            from '@/webgl/glview'
import CameraControler   from '@/webgl/camera/CameraController'
// import MasterHead        from '@/webgl/masterhead'
import Game        from '@/webgl/game'

import { DEG2RAD }  from '@/webgl/math'

import Deferred from '@/utils/Deferred'
import ResourceGroup from './assets2/ResourceGroup'
import store from '@/store'
import gameStatic from './game/gameStatic'
import AssetsResources from './game/Resources'

/////////////
/////////////////////////////////
//////////



export default class Scene {
  
  dt: number
  time: number
  ratio: number
  ilayer      : Element
  loaddefer   : Deferred
  glview      : GLView
  gl          : GLContext
  // mainCamera  : Camera<PerspectiveLens>
  // devCamera   : Camera<PerspectiveLens>
  camera      : Camera
  sroot       : Node
  root        : Node
  glstate     : GLState
  quad        : Rect
  inputs      : Inputs
  post        : PostProcess
  matlib      : Materials
  reflect     : Reflect
  programs    : Programs
  camCtrl     : CameraControler
  maxcam      : MaxControler
  // musee       : MasterHead
  game        :Game

///////////////
///////
////////////
  
  
  enableDebugDraw: boolean
  envRotation    : number
  forceFps       : any
  useDebugCam    : boolean 
  
  debugCam    : () => void
  mainCam     : () => void
  mipmapUsage : () => void
  

  constructor() {

    this.dt     = 0   ;
    this.time   = 0   ;
    this.ratio  = 1.0 ;
    this.ilayer = null;

  }

  /**
   *
   * @param {import('glview').default} glview
   */
  init(glview : GLView) {
    
/////////////////
////////////////////////////
////////////////////////////////////////////////
//////////////
    

    this.loaddefer = new Deferred()

    this.glview = glview
    this.gl = this.glview.gl


    // CAMERA
    // ======
    // this.mainCamera = this.makeCamera()
    // this.devCamera  = this.makeCamera()
    // this.camera     = this.mainCamera


    this.sroot = new Node();
    this.root  = new Node();

    this.glstate     = new GLState(this.gl    );
    this.quad        = new Rect   (this.gl    );
    this.inputs      = new Inputs (this.ilayer);
    
    
    this.matlib      = new Materials  ( this );
    this.game       = new Game ( this );
    
    
/////////////////
/////////////////////////
////////////////////////////////
//////////////

    this.programs = new Programs(this);


    this.envRotation = Math.PI;

    // CONTROLERS
    // ======
    // this.camCtrl = new CamControler  (this   , this.devCamera )
    // this.maxcam  = new MaxControler  (this.glview.canvas)
    
    // GRAPH
    // ======
    // this.root.add( this.mainCamera )
    // this.root.add( this.devCamera )
    this.sroot.add( this.root )

    this.inputs.start();


/////////////////
////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
///////////////
////////////////////////////////////////
////////////////////////////////
///////////////////////////////////
///////////////////////////////
////////////////////////////////////
/////////////////////////////////////////

////////////////////////////////////////////////////
////////////////////////////////////////////////////
////
////
//////////////////////////////////////////////////
////


//////////////

  }




  handleResize(){
    this.game.handleResize()
  }


 
  render(dt) {

    
/////////////////
//////////////////////////////////
////////////
/////
/////////////////////////
//////////////

    if(store.getters['game/isPaused']) return

    if(store.getters['game/isGameStarted']) {
      this.dt = dt;
      this.time += dt;
    } else {
      this.dt = 0;
    }

    // this.useDebugCam ? this.devCamera : 
    this.camera = this.game.getCamera();
    this.drawScene( this.camera );

  }



  drawScene( camera : Camera, fbo = null, force = false ){


    const gl = this.gl;
    const w = fbo ? fbo.width  : this.glview.width;
    const h = fbo ? fbo.height : this.glview.height;

    this.ratio = w / h;

    // preRender
    // =============
    // this.camCtrl.preRender();

    const speed = gameStatic.speed

    if(!this.game.loaded) return
    
/////////////////
//////////////////////
//////////////

    this.game.updateNodes(this.dt, speed);
    this.sroot.updateWorldMatrix()


    // upadate graph
    // =================


    this.game.preRender(this.dt, speed);

    //Render shadow in it's own node
    this.game.renderPronghornShadow(w, h)


    camera.updateViewProjectionMatrix(w, h);
    
    
  
    // RENDER
    // ========
    
    // gl.clearColor(1, 1, 1, 1)
    // gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT)
    // gl.viewport(0, 0, w, h)
    // gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    this.post.post.preRender(w, h);
    this.post.post.bindColor()
    
    //render stuffs
    this.game.render( camera );
    
    this.glstate.apply()
    this.post.post.render()
    
    
/////////////////
////////////////////
//////////////
    
/////////////////
///////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////
/////////////////////////////////
///////////////////////////
////////
/////////////////////////
//////////////


/////////////////
////////////////////////////////////////////////////////////////////////////////////////
//////////////

  }



  async load() {
    ResourceGroup.default.add( this.game.getResources() )

    const nbSounds:number = AssetsResources.filter(asset => asset.type === 'sound').length
    let totalToLoad: number = this.game.getResources().getAllResources().length + nbSounds
    store.commit('game/setLoader', { toLoad: totalToLoad })
    this.game.getResources().getAllResources().forEach(r => {
      r.response().then(() => {
        totalToLoad--
        store.commit('game/setLoader', { toLoad: totalToLoad })
      })
    })

    await ResourceGroup.default.load()
    await this.game.loadSounds(() => {
      totalToLoad--
      store.commit('game/setLoader', { toLoad: totalToLoad })
    })


    this.onLoaded()

  }


  onLoaded = () => {
    this.game.onLoaded()
    this.post        = new PostProcess( this );
    // this.texlib.getGhostLoadables()
/////////////////
/////////////////////////////////////////
//////////////
  }

  release() {
    this.post.release()
    this.game.doUnload()
  }

  makeCamera() {
    // const camera = Camera.makePerspectiveCamera()
    const camera = new Camera( new Lens() );
    camera.lens.setAutoFov(35.0 * DEG2RAD) //80
    camera.lens.near = .1
    camera.lens.far = 100

    camera.setMatrix(<mat4> new Float32Array(
      // [0.9995434284210205, -1.396704476519517e-7, -0.03021577186882496, 0, -0.0026346975937485695, 0.9961907863616943, -0.08716089278459549, 0, 0.030100684612989426, 0.08720070123672485, 0.9957358837127686, 0, 0.45953, 6.27598, 25.63325, 1]
      [-0.9408759474754333, -1.3884552174658893e-7, -0.33875006437301636, 0, -0.13095493614673615, 0.9222548007965088, 0.3637261390686035, 0, 0.31241387128829956, 0.3865823447704315, -0.8677274584770203, 0, 7.604823112487793, 11.150139808654785, -20.09062957763672, 1]
    ))

    return camera
  }

}




