import { mat4, vec4 } from 'gl-matrix';
import vShader from './unlit.vert';
import fShader from './frag.glsl';
import MaterialPass from 'nanogl-pbr/MaterialPass';
import Flag from 'nanogl-pbr/Flag';
import Input from 'nanogl-pbr/Input';
import { AlphaModeEnum, AlphaModes } from 'nanogl-pbr/AlphaModeEnum';
import Enum from 'nanogl-pbr/Enum';
import ShaderVersion from 'nanogl-pbr/ShaderVersion';
import ShaderPrecision from 'nanogl-pbr/ShaderPrecision';
import TexCoord from 'nanogl-pbr/TexCoord';
import Fbo from 'nanogl/fbo';
const MAT_ID = 'ph_shadow_pass';


export default class PronghornShadow extends MaterialPass {
    version: ShaderVersion;
    precision: ShaderPrecision;
    shaderid: Flag;
    baseColorFactor: Input;
    alpha: Input;
    alphaFactor: Input;
    alphaCutoff: Input;
    alphaMode: AlphaModeEnum;
    doubleSided: Flag;
    scroll: number
    fbo:Fbo
    textureMatrix:mat4
    shadowOffsetX:number
    constructor(fbo:Fbo) {
        super({
            uid: MAT_ID,
            vert: vShader(),    
            frag: fShader(),
        });
        this.fbo = fbo
        const inputs = this.inputs;
        inputs.add(this.version = new ShaderVersion('100'));
        inputs.add(this.precision = new ShaderPrecision('highp'));
        inputs.add(this.shaderid = new Flag('id_' + MAT_ID, true));
        inputs.add(this.alpha = new Input('alpha', 1));
        inputs.add(this.alphaFactor = new Input('alphaFactor', 1));
        inputs.add(this.alphaCutoff = new Input('alphaCutoff', 1));
        inputs.add(this.alphaMode = new Enum('alphaMode', AlphaModes));
        inputs.add(this.doubleSided = new Flag('doubleSided', false));
        const tCoord:TexCoord = TexCoord.create('aTexCoord0' )
        const tInput = new Input('uBaseColor', 3)
        tInput.attachSampler( 'uTex', tCoord ).set( this.fbo.getColorTexture(0) )
        inputs.add(tInput)
        this.shadowOffsetX = 0

    }

    preRender(textureMatrix:mat4) {
        this.textureMatrix = textureMatrix
    }

    prepare(prg, node, camera) {
        prg.uShadowOffsetX(this.shadowOffsetX);
        prg.uM(node._wmatrix);
        prg.uVP(camera._viewProj);
        prg.uTex(this.fbo.getColorTexture(0))
        if(this.textureMatrix) prg.uTextureMatrix(this.textureMatrix)
        
    }
}
;
