/////////////
/////////////////////////////////
//////////

import Masks from "@/webgl/gl/masks";
import { Passes } from "@/webgl/glsl/Passes";
import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import GltfNode from "@webgl/lib/nanogl-gltf/lib/elements/Node";
import Scene from "@/webgl/scene";
import GLTFAnim, { mixAnimation } from "@webgl/lib/nanogl-gltf/lib/elements/Animation"
import Node from "nanogl-node";
import PixelFormats, { FormatDesc } from "nanogl-pf";
import GLState from "nanogl-state";
import GLConfig from "nanogl-state/config";
import Fbo from "nanogl/fbo";
import Camera from "nanogl-camera";
import BaseMaterial from "nanogl-pbr/BaseMaterial";
import PronghornShadow from '@webgl/glsl/pronghornshadow'
import { mat4, quat, vec3 } from "gl-matrix";
import store from '@/store';
import timeout from '@/utils/timeout';
import { GAME_GLOBAL_SPEED_SCALE } from '../gameConfig';


const sinein = require('eases/sine-in-out')

const lerp = require('lerp')

const SHADOW_FBO_SIZE = 512

const FORWARD = vec3.create()
vec3.set(FORWARD, 0, 0, 1)

const INVMAT = mat4.create()

export default class PronghornEntity {
    pronghornWalk: GLTFAnim
    pronghornTrot: GLTFAnim
    pronghornRun: GLTFAnim
    pronghornFence: GLTFAnim
    pronghornSuperRun: GLTFAnim
    gltf: Gltf
    root: GltfNode
    scene: Scene
    shadowFbo: Fbo
    shadowCamera: Camera
    shadowCameraNode: Node
    shadowQuad: Node
    shadowNode: GltfNode
    pronghornNode: GltfNode
    pronghornMatrix: mat4
    shadowConfig: GLConfig
    shadowMat: BaseMaterial
    shadowPass: PronghornShadow
    textureMatrix: mat4
    shadowMatrixLookAt: mat4
    camDir: vec3
    scaletex: vec3
    transtex: vec3
    tempDir: vec3
    lerpAnim: number
    animState: number
    inLerp: boolean
    timeP: number
    warpAnimTime: number
    warpAnimTimeArray: number[]
    inLerpState: boolean[]
    timeAnim: number[]
    timeStartAnim: number[]
    currAnim: GLTFAnim
    qteTime: number
    turnTime: number
    slowMoOver: boolean
    isFenceAnim: boolean
    avoidJunk: boolean
    avoidJunkSpeed: boolean
    fenceSpeed: boolean
    afterfenceSpeed: boolean
    beforeFenceSpeed: boolean
    startTurnZ: number
    shadowOffsetX: number
    addSpeed: number
    addSpeedFenceStart:number
    addSpeedFence:number
    constructor(gltf: Gltf, scene: Scene) {
        this.gltf = gltf
        this.scene = scene
        scene.game.root.add(gltf.root)
        this.root = this.gltf.getElementByName(GltfTypes.NODE, 'sceneElements')
        this.pronghornWalk = gltf.getElementByName<GltfTypes.ANIMATION>(GltfTypes.ANIMATION, 'walk23A')
        this.pronghornTrot = gltf.getElementByName<GltfTypes.ANIMATION>(GltfTypes.ANIMATION, 'Trot23')
        this.pronghornRun = gltf.getElementByName<GltfTypes.ANIMATION>(GltfTypes.ANIMATION, 'Run23')
        this.pronghornSuperRun = gltf.getElementByName<GltfTypes.ANIMATION>(GltfTypes.ANIMATION, 'FastRun23')
        this.pronghornFence = gltf.getElementByName<GltfTypes.ANIMATION>(GltfTypes.ANIMATION, 'Fence3')

        this.warpAnimTimeArray = [1, 0.25, 0.05, 0.025, 0.1]
        this.inLerpState = []
        this.timeAnim = [-this.pronghornWalk.duration * 0.5, 0, 0, 0, 0]
        this.timeStartAnim = [-this.pronghornWalk.duration * 0.5, -this.pronghornTrot.duration * 0.25, -this.pronghornRun.duration * 0.25, -this.pronghornSuperRun.duration * 0.25, 0]
        this.isFenceAnim = false
        this.slowMoOver = false
        this.avoidJunk = false

        this.shadowNode = this.gltf.getElementByName(GltfTypes.NODE, 'ShadowElements')
        this.pronghornNode = this.gltf.getElementByName(GltfTypes.NODE, 'pronghornInside')


        this.shadowCameraNode = this.gltf.getElementByName(GltfTypes.NODE, 'ShadowCamera')

        //Shadow
        this.shadowConfig = GLState.config()
            .depthMask(true)
            .enableDepthTest(false)
        const pf = PixelFormats.getInstance(this.scene.gl)
        const configs = [pf.RGBA16F, pf.RGBA32F]
        const cfg: FormatDesc = pf.getRenderableFormat(configs)
        this.shadowFbo = new Fbo(scene.gl)
        this.shadowFbo.bind()
        this.shadowFbo.attachColor(cfg.format, cfg.type, cfg.internal)
        this.shadowFbo.resize(SHADOW_FBO_SIZE, SHADOW_FBO_SIZE)
        const color: any = this.shadowFbo.getColor(0)
        color.setFilter(false)
        // color.clamp()
        this.shadowCamera = this.gltf.cameraInstances[0];
        (this.shadowCamera.lens as any)._valid = false
        this.shadowQuad = this.gltf.getElementByName(GltfTypes.NODE, 'ShadowGround')
        // this.shadowQuad.rotateY(-0.12)

        this.shadowMat = new BaseMaterial(scene.gl, 'bg-parallax');
        this.shadowPass = new PronghornShadow(this.shadowFbo)
        this.shadowMat.addPass(this.shadowPass)
        this.camDir = vec3.create()
        this.scaletex = vec3.create()
        this.transtex = vec3.create()
        // vec3.set(this.camDir, -0.18, -0.33, 0.12)
        vec3.set(this.scaletex, 16.8, -2.97, 1)
        vec3.set(this.transtex, 0.64, 0.2, -1.2)
        vec3.transformQuat(this.camDir, FORWARD, this.shadowCameraNode.rotation)
        vec3.normalize(this.camDir, this.camDir)
        vec3.scale(this.camDir, this.camDir, 1)
        vec3.set(this.camDir, 0.12, 2.65, 1.4)
        // vec3.add(this.camDir, this.shadowCameraNode.position, this.camDir)

        this.textureMatrix = mat4.create()
        this.shadowMatrixLookAt = mat4.create()

        this.tempDir = vec3.create()

        this.animState = 0
        this.lerpAnim = 0
        this.inLerp = false
        this.currAnim = this.pronghornWalk
        this.timeP = 0
        this.warpAnimTime = this.warpAnimTimeArray[0]
        this.addSpeed = 0
        this.addSpeedFenceStart = 1.4 * 2
/////////////////////
///////////////////
//////////////////
    }

    resetBeforeQte() {
        this.avoidJunkSpeed = this.avoidJunk = this.fenceSpeed = this.afterfenceSpeed = this.beforeFenceSpeed = false
    }

    startQte() {
        this.root.x = 1;
        this.root.updateMatrix()
        this.pronghornMatrix = mat4.create()
        mat4.identity(this.pronghornMatrix)
        mat4.copy(this.pronghornMatrix, this.root._matrix)

        this.addSpeed = 0.06
        this.qteTime = 0
        this.turnTime = 0
    }

    async leaveQte() {
        await timeout(1000)
        this.animState = 2
        this.currAnim = this.pronghornRun
        this.root.x = -0.025
        this.inLerp = false
        // this.shadowQuad.x = -0.025
    }

    async setavoidJunk() {
        this.avoidJunkSpeed = true
        await timeout(300)
        this.startTurnZ = this.root.z
        this.turnTime = 0
        this.avoidJunk = true
    }

    async placePronghorn() {
        await timeout(1000)

        this.root.setMatrix(this.pronghornMatrix)
    }


    preRender(dt: number, speed: number, visualSpeed: number = 0, baseSpeed: number = 1) {
        if (this.pronghornWalk) {
            const add = dt * 0.5 * this.warpAnimTime + 0.5 * dt * 0.8 * Math.max(0.0001, visualSpeed);
            this.timeP += add
            const timeAnim: number = this.timeP * baseSpeed + 0.2 * (speed / GAME_GLOBAL_SPEED_SCALE * 2)
            this.handleAnimation(speed / GAME_GLOBAL_SPEED_SCALE, timeAnim)
        }

        const qte = store.getters['game/qte']

        if (qte !== -1) {
            this.handleQTE(dt, qte)
        } else {
            this.root.x -= (dt * 0.4 + dt * speed) * this.scene.game.introOutro
        }

        // this.shadowCamera.lookAt(this.pronghornNode.position)
        // this.shadowCamera.invalidate()

        mat4.identity(this.shadowMatrixLookAt)
        mat4.lookAt(this.shadowMatrixLookAt, this.shadowCameraNode.position, this.pronghornNode.position, [0, 1, 0])

        let textureMatrix: mat4 = this.textureMatrix;
        mat4.identity(textureMatrix)
        mat4.translate(textureMatrix, textureMatrix, this.transtex)
        mat4.scale(textureMatrix, textureMatrix, this.scaletex)
        this.shadowPass.preRender(textureMatrix)
    }

    handleQTE(dt:number, qte:number) {
        let addSpeed: number = 0.06 * 2
        if (this.beforeFenceSpeed) {
            this.addSpeedFenceStart = lerp(this.addSpeedFenceStart, this.addSpeedFence, dt * 4)
            addSpeed = this.addSpeedFenceStart
        }
        if (this.slowMoOver) addSpeed = 1.4 * 2
        if (this.avoidJunkSpeed) addSpeed = 0.8 * 1
        if (this.fenceSpeed) addSpeed = 0.12
        if (this.afterfenceSpeed) addSpeed = 0.12 * 2.5
        
        this.addSpeed = lerp(this.addSpeed, addSpeed, dt * 2)
        this.qteTime += dt * this.addSpeed
        this.root.x = (qte === 1 ? 2.05 : 1.8) - this.qteTime

        if (this.slowMoOver && this.root.x < -1.5) {
            this.slowMoOver = false
            store.commit('game/setQTE', { qte: -1 })
        }

        if (this.avoidJunk) {
            this.turnTime += dt * (this.addSpeed)
            this.root.z = this.startTurnZ + sinein(Math.min(1, this.turnTime)) * 0.6
            vec3.sub(this.tempDir, this.root.position, this.tempDir)
            vec3.add(this.tempDir, this.tempDir, this.root.position)
            this.root.lookAt(this.tempDir)
            this.root.rotateY(-Math.PI * 0.5)

            if (this.root.x < -2) {
                this.avoidJunk = false
                store.commit('game/setQTE', { qte: -1 })
                this.placePronghorn()
            }
        }

        vec3.copy(this.tempDir, this.root.position)
    }

    handleAnimation(speed: number, time: number) {
        if (this.isFenceAnim) {
            if (this.animState !== 4 || this.inLerpState[4])
                this.doLerp(this.currAnim, this.pronghornFence, time, 4)
        }
        if (!this.isFenceAnim) {
            if (speed < 0.05 && this.animState !== 0 || this.inLerpState[0]) {
                this.doLerp(this.currAnim, this.pronghornWalk, time, 0)
            }
            if (speed >= 0.05 && speed < 0.15 && this.animState !== 1 || this.inLerpState[1]) {
                this.doLerp(this.currAnim, this.pronghornTrot, time, 1)
            }
            if (speed >= 0.15 && speed < 0.5 && this.animState !== 2 || this.inLerpState[2]) {
                this.doLerp(this.currAnim, this.pronghornRun, time, 2)
            }
            if (speed >= 0.5 && this.animState !== 3 || this.inLerpState[3]) {
                this.doLerp(this.currAnim, this.pronghornSuperRun, time, 3)
            }
        }
        
        if (!this.inLerp) {
            const t: number = this.timeAnim[this.animState]
            this.currAnim.evaluate(((time - t)) % this.currAnim.duration)
            if (this.animState === 4) {
                if(time - t > 4) this.afterfenceSpeed = true;
                if (this.currAnim.duration - ((time - t) % this.currAnim.duration) < 0.45) store.commit('game/setQTE', { qte: -1 })
            }
        }
    }

    doLerp(animA: GLTFAnim, animB: GLTFAnim, time: number, state: number) {
        if (!this.inLerp) {
            if(!this.isFenceAnim && !this.avoidJunkSpeed) {
                if ((time - this.timeAnim[this.animState]) % this.currAnim.duration > 0.03) return 
            }
            
            this.lerpAnim = time
            this.inLerp = true
            this.inLerpState[state] = true
            this.timeAnim[state] = time - this.timeStartAnim[state]
        }
        const l = Math.min(1, (time - this.lerpAnim) * 5)
        mixAnimation(animA, animB,
            l,
            time - this.timeAnim[this.animState],
            time - this.timeAnim[state],
            state !== 4 && this.animState !== 4
        )
        
        if(state === 1 && this.beforeFenceSpeed && !this.isFenceAnim) {
            this.warpAnimTime = lerp(this.warpAnimTime, 0.02, l)
        }
        else this.warpAnimTime = lerp(this.warpAnimTime, this.warpAnimTimeArray[state], l)
        if (l >= 1 && this.inLerp) {
            this.animState = state
            this.inLerp = false
            this.inLerpState[state] = false
            switch (this.animState) {
                case 0:
                    this.currAnim = this.pronghornWalk
                    break;
                case 1:
                    this.currAnim = this.pronghornTrot
                    break;
                case 2:
                    this.currAnim = this.pronghornRun
                    break;
                case 3:
                    this.currAnim = this.pronghornSuperRun
                    break;
                case 4:
                    this.currAnim = this.pronghornFence
                    break;
            }
        }
    }

    resetLerp() {
        for (let i = 0; i < this.inLerpState.length; i++) {
            this.inLerpState[i] = false
        }

        this.inLerp = false
    }

    renderShadow(w: number, h: number) {
        this.shadowFbo.bind();
        this.scene.gl.clearColor(.0, .0, .0, 0.0);
        this.scene.gl.viewport(0, 0, SHADOW_FBO_SIZE, SHADOW_FBO_SIZE);
        this.shadowFbo.clear();
        this.shadowCamera.updateViewProjectionMatrix(SHADOW_FBO_SIZE, SHADOW_FBO_SIZE)
        this.render(this.shadowConfig, this.shadowCamera, true)
    }

    render(cfg: GLConfig, camera: Camera, isShadow: boolean = false) {
        for (const renderable of this.gltf.renderables) {
            if ((renderable.node.name !== 'ShadowGround')) {
                renderable.render(this.scene, camera, Masks.OPAQUE, Passes.COLOR, cfg)
            } else if (!isShadow) {
                const prg = this.shadowMat.getAllPasses()[0].getProgram()
                prg.use();
                this.shadowPass.prepare(prg, this.shadowQuad, camera)
                for (let i = 0; i < renderable.node.mesh.primitives.length; i++) {
                    const primitive = renderable.node.mesh.primitives[i];
                    this.drawCall(this.scene.game.getCamera(), prg, primitive)
                }
            }
        }
    }

    drawCall(camera, prg, sub) {
        sub.bindVao(prg);
        sub.render();
        sub.unbindVao();
    }

/////////////////
////////////
//////////////////////////////////////////////
////////////////////////////////////////////
/////////////////////////////
//////////////////
///////////////////////////////
///////////////////////////////
///////////////////////////////
//////////////////////////////////
//////////////////////////////////
//////////////////////////////////
/////////////////////////////////
/////////////////////////////////
/////////////////////////////////
/////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////
////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////
//////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////
/////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////
/////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////
/////////////

/////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////
//////////
/////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////
//////////
////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////
//////////

/////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////
//////////
///////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////
//////////
///////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////
//////////

/////
//////////////

}