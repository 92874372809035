import Gltf2 from '../types/Gltf2';
import { vec3, quat } from "gl-matrix"; 

export function mixAnimation(animA, animB, lerpV, timeA, timeB, normalise = false) {
    // if(normalise) {
    //     const minAnim = Math.max(animA.duration, animB.duration)
    //     timeA = timeA / animA.duration * minAnim
    //     timeB = timeB / animB.duration * minAnim
    // }
    const ta = timeA % animA.duration
    const tb = timeB % animB.duration
    for (let i = 0; i < animA.channels.length; i++) {
        const channelA = animA.getChannel(i)
        const channelB = animB.getChannel(i)
        if(!channelA || !channelB || !channelA.valueHolder || !channelB.valueHolder) continue
        channelA.evaluator.evaluate(channelA.valueHolder, ta);
        channelB.evaluator.evaluate(channelB.valueHolder, tb);
        switch (channelA.path) {
            case Gltf2.AnimationChannelTargetPath.TRANSLATION:
                vec3.lerp(channelA.node.position, channelA.valueHolder, channelB.valueHolder, lerpV)
                channelA.node.invalidate()
            case Gltf2.AnimationChannelTargetPath.ROTATION:
                quat.slerp(channelA.node.rotation, channelA.valueHolder, channelB.valueHolder, lerpV)
                channelA.node.invalidate()
            case Gltf2.AnimationChannelTargetPath.SCALE:
                vec3.lerp(channelA.node.scale, channelA.valueHolder, channelB.valueHolder, lerpV)
                channelA.node.invalidate()
            // default: 
            //     throw new Error('unsupported path ' + path);
            // case Gltf2.AnimationChannelTargetPath.WEIGHTS:
            //     if(channelA.node.weights) lerpArray(channelA.node.weights, channelA.valueHolder as any, channelB.valueHolder as any, lerpV)
        }
        
    }
}

export default class Animation {
    constructor() {
        this.gltftype = "animations" /* ANIMATION */;
        this.duration = 0;
    }
    async parse(gltfLoader, data) {
        const samplerPromises = data.samplers.map((data) => gltfLoader._loadElement(data));
        this.samplers = await Promise.all(samplerPromises);
        for (const sampler of this.samplers) {
            this.duration = Math.max(sampler.maxTime);
        }
        const channelPromises = data.channels.map((data) => gltfLoader._loadElement(data));
        this.channels = await Promise.all(channelPromises);
    }
    evaluate(t) {
        for (var channel of this.channels) {
            channel.evaluate(t);
        }
    }
    getChannel(i) {
        return this.channels[i];
    }
    getSampler(i) {
        return this.samplers[i];
    }
}
