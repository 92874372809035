import Chunk from "nanogl-pbr/Chunk";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import glsl_pre_vert from "./pre-vert.glsl";
import { envs } from "@/webgl/game/gameConfig";

export default class ParallaxEffect extends Chunk {

  sunX:number

  constructor() {
    super(true, true);
    this.sunX = envs.SUN_POS
  }

  setup(prg) {
    this.sunX = envs.SUN_POS
    if(prg.uSunX)
      prg.uSunX(this.sunX);
  }

  _genCode(slots: ChunksSlots) {
    slots.add('pv', glsl_pre_vert());
    slots.add('pf', glsl_pre_vert());
  }

  _getHash(): string {
      return 'parallax-effect';
  }

}
