import PixelFormats from "nanogl-pf"
import BaseEffect from "nanogl-post/effects/base-effect"
import store from "@/store";
import { computed, watch } from 'vue'
import Fbo from "nanogl/fbo"
import { GLContext } from "nanogl/types"
import {  FormatDesc } from "nanogl-pf"
import Program from "nanogl/program"

import preCode from './godsray_pre.glsl'
import code from './godsray.glsl'
// import occ_frag from './occlusion.frag'
// import occ_vert from 'nanogl-post/glsl/templates/main.vert'
import { vec2 } from "gl-matrix"
import Texture from "nanogl/texture"
import anime from 'animejs/lib/anime.es.js';

const DOWNSCALE = 8

export default class GodsRay extends BaseEffect {
    _code:string
    _preCode:string

    fbo:Fbo
    ratio:number
    cfg:FormatDesc

    prgOcc:Program

    // godsray properties
    density:number
    weight:number
    decay:number
    exposition:number
    numSamples:number
    addMix:number
    failMix:number
    screenSpaceLightPos:vec2

    mix: number

    sunTex:Texture
    sunRatio:vec2
    sunOffset:vec2
    watchshowFail

    constructor(density: number, weight:number, decay: number, exposition:number, numSamples:number, screenSpaceLightPos:vec2, mix:number = 0.5) {
        super()
        
        this.fbo = null

        this.density = density
        this.weight = weight
        this.decay = decay
        this.exposition = exposition
        this.numSamples = numSamples
        this.screenSpaceLightPos = screenSpaceLightPos

        // this.mix = mix
        this.mix = 1

        this.addMix = 0.3
        this.failMix = 0

        this._code = code()
        this._preCode = preCode()

        const showFail = computed(() => store.getters['game/isFail'])

        this.watchshowFail = watch(showFail, (isFail) => {
            if(isFail) this.showFail()
        })
    }

    init() {
        const pf = PixelFormats.getInstance(this.post.gl)
        const configs = [pf.RGB16F, pf.RGBA16F, pf.RGB32F, pf.RGBA32F, pf.RGB8]
        const cfg:FormatDesc = pf.getRenderableFormat(configs)
        this.cfg = cfg
        this.fbo = this.genFbo(cfg)

        this.sunTex = new Texture(this.post.gl)
        this.sunTex.fromImage(window['resources'].get('addSun'))
        this.sunTex.bind()
        // this.sunTex.setFilter( false )
        this.sunTex.wrap(this.post.gl.CLAMP_TO_EDGE)

        this.sunOffset = vec2.create()
        vec2.set(this.sunOffset, 0.25, -0.07)
        this.sunRatio = vec2.create()
        
        
        // Build programs
        // let defs = '\n'
        // defs += 'precision highp float;\n'
        // defs += '#define DOWNSCALE 8.0 \n'
        // this.prgOcc = new Program(this.post.gl)
        // this.prgOcc.compile(
        // occ_vert({
        //     precode: '',
        //     code: '',
        // }),
        // occ_frag(),
        // defs
        // )
    }

    genFbo(cfg) {
      const gl:GLContext = this.post.gl
      const res:Fbo = new Fbo(gl)
      res.bind()
      res.attachColor(cfg.format, cfg.type, cfg.internal)
      const color:any = res.getColor(0)
      color.setFilter(true, false, false)
      color.clamp()
      return res
    }

    setupProgram(prg:Program) {
        // prg.uDensity(this.density)
        // prg.uWeight(this.weight)
        // prg.uDecay(this.decay)
        // prg.uExposition(this.exposition)
        // prg.uNumSamples(this.numSamples)
        // prg.uOcclusionTexture(this.fbo.getColor(0))
        prg.sunTex(this.sunTex)
        prg.uFailMix(this.failMix)
        prg.uAddMix(this.addMix)
        prg.uAddRatio(this.sunRatio)
        prg.uSunOffet(this.sunOffset)
        // prg.uScreenSpaceLightPos(this.screenSpaceLightPos)

        // prg.uMix(this.mix)
    }

    showFail() {
        store.commit('game/disableFail')
        this.failMix = 1
        anime({
            targets: this,
            failMix: 0,
            duration: 1000,
            delay: 500,
            easing: 'easeInCirc'
        })
    }

    genCode(precode, code) {
        precode.push(this._preCode)
        code.push(this._code)
    }

    preRender() {
        //generate occlusion texture
        // const post = this.post
        // const gl = post.gl
        // let fbo, prg

        //      Threshold pass
        // ===================
        // prg = this.prgOcc
        // fbo = this.fbo
        // fbo.bind()
        // fbo.clear()
        // prg.use()
        // prg.tInput(post.mainColor)
        // post.fillScreen(prg, true)
    }

    resize() {
        const bw = this.post.gl.canvas.width / DOWNSCALE
        const bh = this.post.gl.canvas.height / DOWNSCALE

        this.ratio = this.post.bufferWidth / this.post.bufferHeight
        vec2.set(this.sunRatio, window.innerWidth / window.innerHeight, 0.46)

        if (bw > 1 && bh > 1) {
        this.fbo.bind()
        this.fbo.attachColor(this.cfg.format, this.cfg.type, this.cfg.internal)
        this.fbo.resize(bw, bh)
        let color:any = this.fbo.getColor(0)
        color.setFilter(true, false, false)
        color.clamp()
        }
    }

    release() {
        this.watchshowFail()
        this.fbo.dispose()
        if(this.prgOcc) this.prgOcc.dispose()
        this.prgOcc = null
    }
}