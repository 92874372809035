

import GLView   from './glview'
import Scene    from './scene'
import Config   from './config'
import Quality  from './lib/quality'
import { computed, watch } from '@vue/runtime-core'
import store from '@/store'

// import {gui} from './dev'
// console.log( gui );


// TODO: move this somwhere else
import { TextureCodecs } from './assets2/TextureCodec'
import { TextureCodecBasis } from './assets2/TextureCodecBasis'
import ResourceGroup from './assets2/ResourceGroup'
import TextureCodecStd from './assets2/TextureCodecStd'

TextureCodecs.registerCodec( new TextureCodecBasis() );
TextureCodecs.registerCodec( new TextureCodecStd() );

import Gltf                                from './lib/nanogl-gltf/lib'
import KHR_texture_transform               from './lib/nanogl-gltf/lib/extensions/KHR_texture_transform'
import KHR_draco_mesh_compression          from './lib/nanogl-gltf/lib/extensions/KHR_draco_mesh_compression'
import KHR_materials_pbrSpecularGlossiness from './lib/nanogl-gltf/lib/extensions/KHR_materials_pbrSpecularGlossiness'
import KHR_lights_punctual                 from './lib/nanogl-gltf/lib/extensions/KHR_lights_punctual'
import KHR_materials_unlit                 from './lib/nanogl-gltf/lib/extensions/KHR_materials_unlit'
import EXT_texture_webp                    from './lib/nanogl-gltf/lib/extensions/EXT_texture_webp'



Gltf.addExtension( new KHR_texture_transform              () );
Gltf.addExtension( new KHR_draco_mesh_compression         () );
Gltf.addExtension( new KHR_materials_pbrSpecularGlossiness() );
Gltf.addExtension( new KHR_lights_punctual                () );
Gltf.addExtension( new KHR_materials_unlit                () );
Gltf.addExtension( new EXT_texture_webp                   () );

/**
  @opts :

    REQUIRED:
    'canvas'    {HTMLCanvasElement}   :  context free canvas element in which scene will be rendered
    'subtitles' {HTMLElement}         :  a dom element in which subtitles will be innerHTML-ed
    'config'    {string}              :  url to the xml config file 
    'assetsUrl' {string}              :  3D assets base url without trailing slash
    'model'     {Model}               :  Model instance shared with the page

    OPTIONAL:
    'ilayer'    {HTMLElement}         :  dom elemnt on which touch/mouse event are listened, default to canvas element 
    'quality'   {string}              :  enum 'auto', 'hi' or 'low'

**/
export default class App{

  glview: GLView
  scene: Scene
  frameCallback: Function
  store: any

  stopWatch

  constructor( { 
                  assetsUrl, 
                  canvas, 
                  ilayer=canvas, 
                  quality,
                  frameCallback = null
                } ){
                  

    Config.setBaseDir( assetsUrl );
    this.frameCallback = frameCallback


    this.glview   = new GLView( canvas, { frameCallback: this.rafCallback } );
    this.scene    = new Scene();

    this.scene.ilayer = ilayer;

    Quality.setMode( quality );

    this.glview.scene = this.scene;
    this.scene.init( this.glview );

    this.plugStore()

  }

  plugStore() {
    const isStarted = computed(() => store.getters['game/isStarted'])
    this.stopWatch = watch(isStarted, (isStarted:boolean) => {
        if(isStarted && !this.glview._playing) {
          this.glview.play()
        } else if(!isStarted && this.glview._playing) {
          this.glview.stop()
        }
    })
  }

  rafCallback = (dt) => {
    if(this.frameCallback) this.frameCallback(dt)
  }

  unload() {
    this.stopWatch()
  }



  /**
    start loading assets 
    @return a (when.js) promise 
  **/
  async load(){
    await this.scene.load();
  }



}



/////////////

//////////////////////////////////////////////


/////////////////
//////////////
//////////////////////////////////////////////
//////////////////////////////
/////////
 

//////////