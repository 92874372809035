import { mat3 } from "gl-matrix";
import DirectionalLight from "nanogl-pbr/lighting/DirectionalLight";
import PointLight from "nanogl-pbr/lighting/PointLight";
import SpotLight from "nanogl-pbr/lighting/SpotLight";
const M3 = mat3.create();
const EXT_ID = 'KHR_lights_punctual';
// ===========================================
// Data Spec
// ===========================================
var IKHR_LP_LightType;
(function (IKHR_LP_LightType) {
    IKHR_LP_LightType["Directional"] = "directional";
    IKHR_LP_LightType["Point"] = "point";
    IKHR_LP_LightType["Spot"] = "spot";
})(IKHR_LP_LightType || (IKHR_LP_LightType = {}));
class Instance {
    constructor(gltfLoader) {
        this.name = EXT_ID;
        this.priority = 1;
        this.loader = gltfLoader;
        this.lights = [];
        gltfLoader.gltf.extras.lights = this.lights;
    }
    _getLightData(index) {
        var _a;
        const lightDatas = (_a = this.loader._data.extensions) === null || _a === void 0 ? void 0 : _a[EXT_ID];
        if (lightDatas === undefined)
            throw new Error(EXT_ID + ' no extension data');
        return lightDatas.lights[index];
    }
    _createDirectional(lightData) {
        return new DirectionalLight();
    }
    _createPoint(lightData) {
        var _a;
        const l = new PointLight();
        l.radius = (_a = lightData.range) !== null && _a !== void 0 ? _a : -1;
        return l;
    }
    _createSpot(lightData) {
        var _a, _b, _c, _d, _e;
        const l = new SpotLight();
        l.radius = (_a = lightData.range) !== null && _a !== void 0 ? _a : -1;
        l.innerAngle = (_c = (_b = lightData.spot) === null || _b === void 0 ? void 0 : _b.innerConeAngle) !== null && _c !== void 0 ? _c : 0;
        l.outerAngle = (_e = (_d = lightData.spot) === null || _d === void 0 ? void 0 : _d.outerConeAngle) !== null && _e !== void 0 ? _e : 0;
        return l;
    }
    _createLightInstance(lightData) {
        switch (lightData.type) {
            case IKHR_LP_LightType.Directional: return this._createDirectional(lightData);
            case IKHR_LP_LightType.Point: return this._createPoint(lightData);
            case IKHR_LP_LightType.Spot: return this._createSpot(lightData);
        }
    }
    _createLight(node, iData) {
        var _a, _b;
        const lightData = this._getLightData(iData.light);
        let light = this._createLightInstance(lightData);
        const color = (_a = lightData.color) !== null && _a !== void 0 ? _a : [1, 1, 1];
        const intensity = (_b = lightData.intensity) !== null && _b !== void 0 ? _b : 1;
        color[0] *= intensity;
        color[1] *= intensity;
        color[2] *= intensity;
        light._color.set(color);
        node.add(light);
        this.lights.push(light);
        return light;
    }
    acceptElement(data, element) {
        if (element.gltftype === "nodes" /* NODE */ && data.extensions && data.extensions[EXT_ID]) {
            const node = element;
            this._createLight(node, data.extensions[EXT_ID]);
        }
        return null;
    }
    loadElement(data) {
        return null;
    }
}
export default class KHR_texture_transform {
    constructor() {
        this.name = EXT_ID;
    }
    createInstance(gltfLoader) {
        return new Instance(gltfLoader);
    }
}
