<template>
  <div
    ref="root"
    class="gesture"
    :class="{
      'gesture-right': isRight,
      'is-close': isClose,
      'in-transition': (qteTransition && qteComing !== -1) || isPaused,
      'in-intro': isInIntro || isInOutro || !gameStarted,
      'intro-appear': introAppear,
      activated
    }"
    @touchstart="touchStart"
    @touchmove="touchMove"
    @touchend="touchEnd"
  >
    <div ref="line" class="gesture-line">
      <div class="gesture-line-bg" />
      <div ref="lineFollow" class="gesture-line-follow">
        <line-speed />
      </div>
    </div>
    <div
      v-if="!ghost"
      ref="gestureGraphGhost"
      class="gesture-graph gesture-graph-ghost"
    >
      <div ref="gest" class="gesture-circle">
        <div />
      </div>
    </div>
    <div ref="gestureGraph" class="gesture-graph">
      <div ref="gest" class="gesture-circle">
        <div />
      </div>
    </div>
  </div>
</template>

<script>
import { checkIsMobile } from '@/hooks/global'
import { watchResize } from '@/hooks/global'
import store from '@/store'
import timeout from '@/utils/timeout'
import {
  computed,
  defineComponent,
  nextTick,
  onDeactivated,
  onMounted,
  ref,
  watch
} from 'vue'
import LineSpeed from '@/assets/svg/dash-line-speed.svg'
import gameStatic from '@/webgl/game/gameStatic'
import { useGtm } from 'vue-gtm'
const smoothstep = require('smoothstep')

const easeInOutExpo = require('eases/expo-in-out')

const MARGIN = 100
const CIRCLE_HALF = 47

export default defineComponent({
  components: {
    'line-speed': LineSpeed
  },
  props: {
    isRight: {
      type: Boolean,
      default: false
    },
    delay: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const gtm = useGtm()

    const circleY = ref(MARGIN - CIRCLE_HALF)
    const touchY = ref(0)
    const firstTouch = ref(false)
    const root = ref(null)
    const line = ref(null)
    const gestureGraphGhost = ref(null)
    const lineFollow = ref(null)
    const gestureGraph = ref(null)
    const lineHeight = ref(0)
    const ghostY = ref(0)
    const isClose = ref(false)
    const introAppear = ref(false)
    const activated = ref(true)
    const currentTime = ref(0)
    const addCount = ref(0)
    const doesVibrate = ref(false)
    const inTouch = ref(false)

    const watchInTouch = watch(inTouch, async t => {
      if (!t) {
        if (activated.value) {
          activated.value = false
          await timeout(160)
          activated.value = true
          circleY.value = touchY.value = MARGIN - CIRCLE_HALF
        }
      }
    })

    watch(activated, a => {
      if (a) {
        nextTick(() => resize())
      }
    })

    const qteTransition = computed(() => store.getters['game/qteTransition'])
    const qteComing = computed(() => store.getters['game/qteComing'])
    const isInIntro = computed(() => store.getters['game/isInIntro'])
    const isInOutro = computed(() => store.getters['game/isInOutro'])
    const isPaused = computed(() => store.getters['game/isPaused'])
    const ghost = computed(() => store.getters['game/showGhost'])
    const gameStarted = computed(() => store.getters['game/isGameStarted'])
    const forceLandscape = computed(
      () => store.getters['global/forceLandscape']
    )

    const unwatch = watchResize(async (w, h, isLandscape) => resize())

    watch(isInIntro, async b => {
      if (!b) {
        introAppear.value = true
        await timeout(800)
        introAppear.value = false
      }
    })

    //Mount/Unmount
    onMounted(async () => {
      firstTouch.value = false
      doesVibrate.value =
        window.navigator && window.navigator.vibrate && checkIsMobile()
      await timeout(2000)
      document.documentElement.style.setProperty('--line-margin', MARGIN + 'px')
      document.documentElement.style.setProperty(
        '--circle-half-size',
        CIRCLE_HALF + 'px'
      )
      resize()
      if (props.isRight) ghostY.value = -lineHeight.value * 0.5
    })

    //Methods
    const resize = () => {
      lineHeight.value = -1
      nextTick(() => {
        const rect = line.value.getBoundingClientRect()
        lineHeight.value =
          (forceLandscape.value ? rect.width : rect.height) - 60
        lineFollow.value.firstChild.style.maxHeight =
          lineHeight.value + 30 + 'px'
        if (props.isRight) ghostY.value = -lineHeight.value * 0.5
        else ghostY.value = 0
      })
    }
    const update = async (dt, speed, count) => {
      currentTime.value += dt

      const prevY = circleY.value
      if (
        activated.value &&
        Math.abs(circleY.value - (touchY.value - CIRCLE_HALF)) < 150
      ) {
        circleY.value = Math.max(
          MARGIN - CIRCLE_HALF,
          Math.min(
            MARGIN + lineHeight.value - CIRCLE_HALF,
            touchY.value - CIRCLE_HALF
          )
        )
      }

      const label = 'gesture' + (props.isRight ? 'Right' : 'Left')
      gameStatic[label] = circleY.value

      const diffV = props.isRight
        ? gameStatic['gestureLeft']
        : gameStatic['gestureRight']
      const diff = Math.abs(circleY.value - diffV)
      const ratio =
        smoothstep(0, lineHeight.value * 0.5, diff) *
        smoothstep(lineHeight.value, lineHeight.value * 0.5, diff)
      const dep = 1 / (lineHeight.value / 205)
      addCount.value =
        Math.min(
          3,
          Math.max(
            ((circleY.value - prevY) / (47 - Math.min(46, dt))) * dep,
            -0.1
          )
        ) *
          (1 - speed * 0.65) *
          (7 + (1 - speed)) *
          ratio -
        (0.1 + speed * 0.1)
      if (!store.getters['game/qteTransition']) count.v += addCount.value
      // store.commit('game/addToCount', { count: addCount.value })

      let mY = circleY.value - (MARGIN - CIRCLE_HALF)
      lineFollow.value.style.transform = `translateY(${Math.min(
        Math.round(mY),
        Math.round(lineHeight.value)
      )}px)`

      mY = circleY.value - (MARGIN - CIRCLE_HALF)
      lineFollow.value.firstChild.style.transform = `translate(-1px, -${Math.round(
        mY
      )}px)`

      gestureGraph.value.style.transform = `translateY(${Math.round(
        circleY.value
      )}px)`

      if (!ghost.value) {
        ghostY.value = ghostY.value + dt * 0.12
        const r = ghostY.value % lineHeight.value
        const val =
          MARGIN -
          CIRCLE_HALF +
          easeInOutExpo(r / lineHeight.value) * lineHeight.value

        gestureGraphGhost.value.style.transform = `translateY(${Math.round(
          val
        )}px)`
      }
    }

    const touchStart = event => {
      if (!firstTouch.value) {
        firstTouch.value = true
        gtm.trackEvent({
          event: 'eventPush',
          eventAction: 'userinput',
          eventLabel: '',
          eventValue: '',
          eventCategory: 'game'
        })
      }
      if (!isInIntro.value) store.commit('game/setShowGhost')
      inTouch.value = true
      touchMove(event)
    }
    const touchMove = event => {
      const t = Array.from(event.touches).filter(
        t => t.target === root.value
      )[0]
      if (!t) return
      touchY.value = forceLandscape.value
        ? window.innerWidth - t.pageX
        : t.pageY
    }

    const touchEnd = () => {
      inTouch.value = false
    }

    onDeactivated(() => {
      unwatch()
    })

    return {
      forceLandscape,
      lineHeight,
      gameStarted,
      firstTouch,
      root,
      ghostY,
      ghost,
      line,
      lineFollow,
      gestureGraphGhost,
      gestureGraph,
      inTouch,
      isClose,
      circleY,
      currentTime,
      isPaused,
      addCount,
      touchY,
      doesVibrate,
      activated,
      isInIntro,
      isInOutro,
      introAppear,
      //Computed
      qteComing,
      qteTransition,
      //Methods
      resize,
      update,
      // reset,
      touchStart,
      touchMove,
      touchEnd
    }
  }
})
</script>

<style lang="scss">
.gesture {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  pointer-events: none;

  &.activated {
    pointer-events: all;
    .gesture-graph {
      opacity: 1;
      transition: opacity 0.05s $ease-out-sine;
      & > div {
        transform: scale(1);
        transition: transform 0.15s $ease-in-sine;
        & > div {
          transform: scale(1);
          transition: transform 0.15s $ease-in-sine 0.2s;
        }
      }
    }

    &.intro-appear {
      .gesture-graph {
        transition: none;
        & > div {
          transition: transform 0.7s $ease-in-out-sine 0.1s;
          & > div {
            transition: transform 0.7s $ease-in-out-sine 0.1s;
          }
        }
      }
      .gesture-line {
        transition: transform 0.7s $ease-in-out-sine;
      }
    }
  }

  &.in-transition,
  &.in-intro {
    .gesture-graph .gesture-circle {
      transform: scale(1) translate(-200px);
      transition: transform 0.15s $ease-in-sine;
    }
    .gesture-line {
      transform: scale(1) translate(-200px);
      transition: transform 0.15s $ease-in-sine 0.015s;
    }
  }

  &.in-intro {
    pointer-events: none;
    .gesture-graph .gesture-circle {
      transition: none;
    }
    .gesture-line {
      transition: none;
    }
  }

  & * {
    pointer-events: none;
  }

  &.is-disable {
    opacity: 0.5;
  }

  &-right {
    left: auto;
    right: 0;
    .gesture-line {
      left: auto;
      right: calc(25% - var(--circle-half-size) / 2 - 7.5px);
    }
    .gesture-graph {
      div {
        left: auto;
        right: calc(25% - 17px - var(--circle-half-size));
      }
    }

    &.in-transition,
    &.in-intro {
      .gesture-graph .gesture-circle {
        transform: scale(1) translate(200px);
      }
      .gesture-line {
        transform: scale(1) translate(200px);
      }
    }

    &.in-intro {
      pointer-events: none;
      .gesture-graph .gesture-circle {
        transition: none;
      }
      .gesture-line {
        transition: none;
      }
    }
  }

  &-line {
    position: absolute;
    top: calc(var(--line-margin) - 7.5px);
    left: calc(25% - var(--circle-half-size) / 2 - 8px);
    font-size: 0;
    line-height: 0;
    height: calc(100% + 15px - var(--line-margin) * 2);
    max-height: 250px;
    min-height: 140px;
    border: 15px solid rgba(255, 236, 210, 0.5);
    // box-shadow: 0px 0px 0px 30px rgba(255, 236, 210, 0.5);
    width: 1px;
    border-radius: 40px;
    transition: transform 0.25s $ease-out-sine;
    div {
      position: absolute;
      width: 3px;
      height: 100%;
      top: 0;
      left: 0;
      overflow: hidden;
    }
    &-follow {
      svg {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &-graph {
    opacity: 0;
    transition: opacity 0.015s $ease-out-sine 0.2s;
    &.gesture-graph-ghost {
      & > div {
        opacity: 0.2;
        border-color: #7e1f1f;
        & > div {
          background: #7e1f1f;
        }
      }
    }
    & > div {
      position: absolute;
      top: 20px;
      left: calc(25% - 17px - var(--circle-half-size));
      border-radius: 50%;
      width: calc(var(--circle-half-size) * 2);
      height: calc(var(--circle-half-size) * 2);
      border: 2px solid white;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: scale(0.5);
      transition: transform 0.5s $ease-out-sine;
      & > div {
        background: color(light-red);
        border-radius: 50%;
        width: 54px;
        height: 54px;
        transform: scale(0.5);
        transition: transform 0.05s $ease-out-sine;
      }
      // background: color(red);
    }

    & > div.gesture-mock {
      opacity: 0.2;
      border: 5px solid white;
    }
  }
}
</style>
