export default class BufferView {
    constructor() {
        this.gltftype = "bufferViews" /* BUFFERVIEW */;
        this.byteOffset = 0;
        this.byteLength = 0;
        this.byteStride = 0;
        this.target = 0;
        this.glBuffer = null;
        this.glBufferTarget = 0;
    }
    async parse(gltfLoader, data) {
        const { byteLength, byteOffset = 0, byteStride = 0, target = 0 } = data;
        this.byteLength = byteLength;
        this.byteOffset = byteOffset;
        this.byteStride = byteStride;
        this.target = target;
        this.buffer = await gltfLoader.getElement("buffers" /* BUFFER */, data.buffer);
    }
    getByteOffset() {
        return this.byteOffset + this.buffer._byteOffset;
    }
    getWebGLBuffer(gl, inferedTarget) {
        if (this.target !== 0 && this.target !== inferedTarget) {
            throw new Error(`BufferView's target ${this.target} doesn't match infered one ${inferedTarget}`);
        }
        if (this.glBuffer !== null) {
            if (this.glBufferTarget !== inferedTarget) {
                // Is this really an error?
                throw new Error(`WebglBuffers with different target requested on BufferView`);
            }
        }
        else {
            const data = new Uint8Array(this.buffer._bytes, this.getByteOffset(), this.byteLength);
            this.glBufferTarget = inferedTarget;
            this.glBuffer = gl.createBuffer();
            gl.bindBuffer(inferedTarget, this.glBuffer);
            gl.bufferData(inferedTarget, data, gl.STATIC_DRAW);
            gl.bindBuffer(inferedTarget, null);
        }
        return this.glBuffer;
    }
}
