import Gltf from "..";
import { ExtensionList } from "../extensions/Registry";
import "../extensions/DefaultExtension";
import Assert from "../lib/assert";
import { AbortSignal } from "@azure/abort-controller";
let UID = 0;
function getUUID() {
    return (UID++) + "";
}
const defaultMaterialData = {
    gltftype: "materials" /* MATERIAL */,
    elementIndex: -1,
    elementParent: null,
    uuid: '_default_mat_',
    name: 'default',
};
class PendingElement {
    constructor(data, element) {
        this.data = data;
        this.promise = element;
    }
}
const MAGIC = 0x46546C67; // "glTF"
const JSON_MAGIC = 0x4E4F534A; // "JSON"
const GLB_HEADER_SIZE = 20;
export default class GltfLoader {
    constructor(gltfIO, url, options = {}) {
        var _a;
        this._elements = new Map();
        this._pendingElements = [];
        this._byType = new Map();
        this._propertyMaps = new Map();
        this.unpack = (buffer) => {
            const magic = new Uint32Array(buffer, 0, 1)[0];
            if (magic === MAGIC) {
                this.unpackGlb(buffer);
            }
            else {
                const jsonStr = this.gltfIO.decodeUTF8(buffer);
                this._data = JSON.parse(jsonStr);
                this.prepareGltfDatas(this._data);
            }
            return Promise.resolve(true);
        };
        // loadBuffer = (b: Buffer) => {
        this.loadBufferUri = (uri) => {
            if (uri === undefined)
                return Promise.resolve(this._glbData);
            const resolvedUri = this.gltfIO.resolvePath(uri, this._baseUrl);
            return this.gltfIO.loadBinaryResource(resolvedUri, this.abortSignal);
        };
        this.parseAll = async () => {
            this._extensions.validate(this._data.extensionsUsed, this._data.extensionsRequired);
            const asset = await this._loadElement(this._data.asset);
            if (asset.version != '2.0') {
                console.warn(`Gltf version should be "2.0" found "${asset.version}"`);
            }
            await this._loadElements(this._data.nodes);
            await this._loadElements(this._data.animations);
            await this.resolveElements();
        };
        this.yieldGltf = () => {
            return Promise.resolve(this.gltf);
        };
        this.gltfIO = gltfIO;
        this._url = url;
        this._baseUrl = options.baseurl;
        this.abortSignal = (_a = options.abortSignal) !== null && _a !== void 0 ? _a : AbortSignal.none;
        if (this._baseUrl === undefined)
            [this._baseUrl, this._url] = gltfIO.resolveBaseDir(this._url);
        this.gltf = new Gltf();
        this._data = null;
        this._extensions = new ExtensionList();
        Gltf.getExtensionsRegistry().setupExtensions(this, options.extensions);
    }
    load() {
        return this.gltfIO.loadBinaryResource(this.gltfIO.resolvePath(this._url, this._baseUrl), this.abortSignal)
            .then(this.unpack)
            .then(this.parseAll)
            .then(this.yieldGltf);
    }
    unpackGlb(buffer) {
        const [, version, , jsonSize, magic] = new Uint32Array(buffer, 0, 5);
        // Check that the version is 2
        if (version !== 2)
            throw new Error('Binary glTF version is not 2');
        // Check that the scene format is 0, indicating that it is JSON
        if (magic !== JSON_MAGIC)
            throw new Error('Binary glTF scene format is not JSON');
        const scene = this.gltfIO.decodeUTF8(buffer, GLB_HEADER_SIZE, jsonSize);
        this._glbData = buffer.slice(GLB_HEADER_SIZE + jsonSize + 8);
        this._data = JSON.parse(scene);
        this.prepareGltfDatas(this._data);
    }
    resolveUri(uri) {
        return this.gltfIO.resolvePath(uri, this._baseUrl);
    }
    parseCommonGltfProperty(data, element) {
        if (element.name === undefined) {
            element.name = data.name;
        }
        if (element.extras === undefined) {
            element.extras = data.extras;
        }
    }
    async _createElement(data) {
        let element = await this._createElementInstance(data);
        this.parseCommonGltfProperty(data, element);
        return this._extensionsAccept(data, element);
    }
    // create element
    _createElementInstance(data) {
        const extensions = this._extensions._list;
        for (const ext of extensions) {
            const res = ext.loadElement(data);
            if (res === undefined)
                throw new Error("extensiosn should not return undefined");
            if (res !== null)
                return res;
        }
        throw new Error("Unhandled type");
    }
    // create element
    async _extensionsAccept(data, element) {
        const extensions = this._extensions._list;
        let res;
        for (const ext of extensions) {
            res = ext.acceptElement(data, element);
            if (res !== null) {
                element = await res;
            }
        }
        return element;
    }
    // create elementif not already created
    _loadElement(data) {
        let res = this._elements.get(data.uuid);
        if (res === undefined) {
            res = this._createElement(data);
            const pe = new PendingElement(data, res);
            this._pendingElements.push(pe);
            this._elements.set(data.uuid, res);
        }
        return res;
    }
    loadDefaultMaterial() {
        return this._loadElement(defaultMaterialData);
    }
    _getElementHolder(type) {
        let array = this._byType.get(type);
        if (array === undefined) {
            array = [];
            this._byType.set(type, array);
        }
        return array;
    }
    getElement(type, index) {
        const holder = this._getElementHolder(type);
        if (holder[index] !== undefined)
            return holder[index];
        // get existing or create if not exist!
        const properties = this._propertyMaps.get(type);
        const property = properties[index];
        return this._loadElement(property);
    }
    _loadElements(dataList) {
        if (dataList !== undefined) {
            const promises = dataList.map((data) => this._loadElement(data));
            return Promise.all(promises);
        }
    }
    /**
     * wait for all pending elements creation to complete
     * and register them in Gltf object
     */
    async resolveElements() {
        while (this._pendingElements.length > 0) {
            const pelements = this._pendingElements.splice(0, this._pendingElements.length);
            const elements = await Promise.all(pelements.map((pe) => pe.promise));
            for (let i = 0; i < pelements.length; i++) {
                const element = elements[i];
                Assert.isDefined(element.gltftype);
                this.gltf.addElement(elements[i], pelements[i].data.elementIndex);
            }
        }
    }
    prepareGltfDatas(gltfData) {
        this.prepareGltfRootProperties(gltfData.accessors, "accessors" /* ACCESSOR */, null);
        this.prepareGltfRootProperties(gltfData.animations, "animations" /* ANIMATION */, null);
        this.prepareGltfRootProperties([gltfData.asset], "asset" /* ASSET */, null);
        this.prepareGltfRootProperties(gltfData.buffers, "buffers" /* BUFFER */, null);
        this.prepareGltfRootProperties(gltfData.bufferViews, "bufferViews" /* BUFFERVIEW */, null);
        this.prepareGltfRootProperties(gltfData.cameras, "cameras" /* CAMERA */, null);
        this.prepareGltfRootProperties(gltfData.images, "images" /* IMAGE */, null);
        this.prepareGltfRootProperties(gltfData.materials, "materials" /* MATERIAL */, null);
        this.prepareGltfRootProperties(gltfData.meshes, "meshes" /* MESH */, null);
        this.prepareGltfRootProperties(gltfData.nodes, "nodes" /* NODE */, null);
        this.prepareGltfRootProperties(gltfData.samplers, "samplers" /* SAMPLER */, null);
        this.prepareGltfRootProperties(gltfData.scenes, "scenes" /* SCENE */, null);
        this.prepareGltfRootProperties(gltfData.skins, "skins" /* SKIN */, null);
        this.prepareGltfRootProperties(gltfData.textures, "textures" /* TEXTURE */, null);
        if (gltfData.animations !== undefined) {
            for (const animation of gltfData.animations) {
                this.prepareGltfProperties(animation.samplers, "animationSamplers" /* ANIMATION_SAMPLER */, animation);
                this.prepareGltfProperties(animation.channels, "animationChannels" /* ANIMATION_CHANNEL */, animation);
            }
        }
        if (gltfData.materials !== undefined) {
            for (const material of gltfData.materials) {
                this.prepareGltfProperty(material.normalTexture, "normalTextureInfo" /* NORMAL_TEXTURE_INFO */, -1, material);
                this.prepareGltfProperty(material.occlusionTexture, "occlusionTextureInfo" /* OCCLUSION_TEXTURE_INFO */, -1, material);
                this.prepareGltfProperty(material.emissiveTexture, "textureInfo" /* TEXTURE_INFO */, -1, material);
                if (material.pbrMetallicRoughness !== undefined) {
                    this.prepareGltfProperty(material.pbrMetallicRoughness.baseColorTexture, "textureInfo" /* TEXTURE_INFO */, -1, material);
                    this.prepareGltfProperty(material.pbrMetallicRoughness.metallicRoughnessTexture, "textureInfo" /* TEXTURE_INFO */, -1, material);
                }
            }
        }
        if (gltfData.meshes !== undefined) {
            for (const mesh of gltfData.meshes) {
                this.prepareGltfProperties(mesh.primitives, "primitives" /* PRIMITIVE */, mesh);
            }
        }
        if (gltfData.accessors !== undefined) {
            for (const accessor of gltfData.accessors) {
                this.prepareGltfProperty(accessor.sparse, "sparse" /* ACCESSOR_SPARSE */, -1, accessor);
                if (accessor.sparse !== undefined) {
                    this.prepareGltfProperty(accessor.sparse.indices, "sparseIndices" /* ACCESSOR_SPARSE_INDICES */, -1, accessor.sparse);
                    this.prepareGltfProperty(accessor.sparse.values, "sparseValues" /* ACCESSOR_SPARSE_VALUES */, -1, accessor.sparse);
                }
            }
        }
    }
    prepareGltfProperties(elementsData, type, parent) {
        if (elementsData === undefined)
            return;
        for (let i = 0; i < elementsData.length; i++) {
            const element = elementsData[i];
            this.prepareGltfProperty(element, type, i, parent);
        }
    }
    prepareGltfRootProperties(elementsData, type, parent) {
        if (elementsData === undefined)
            return;
        this._propertyMaps.set(type, elementsData);
        for (let i = 0; i < elementsData.length; i++) {
            const element = elementsData[i];
            this.prepareGltfProperty(element, type, i, parent);
        }
    }
    prepareGltfProperty(element, type, index, parent) {
        if (element === undefined)
            return;
        element.gltftype = type;
        element.uuid = getUUID();
        element.elementIndex = index;
        element.elementParent = parent;
    }
}
