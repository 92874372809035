<template>
  <div class="game-header">
    <div
      class="game-header-time"
      :class="{ active: timerActive && !isInIntro }"
    >
      {{ gameTime }}
    </div>
    <div class="game-header-playpause">
      <div v-if="!timerActive" class="game-header-temp-score">
        {{ getTempScore }}
      </div>
      <button v-else class="t-btn playpause" @click="togglePlay">
        <div v-if="isPaused" class="play" />
        <div v-else class="pause">
          <div />
          <div />
        </div>
      </button>
    </div>
    <div class="game-header-score">{{ score }}</div>
    <lottie-animation
      ref="scoreAnim"
      url="/assets/bodymovin/score.json"
      :loop="false"
      :autoplay="false"
    />
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/runtime-core'
import store from '@/store'
import LottieAnimation from '@/components/LottieAnimation.vue'
import gameStatic from '@/webgl/game/gameStatic'
import { useGtm } from 'vue-gtm'
export default {
  components: {
    LottieAnimation
  },
  setup() {
    const gtm = useGtm()
    const time = ref(0)
    const scoreAnim = ref(null)
    const gameTime = computed(() => {
      const t = time.value
      let sec = Math.floor(t / 1000)
      let csec = Math.floor((t - sec * 1000) / 10)
      csec = csec.toString()
      while (csec.length < 2) csec = '0' + csec

      sec = sec.toString()
      while (sec.length < 2) sec = '0' + sec

      return `${sec}’${csec}`
    })
    const score = ref(0)
    const tempScore = computed(() => store.getters['game/tempScore'])
    const isInIntro = computed(() => store.getters['game/isInIntro'])
    const isScoreQte = computed(() => store.getters['game/addScoreQte'])
    const isPaused = computed(() => store.getters['game/isPaused'])
    const scoreToAdd = ref(0)
    const getTempScore = computed(() => {
      if (tempScore.value === 0) return ''
      return '+ ' + tempScore.value
    })

    const togglePlay = () => {
      gtm.trackEvent({
        event: 'eventPush',
        eventAction: store.getters['game/isPaused'] ? 'play' : 'pause',
        eventLabel: '',
        eventValue: '',
        eventCategory: 'game'
      })
      store.commit('game/togglePause')
    }

    const watchisScoreQte = watch(isScoreQte, b => {
      if (b) {
        scoreToAdd.value = store.getters['game/tempScore']
        if (store.getters['game/tempScore'] > 0)
          scoreAnim.value.lottieAnim.goToAndPlay(0, true)
      }
    })

    const timerActive = computed(() => store.getters['game/qte'] === -1)

    const update = dt => {
      time.value = gameStatic.time
      if (scoreToAdd.value > 0) {
        const toAdd = Math.round(10 * gameStatic.scoreScale)
        scoreToAdd.value = Math.max(0, scoreToAdd.value - toAdd)
        gameStatic.score += toAdd
        store.commit('game/setTempScore', { score: scoreToAdd.value })
      }
      score.value = gameStatic.score
    }

    return {
      time,
      score,
      tempScore,
      isPaused,
      togglePlay,
      getTempScore,
      gameTime,
      timerActive,
      isInIntro,
      isScoreQte,
      scoreToAdd,
      update,
      scoreAnim
    }
  }
}
</script>

<style lang="scss" scoped>
.game-header {
  position: absolute;
  top: 15px;
  left: 15px;
  width: calc(100vw - 30px);
  border: 1px solid color(border);
  border-radius: 15px;
  display: flex;
  text-align: center;
  height: 40px;
  z-index: 0;

  .force-landscape & {
    width: calc(var(--vh, 1vh) * 100 - 30px);
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-temp-score {
    color: color(red);
    font-size: 23px;
    text-shadow: 0px 2px 0px #ffffff;
  }

  &-playpause {
    width: calc(100% - 21% * 2);
  }

  &-time {
    width: 21%;
    border-right: 1px solid color(border);
    font-family: font(heading2);
    color: rgba(157, 29, 41, 0.2);
    font-size: 25px;
    letter-spacing: 2px;
    &.active {
      color: rgba(157, 29, 41, 1);
    }
  }

  &-score {
    width: 21%;
    border-left: 1px solid color(border);
    color: color(red);
    text-shadow: 0px 2px 0px #ffffff;
    font-size: 23px;
  }

  .lottie-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  .playpause {
    .pause {
      display: flex;
      flex-direction: row;
      & > div {
        background: color(red);
        width: 7px;
        height: 22px;
        border-radius: 2px;
        &:first-child {
          margin-right: 4px;
        }
      }
    }

    .play {
      display: inline-block;
      height: 0;
      width: 0;
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
      border-left: 24px solid color(red);
      border-radius: 2px;
    }
  }
}
</style>
