
import LetterPass from '@/webgl/glsl/letter'
import Gltf from '@/webgl/lib/nanogl-gltf/lib'
import IRenderable from '@/webgl/lib/nanogl-gltf/lib/renderer/IRenderable'
import MeshRenderer from '@/webgl/lib/nanogl-gltf/lib/renderer/MeshRenderer'
import GltfTypes from '@/webgl/lib/nanogl-gltf/lib/types/GltfTypes'
import Scene from '@/webgl/scene'
import BaseMaterial from 'nanogl-pbr/BaseMaterial'
import GltfNode from "@webgl/lib/nanogl-gltf/lib/elements/Node";
import Camera from 'nanogl-camera'
import Masks from '@/webgl/gl/masks'
import { Passes } from '@/webgl/glsl/Passes'
import GLConfig from 'nanogl-state/config'
import GLState from "nanogl-state";
import { GLContext } from 'nanogl/types'
import Texture from 'nanogl/texture'
import anime from 'animejs/lib/anime.es.js';

export default class WordsController {
    scene:Scene
    gltf:Gltf
    ltPass:LetterPass
    currentWord:GltfNode
    isShowWord:boolean

    baseConfig:GLConfig
    isAnimating:boolean
    sequenceId: number

    trans
    constructor() {
        console.log('create wordscontroller')
    }

    init(gltf: Gltf, scene:Scene) {
        const gl:GLContext = scene.gl
        this.baseConfig = GLState.config()
            .enableBlend()
            .blendEquation(gl.FUNC_REVERSE_SUBTRACT)
            .blendFuncSeparate(gl.ONE_MINUS_SRC_COLOR, gl.DST_COLOR, gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)
            .depthMask( false )
            .enableDepthTest(false)

        this.scene = scene
        this.gltf = gltf
        const atlasTex:Texture = gltf.getElementByName(GltfTypes.TEXTURE, 'letter-atlas').glTexture;
        atlasTex.bind()
        atlasTex.setFilter(false)


        this.sequenceId = Math.floor(Math.random() * 3)
        this.ltPass = new LetterPass()

        this.isAnimating = false
        
        this.gltf.renderables.forEach((r:IRenderable) => {
            (r as MeshRenderer).materials[0].inputs.add(this.ltPass)
        })
    }

    showWord(isLastWord:boolean = false) {
        this.isAnimating = true
        this.currentWord = this.gltf.root._children[isLastWord ? this.gltf.root._children.length - 1 : this.sequenceId] as GltfNode
        this.sequenceId++
        if(this.sequenceId > this.gltf.root._children.length - 2) this.sequenceId = 0

        this.isShowWord = true
        this.currentWord._children.forEach(l => l.setScale(0))
        this.scene.game.root.add(this.gltf.root)
        this.trans = this.currentWord._children.map(c => ({ s: 0 }))
        anime({
            targets: this.trans,
            delay: anime.stagger(75),
            s: 1,
            easing: 'spring(0.75, 80, 10, 5)',
            onComplete: () => this.isAnimating = false
        })
        // this.scene.root.add()
    }

    hideWord() {
        this.isAnimating = this.isShowWord = false
        this.scene.game.root.remove(this.gltf.root)
        // this.scene.root.add()
    }

    updateNodes(dt: number, speed: number) {
        if(this.isAnimating) this.currentWord._children.forEach((l, i) => {
            l.setScale(this.trans[i].s)
        })
    }

    render = () => {
        if(this.isShowWord) {
            this.renderMainScene( this.scene.game.getCamera(), Masks.BLENDED , Passes.COLOR, this.gltf.renderables, this.baseConfig);
        }
    }

    renderMainScene(camera: Camera, mask: Masks, passId: Passes = Passes.DEFAULT, renderables:IRenderable[], cfg?: GLConfig) {
        for (const renderable of renderables) {
            if((renderable.node._parent._parent as GltfNode).name === this.currentWord.name)
                {
                    renderable.render(this.scene, camera, mask, passId, cfg)
                }
        }
    }
}