/////////////
//////////////////////////////////
//////////

import DynamicElementEntity from "../entities/DynamicElementEntity";
import Gltf from "../../lib/nanogl-gltf/lib";
import Node from "nanogl-node";
import Rect from "nanogl-primitives-2d/rect";
import Scene from "@/webgl/scene";
import Texture from "nanogl/texture";
import GltfTypes from '@/webgl/lib/nanogl-gltf/lib/types/GltfTypes';
import { vec3 } from 'gl-matrix';
import GltfNode from "@webgl/lib/nanogl-gltf/lib/elements/Node";
import IRenderable from '@/webgl/lib/nanogl-gltf/lib/renderer/IRenderable';
import store from '@/store';
import { BACK_FENCE_SLOWMO, BACK_SLOWMO } from '../gameConfig';

const NODELIST = [
    ['Grass', 'Grass2', 'Grass3'],
    ['TREE1', 'TREE2', 'TREE3', 'TREE4'],
    ['Group-Bisons'],
    ['PRAIRIE DOG', 'FOX', 'RABBIT', 'BIRD'],
    ['ROCK1', 'ROCK2', 'ROCK3', 'DEAD WOOD 2'],
    ['DEAD WOOD'],
    ['Group-Trees'],
    ['BIRDS (FLYING)'],
    ['DEAD WOOD 3'],
]

const TYPES = [
    'MiddleRandom',
    'FarRandom',
    'closeRandom',
    'FurtherRandom',
]

const NODE_TYPE = [
    'close',
    1,
    1,
    2,
    2,
    1,
    3,
    'fly',
    2
]

const NB_ELEMENTS = [
    1,
    3,
    1,
    4,
    1,
    1,
    1,
    1,
    1
]

const LAUNCH_WITH = [
    -1,
    -1,
    -1,
    -1,
    3,
    -1,
    -1,
    -1,
    -1
]

const LAUNCH_TO = [
    -1,
    -1,
    -1,
    4,
    -1,
    -1,
    -1,
    -1,
    -1
]

export default class DynamicElementsController {
    dynsBeforePronghorn:IRenderable[];
    dynsAfterPronghorn:IRenderable[];
    
    intro:Gltf
    entitiesList:Array<DynamicElementEntity>
    quad:Rect
    scene:Scene
    mainNode:Node
    speed:number
    zMultiplier:number
    types:Array<Array<vec3>>
    renderIntro:boolean
    constructor(elements:Gltf, intro:Gltf, sceneNode:Node, scene:Scene) {
        this.scene = scene
        this.quad = new Rect(scene.gl)
        this.intro = intro
        this.speed = 1.11 / 2
        this.zMultiplier = 1
        this.mainNode = new Node()
        scene.game.root.add(this.mainNode)
        this.mainNode['extras'] = { isRendered: true }
        // const texts  = elements['_collections'].filter(c => c.key === 'textures')
        const atlasTex:Texture = elements.getElementByName(GltfTypes.TEXTURE, 'dynamic-atlas').glTexture;
        atlasTex.bind()
        atlasTex.setFilter(false)
        this.dynsBeforePronghorn = elements.renderables.filter(r => r.node.name.indexOf('Grass') < 0)
        this.dynsAfterPronghorn = elements.renderables.filter(r => r.node.name.indexOf('Grass') >= 0)
        
        // Intro handle
        this.renderIntro = true
        this.mainNode.add(this.intro.root)
        const shadowList = this.scene.game.parallaxCtrl.groundEntities[0].shadowNodeList;
        (this.intro.root as GltfNode).extras = { isRendered: true }
        const atlasIntroTex:Texture = this.intro.getElementByName(GltfTypes.TEXTURE, 'intro-atlas').glTexture;
        atlasIntroTex.bind()
        atlasIntroTex.setFilter(false)
        this.intro.root._children.forEach(c => {
            if(c._children.length > 0) shadowList.push(c._children[0])
        }) 

        this.types = TYPES.map((name) => {
            const start = vec3.create()
            const d = vec3.create()

            const n = elements.getElementByName(GltfTypes.NODE, name)

            vec3.copy(start, n._children[0].position)
            vec3.sub(d, n._children[1].position, start)
            return [start, d]

            
        })

        this.entitiesList = NODELIST.map((nodeNames, i) => {
            let randPos:Array<vec3>|string = ''
            const t = NODE_TYPE[i]
            if(typeof t === 'string') {
                randPos = t
            } else {
                randPos = this.types[t]
            }
            return new DynamicElementEntity(
                scene,
                elements,
                this.mainNode,
                nodeNames,
                NB_ELEMENTS[i],
                this.quad,
                i,
                randPos,
                LAUNCH_WITH[i],
                this.onPlaceElement)
        })

/////////////////////
///////////////////
//////////////////
    }

    makeSpace() {
        this.entitiesList.forEach(e => e.makeSpace())
    }

    updateNodes(dt: number, speed:number, changeSpeed:number) {
        // this.mainNode.y = this.scene.game.groundY
        const scrollSpeed = (dt + speed * dt) * changeSpeed * this.speed * (1 - this.scene.game.introOutro)
        const scroll = store.getters['game/qte'] === -1 ? scrollSpeed : scrollSpeed * (store.getters['game/qte'] === 1 ? BACK_FENCE_SLOWMO : BACK_SLOWMO)
        this.entitiesList.forEach(entity => entity.updateNode(dt, scroll))
        this.intro.root.x += scroll
        if(this.renderIntro && scroll > 20) {
            this.renderIntro = false;
            (this.intro.root as GltfNode).extras = { isRendered: false }
            this.mainNode.remove(this.intro.root)
        }
    }

    render() {}

    onPlaceElement = (id:number, rand:number, position:any, intId:number = -1) => {
        if(LAUNCH_TO[id] !== -1) this.entitiesList[LAUNCH_TO[id]].startPlaceElement(-1, rand, false, position, intId)

        this.dynsBeforePronghorn = this.dynsBeforePronghorn.sort((a:IRenderable, b:IRenderable) => this.getObjectZ(b) - this.getObjectZ(a))
    }

    getObjectZ(a:IRenderable):number {
        if(a.node._parent && (a.node._parent as GltfNode).name && (a.node._parent as GltfNode).name.indexOf('Group-') > -1) {
            return a.node.position[2] + a.node._parent.position[2]
        }

        return a.node.z
    }

/////////////////
////////////
////////////////////////////////////////////////////
///////////////////////////////////////////////////
//////////////////////////////////////////////////
/////
//////////////
}