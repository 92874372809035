<template>
  <div
    class="speed-gauge"
    :class="{
      show: qte === -1 && isLoaded,
      'in-intro': isInIntro || !gameStarted
    }"
  >
    <div class="gauge">
      <div class="gauge-back" :style="{ width: speed * 102 + '%' }" />
      <div class="gauge-mask">
        <div class="gauge-increase" :style="{ width: speed * 102 + '%' }">
          {{ Math.round(speed * 61) }}
        </div>
      </div>
    </div>
    <div class="speed-gauge-label">
      MPH
    </div>
    <lottie-animation
      ref="maxSpeedAnim"
      url="/assets/bodymovin/61mph.json"
      :loop="false"
      :autoplay="false"
    />
  </div>
</template>

<script>
import store from '@/store'
import {
  computed,
  onActivated,
  onDeactivated,
  onMounted,
  onUnmounted,
  ref,
  watch
} from '@vue/runtime-core'
import LottieAnimation from '@/components/LottieAnimation.vue'
import gameStatic from '@/webgl/game/gameStatic'
export default {
  components: {
    LottieAnimation
  },
  props: {
    isLoaded: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const speed = ref(0)
    const qte = computed(() => store.getters['game/qte'])
    const qteTransition = computed(() => store.getters['game/qteTransition'])
    const isInIntro = computed(() => store.getters['game/isInIntro'])
    const gameStarted = computed(() => store.getters['game/isGameStarted'])
    const maxSpeedAnim = ref(null)

    let watchqteTransition = watch(qteTransition, b => {
      if (b && store.getters['game/qteComing'] !== -1) {
        maxSpeedAnim.value.play()
      }
    })

    const update = dt => {
      speed.value = gameStatic.speed
    }

    const complete = () => {
      maxSpeedAnim.value.lottieAnim.goToAndStop(0, true)
    }

    onMounted(() => {
      complete()
    })

    // onDeactivated(() => {
    //   watchqteTransition()
    // })

    return {
      speed,
      qte,
      isInIntro,
      maxSpeedAnim,
      qteTransition,
      update,
      gameStarted
    }
  }
}
</script>

<style lang="scss">
.speed-gauge {
  position: absolute;
  width: calc(100vw - 300px);
  height: 30px;
  bottom: 30px;
  left: 150px;
  visibility: hidden;
  transform: translateY(0);
  transition: transform 0.6s $ease-out-quart 0.2s;

  .force-landscape & {
    width: calc(var(--vh, 1vh) * 100 - 300px);
  }

  &.show {
    visibility: visible;
  }
  &.in-intro {
    transform: translateY(150px);
    transition: none;
  }
  .gauge,
  .gauge-back,
  .gauge-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 20px;
    width: 100%;
    background: rgba(255, 236, 210, 0.5);
    &-increase {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 70%;
      height: 100%;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 5px;
      color: white;
      background: linear-gradient(
        90deg,
        #d8635f -1.94%,
        rgba(216, 99, 95, 0) calc(100% - 300px)
      );

      background: linear-gradient(90deg, #d8635f -1.94%, #ff8a86 100.75%);
    }
  }
  .gauge-back {
    background: none;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.25);
  }
  .gauge-mask {
    overflow: hidden;
  }
  &-label {
    padding-left: 45px;
    position: absolute;
    top: 50%;
    transform: translate(calc(100% - 18px), -50%);
    right: 0;
    color: white;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .lottie-animation {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    pointer-events: none;
    width: 170px;
    height: 170px;
  }
}
</style>
