
import {
  computed,
  onActivated,
  onDeactivated,
  onMounted,
  ref,
  watch
} from 'vue'
import store from '@/store'
import timeout from '@/utils/timeout'
import {
  CLICK_START_TIME,
  CLICK_END_TIME,
  TARGET_SPEED
} from '@webgl/game/gameConfig'
import { useHowler } from '@/plugins/Howler'
import gameStatic from '@/webgl/game/gameStatic'
const expoIn = require('eases/quart-in')
const smoothstep = require('smoothstep')
export default {
  setup() {
    const bisonsTarget = computed(() => store.getters['game/bisonsPosition'])
    const times = ref([])
    const isResults = ref([])
    const success = ref([])
    const failed = ref([])
    const count = ref(0)
    const currStart = ref(0)

    const results = computed(() => store.getters['game/isResultMode'])

    const howler = useHowler()

    const getValue = i => {
      return expoIn(
        smoothstep(CLICK_START_TIME, CLICK_END_TIME, times.value[i])
      )
    }

    const watchResults = watch(results, async () => {
      if (results.value) {
        for (let i = 0; i < bisonsTarget.value.length; i++) {
          await timeout(50 * i)
          isResults.value[i] = true
        }
      }
    })

    const fillTargets = (delay = 0) => {
      for (let i = 0; i < bisonsTarget.value.length; i++) {
        times.value.push(0)
        success.value.push(false)
      }
      start(bisonsTarget.value[count.value], delay)
    }

    const watchBisonsTarget = watch(bisonsTarget, () => {
      if (times.value.length === 0) fillTargets(2500)
    })

    const update = (dt: number) => {
      bisonsTarget.value.forEach((t, i) => {
        if (t.started) {
          times.value[i] = Math.min(
            1,
            times.value[i] +
              (dt / 1000) * (TARGET_SPEED + 0.3 * store.getters['game/level'])
          )

          if (getValue(i) >= 1 && !success.value[i] && !failed.value[i]) {
            store.commit('game/enableFail')
            howler.$getSound('qte-fail', true).play()
            failed.value[i] = true
            currStart.value++
            if (currStart.value < bisonsTarget.value.length)
              start(bisonsTarget.value[currStart.value], 0)
          }
        }
      })

      if (
        getValue(bisonsTarget.value.length - 1) >= 1 &&
        !store.getters['game/isResultMode']
      )
        store.commit('game/setToQteResult')
    }

    const start = async (target, delay) => {
      await timeout(delay)
      target.started = true
    }

    const targetClick = (event: TouchEvent, id: number) => {
      success.value[id] = true
      count.value++
      const score = store.getters['game/tempScore']

      store.commit('game/setTempScore', {
        score: score + Math.round(gameStatic.dodgeScore * gameStatic.scoreScale)
      })

      const resArr = success.value.filter(v => v === true)
      store.commit('game/setQteSuccess', {
        percSuccess: resArr.length / success.value.length
      })
      currStart.value++
      if (currStart.value < bisonsTarget.value.length)
        start(bisonsTarget.value[currStart.value], 0)
    }

    onActivated(async () => {
      store.commit('game/setQteSuccess', { percSuccess: 0 })
      if (times.value.length === 0) fillTargets(1500)
    })

    onDeactivated(() => {
      watchBisonsTarget()
      watchResults()
    })

    return {
      bisonsTarget,
      update,
      results,
      times,
      isResults,
      success,
      failed,
      getValue,
      count,
      currStart,
      targetClick,
      CLICK_START_TIME,
      CLICK_END_TIME
    }
  }
}
