export default class Scene {
    constructor() {
        this.gltftype = "scenes" /* SCENE */;
    }
    async parse(gltfLoader, data) {
        if (data.nodes !== undefined) {
            const nodePromises = data.nodes.map(idx => gltfLoader.getElement("nodes" /* NODE */, idx));
            this.nodes = await Promise.all(nodePromises);
        }
        else {
            this.nodes = [];
        }
    }
    /*
     * update world matrices of all scene nodes
     */
    updateNodes() {
        for (let i = 0; i < this.nodes.length; i++) {
            this.nodes[i].updateWorldMatrix();
        }
    }
}
