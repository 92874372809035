
import { courseUpdate } from '@/hooks/gestures'
import { computed, defineComponent, onDeactivated, ref } from 'vue'
import GestureCleanUp from '../@gestures/GestureCleanUp.vue'
import GestureDodge from '../@gestures/GestureDodge.vue'
import GestureBisons from '../@gestures/GestureBisons.vue'
import GestureRunning from '../@gestures/GestureRunning.vue'
import SpeedGauge from './SpeedGauge.vue'
import GameHeader from './GameHeader.vue'
import store from '@/store'
export default defineComponent({
  components: {
    'game-head': GameHeader,
    'gesture-running': GestureRunning,
    'speed-gauge': SpeedGauge,
    'gesture-cleanup': GestureCleanUp,
    'gesture-dodge': GestureDodge,
    'gesture-bisons': GestureBisons
  },
  props: {
    isLoaded: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const speedGauge = ref(null)
    const gestureLeft = ref(null)
    const gestureRight = ref(null)
    const gestureDodge = ref(null)
    const gestureCleanUp = ref(null)
    const gestureBisons = ref(null)
    const gameHead = ref(null)
    const cycle = ref(0)
    const totalCount = ref(0)

    // Computed
    const qte = computed(() => store.getters['game/qte'])
    const gameStarted = computed(() => store.getters['game/isGameStarted'])

    // watch(qte, (qte: number) => {})

    const resize = () => {
      if (qte.value === -1) {
        ;(gestureLeft.value as any).resize()
        ;(gestureRight.value as any).resize()
      }
    }
    const update = (dt: number = 0) => {
      if (qte.value === -1 && gestureLeft.value) {
        courseUpdate(dt, gestureLeft.value, gestureRight.value)
        speedGauge.value.update(dt)
      }
      if (qte.value === 0 && gestureCleanUp.value)
        gestureCleanUp.value.update(dt)
      if (qte.value === 1 && gestureDodge.value) gestureDodge.value.update(dt)
      if (qte.value === 2 && gestureBisons.value) gestureBisons.value.update(dt)
      if (gameStarted.value) gameHead.value.update(dt)
    }

    return {
      gameStarted,
      qte,
      cycle,
      totalCount,
      speedGauge,
      gestureLeft,
      gestureRight,
      gestureDodge,
      gestureCleanUp,
      gestureBisons,
      gameHead,
      update
    }
  }
})
