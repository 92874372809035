import getQuery from '@/webgl/lib/getQuery'


const HI = 1;
const LO = 0;



const _p = {


  'post': [
    false,
    false
  ],

  'shadows' : [
    false, 
    false
  ],

  'reflect':[
    false, 
    true
  ],

  'cube_res': [
    1024,
    1024
  ],

  'half_dpi': [
    false,
    false
  ],


  'max_dpi': [
    3,
    2
  ],


  'anisotropy': [
    0,
    4
  ],




  'shaderQuality': [
    "QUALITY_LO",
    "QUALITY_HI"
  ]



};






const _defaultQuality = ()=>HI


const urlP = getQuery( 'quality', _defaultQuality() )

let _quality = parseInt( urlP );

let listeners = [];


/**
  hi
  lo
  auto
**/
function setMode(mode) {

  let q;
  switch(mode){
    case 'auto' : 
    case undefined : 
      q = _defaultQuality(); break;
      
    case 'high' : q = HI; break;
    case 'low'  : q = LO; break;
    default : throw new Error( 'quality invalid enum '+mode);
  }

  _quality = q;
  notify();
};


function setQuality(val) {
  _quality = val;
  notify();
};

function getQuality(val) {
  return _quality;
};


function setHI() {
  setQuality( HI )
};

function setLOW() {
  setQuality( LO )
};


function param(key) {
  return _p[key][_quality];
};

function on( scope, fname, nocall ){
  listeners.push( {
    scope:scope,
    fname:fname
  });

  if( !nocall ){
    scope[fname]( true );
  }
}

function release(){
  listeners = []
}

function notify(){
  for (var listener of listeners) {
    listener.scope[listener.fname]( false );
  }
}


function getMacroDefs(){
  const defs = [
    '#define '+param( 'shaderQuality' )+ ' 1'
  ]

  return defs.join( '\n' );
}



const quality = {
  setHI       ,
  setLOW      ,
  setMode     ,
  setQuality  ,
  getQuality  ,
  param       ,
  on          ,
  release     ,
  getMacroDefs,
}

export default quality;





