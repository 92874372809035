import { vec4 } from 'gl-matrix';
import vShader from './unlit.vert';
import fShader from './frag.glsl';
import MaterialPass from 'nanogl-pbr/MaterialPass';
import Flag from 'nanogl-pbr/Flag';
import Input from 'nanogl-pbr/Input';
import { AlphaModeEnum, AlphaModes } from 'nanogl-pbr/AlphaModeEnum';
import Enum from 'nanogl-pbr/Enum';
import ShaderVersion from 'nanogl-pbr/ShaderVersion';
import ShaderPrecision from 'nanogl-pbr/ShaderPrecision';
import Texture from 'nanogl/texture';
import TexCoord from 'nanogl-pbr/TexCoord';
const MAT_ID = 'bg_parallax_pass';

import Chunk from "nanogl-pbr/Chunk";
import ChunksSlots from "nanogl-pbr/ChunksSlots";

export interface ParallaxUnit {
    texture: Texture,
    scalePos: vec4
}

class ParallaxUnitShaderCode extends Chunk {
    totalTex:number

    constructor(totalTex:number) {
      super(true, true);
      this.totalTex = totalTex
    }

    _genCode(slots: ChunksSlots) {
        let t:string = ''
        t += `
        vec4 r;
        vec4 t;
        `
        for (let i = 0; i < this.totalTex; i++) {
            t += `
            r = uScalePos${i};
            t = texture2D(uTex${i}, vec2(uScroll * -r.w, r.z) + uvs * (1. / r.xy));
            _baseColor.rgb = mix(_baseColor.rgb, t.rgb, t.a);`
        }
        slots.add('f', t);

    }

    _getHash(): string {
        return 'parallax-effect';
    }

}


export default class BackgroundParallax extends MaterialPass {
    version: ShaderVersion;
    precision: ShaderPrecision;
    shaderid: Flag;
    baseColorFactor: Input;
    alpha: Input;
    alphaFactor: Input;
    alphaCutoff: Input;
    alphaMode: AlphaModeEnum;
    doubleSided: Flag;
    scroll: number
    texList:Array<ParallaxUnit>
    constructor(all:Array<ParallaxUnit>, name = 'unlit-pass') {
        super({
            uid: MAT_ID,
            vert: vShader(),    
            frag: fShader(),
        });
        const inputs = this.inputs;
        inputs.add(this.version = new ShaderVersion('100'));
        inputs.add(this.precision = new ShaderPrecision('highp'));
        inputs.add(this.shaderid = new Flag('id_' + MAT_ID, true));
        inputs.add(this.alpha = new Input('alpha', 1));
        inputs.add(this.alphaFactor = new Input('alphaFactor', 1));
        inputs.add(this.alphaCutoff = new Input('alphaCutoff', 1));
        inputs.add(this.alphaMode = new Enum('alphaMode', AlphaModes));
        inputs.add(this.doubleSided = new Flag('doubleSided', false));
        const tCoord:TexCoord = TexCoord.create('aTexCoord0' )
        this.texList = all
        this.texList.forEach((unit:ParallaxUnit, i:number) => {
            //Sampler
            const tInput = new Input('uBaseColor' + i, 3)
            tInput.attachSampler( 'uTex' + i, tCoord ).set( unit.texture )
            inputs.add(tInput)

            //Scale
            const sInput = new Input('uScalePos' + i, 4)
            sInput.attachUniform('uScalePos' + i, 4)
            inputs.add(sInput)
        })

        const pUnitCode = new ParallaxUnitShaderCode(this.texList.length)
        inputs.add(pUnitCode)
    }

    preRender(scroll:number, speed:number) {
        this.scroll = scroll * 0.1 * speed
    }

    prepare(prg, node, camera) {
        prg.uM(node._wmatrix);
        prg.uVP(camera._viewProj);
        prg.uScroll(-this.scroll)
        this.texList.forEach((unit:ParallaxUnit, i:number) => {
            prg['uTex'+i](unit.texture)
            prg['uScalePos'+i](unit.scalePos)
        })
    }
}
;
