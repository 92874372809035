import store from "@/store";
import Masks from "@/webgl/gl/masks";
import { Passes } from "@/webgl/glsl/Passes";
import Gltf from "@/webgl/lib/nanogl-gltf/lib";
import IRenderable from "@/webgl/lib/nanogl-gltf/lib/renderer/IRenderable";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import Scene from "@/webgl/scene";
import { quat, vec3 } from "gl-matrix";
import Node from "nanogl-node";
import { BACK_SLOWMO, GAME_GLOBAL_SPEED_SCALE, SPEED_RUN } from "../../gameConfig";
import anime from 'animejs/lib/anime.es.js';
import GltfNode from "@webgl/lib/nanogl-gltf/lib/elements/Node";
import UnlitPass from "nanogl-pbr/UnlitPass";
import { PassInstance } from "nanogl-pbr/BaseMaterial";
import { Uniform } from "nanogl-pbr/Input";
import timeout from "@/utils/timeout";

import { computed, watch } from 'vue'
import gameStatic from "../../gameStatic";

const lerp = require('lerp')

const ID_QUAT = quat.create()
quat.identity(ID_QUAT)

export default class CleanupController {
    gltf:Gltf
    scene:Scene

    nodeList:Array<Node>

    root:GltfNode

    renderer:IRenderable

    objects:GltfNode[]
    ejectAlpha
    savePosition:vec3[]

    cleanUp:number
    divCleanUp:number

    countRender:number

    alphaVal:Uniform

    avoidSpeed:number
    inGame:boolean

    objectsR:IRenderable[]
    shadows:IRenderable[]
    watchResult

    constructor(gltf:Gltf, scene:Scene) {
        this.gltf = gltf
        this.scene = scene

        this.root = gltf.getElementByName(GltfTypes.NODE, 'CleanUpGroup')
        this.objects = []
        const pass:UnlitPass = ((this.root._children[0] as GltfNode).renderable.materials[0].getPass('color') as PassInstance).pass as UnlitPass;
        this.alphaVal = pass.alphaFactor.attachUniform('alphaFactor')

        // this.root.extras = { isRendered: false }

        this.objectsR = this.gltf.renderables.filter(r => (r.node._parent as GltfNode).name === 'CleanUpGroup')
        this.shadows = this.gltf.renderables.filter(r => (r.node._parent as GltfNode).name !== 'CleanUpGroup')

        const c = computed(() => store.getters['game/isResultMode'])

        this.watchResult = watch(c, () => {
            if(store.getters['game/isResultMode'] && this.inGame) this.setEndAnim()
        })
    }

    start() {
        
        // this.root.extras.isRendered = true
        this.inGame = true
        store.commit('game/setTempScore', { score: 0 })
        this.scene.game.root.add(this.root)
        this.root.x = -0.15
        this.ejectAlpha = []
        this.savePosition = this.root._children.map(c => {
            c._children[0].x = 0
            const v = vec3.create()
            vec3.copy(v, c.position)
            this.ejectAlpha.push({
                name:(c as GltfNode).name,
                value: 1
            })
            return v
        })
        this.objects = (this.root._children as GltfNode[]).slice()
        this.objects = this.objects.sort(() => Math.random() - 0.5)
        let tId = -1
        let tNode:GltfNode = null
        for (let i = 0; i < this.objects.length; i++) {
            if(this.objects[i].name === 'TONNEAU1') {
                tId = i
                tNode = this.objects[i]
                break
            }
        }

        this.objects.splice(tId, 1)
        this.objects.push(tNode)

        this.cleanUp = store.getters['game/cleanUp'];
        this.divCleanUp = Math.ceil(this.cleanUp / this.objects.length);
        this.scene.game.visualSpeedLerp = 1
    }

    async setEndAnim() {
        store.commit('game/setaddScoreQte')
        // store.commit('game/addToScore', { score: store.getters['game/tempScore'] })
        // store.commit('game/setTempScore', { score: 0 })
        this.scene.game.visualSpeedLerp = 0
        if(store.getters['game/cleanUp'] === 0) {
            this.scene.game.pronghornEntity.slowMoOver = true
            gameStatic.speed = 1
            this.scene.game.toggleSoundLayers(false)
            const winSound = this.scene.game.howler.$getSound('qte-win', true).play()
            this.scene.game.howler.$getSound('qte-win', true).once('end', () => this.scene.game.toggleSoundLayers(true))
        } else {
            store.commit('game/enableFail')
            this.scene.game.howler.$getSound('qte-fail', true).play()
            await timeout(500)
            // store.commit('game/setSpeedAvoidJunk')
            this.avoidSpeed = 1
            this.scene.game.pronghornEntity.setavoidJunk()
        }
    }

    stop() {
        // this.root.extras.isRendered = false
        this.inGame = false
        gameStatic.speed = 0.5 + store.getters['game/percSuccess'] * 0.25
        this.root._children.forEach((c, i) => {
            vec3.copy(c.position, this.savePosition[i])
            quat.copy(c.rotation, ID_QUAT)
            c.invalidate()
        })
        this.root._parent.remove(this.root)
    }

    removeNextObject() {
        // console.log(this.objects.length)
        if(this.objects.length === 0) return
        const duration = 700 + (Math.random() * 2 - 1) * 100
        const toRemove = this.objects[0]
        toRemove._children[0].x = -1000
        const randRot = 0.01 + Math.random() * 0.1
        const alpha = this.ejectAlpha.filter(c => c.name === toRemove.name)[0]
        anime({
            targets: toRemove,
            x: toRemove.x + (Math.random() * 2 - 1) * 0.25,
            z: toRemove.z + (Math.random() * 2 - 1) * 0.25,
            y: {
                value: toRemove.y + 0.5 + Math.random() * 0.1,
                easing: 'spring(1, 80, 10, 0)'
            },
            duration,
            easing: 'linear',
            update: () => {
                toRemove.rotateZ(randRot)
            },
            complete: () => {
                toRemove.y = -1000  
            }
            
        })

        anime({
            targets: alpha,
            value: 0,
            duration: 100,
            easing: 'linear',
            delay: duration - 200
        })
        this.objects.shift()

    }

    updateNodes(dt:number, globalSpeed:number, changeSpeed:number) {
        const dtr =  dt * SPEED_RUN
        this.root.x += (dtr + globalSpeed * dtr) * changeSpeed * BACK_SLOWMO
        if(this.cleanUp !== store.getters['game/cleanUp']) {
            if(this.cleanUp % this.divCleanUp === 0) {
                this.removeNextObject()
            }
            this.cleanUp = store.getters['game/cleanUp']
        }
        if(store.getters['game/cleanUp'] === 0 && this.objects.length !== 0) {
            while(this.objects.length !== 0) {
                this.removeNextObject()
            }
        }
        if(this.scene.game.pronghornEntity.avoidJunkSpeed) {
            this.avoidSpeed = lerp(this.avoidSpeed, 0.4, dt * 3)
            gameStatic.speed = this.avoidSpeed

        }
    }

    preRender(dt:number) {}

    render() {
        this.alphaVal.set(1)
        for (const renderable of this.shadows) {
            renderable.render(this.scene, this.scene.game.getCamera(), Masks.BLENDED , Passes.COLOR, this.scene.game.baseConfig)
        }
    }

    renderAfter() {
        this.countRender = 0
        for (const renderable of this.objectsR) {
            this.alphaVal.set(this.ejectAlpha[this.countRender].value)
            this.countRender++
            renderable.render(this.scene, this.scene.game.getCamera(), Masks.BLENDED , Passes.COLOR, this.scene.game.baseConfig)
        }
    }

    release() {
        this.watchResult()
    }
}