<template>
  <div ref="root" class="game-transition">
    <div class="game-transition-frame-3" />
    <div class="game-transition-frame-2" />
    <div class="game-transition-title-frame">
      <div class="game-transition-title-frame-inside">
        <h2 class="t-h2">{{ getMessage }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core'
import timeout from '@/utils/timeout'
import store from '@/store'
export default {
  setup() {
    const root = ref(null)

    const getMessage = computed(() => {
      switch (store.getters['game/qteComing']) {
        case -1:
          return 'Dash!'
        case 0:
          return 'Clear!'
        case 1:
          return 'DUCK!'
        default:
          return 'Dodge!'
      }
    })

    onMounted(async () => {
      await timeout(store.getters['game/qteComing'] === -1 ? 100 : 1100)
      root.value.classList.add('show')
      await timeout(2000)
      root.value.classList.add('disappear-right')
      await timeout(2000)
      if (root.value) {
        root.value.classList.remove('show')
        root.value.classList.remove('disappear-right')
      }
    })

    return {
      root,
      getMessage
    }
  }
}
</script>

<style lang="scss">
.game-transition {
  --tr-width: calc(100vw + 100px);
  --tr-width-half: calc(100vw + 200px);

  .force-landscape & {
    --tr-width: calc(var(--vh, 1vh) * 100 + 100px);
    --tr-width-half: calc(var(--vh, 1vh) * 100 + 200px);
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  z-index: 1;
  & > div {
    position: absolute;
    top: 0;
    left: -50px;
    height: 100%;
    width: var(--tr-width);
    overflow: hidden;
    transform-origin: center;
  }
  &-title-frame {
    transform: translateX(var(--tr-width-half)) skewX(5deg);
    background: linear-gradient(0deg, #ffecd2 0%, #ffffff 100%), #ffffff;
    &-inside {
      transform-origin: center;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(calc(-1 * var(--tr-width-half))) skewX(-5deg);
      width: var(--tr-width);
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-text-stroke: 1px color(light-red);
      -webkit-text-fill-color: transparent;
      padding: 0 120px;
      text-transform: uppercase;
      text-align: center;
      .t-h2 {
        font-family: font(heading);
        font-size: 70px;
        line-height: 90%;
      }
    }
  }
  &-frame-2 {
    transform: translateX(var(--tr-width-half)) skewX(5deg);
    background: linear-gradient(0deg, #f7b681, #f7b681), #ffffff;
    opacity: 0.7;
  }
  &-frame-3 {
    transform: translateX(var(--tr-width-half)) skewX(5deg);
    background: #ffffff;
    opacity: 0.3;
  }

  &.show {
    .game-transition-frame-3 {
      transition: transform 1.5s $ease-out-quart;
      transform: translateX(0) skewX(5deg);
    }
    .game-transition-frame-2 {
      transition: transform 1.5s $ease-out-quart 0.1s;
      transform: translateX(0) skewX(5deg);
    }
    .game-transition-title-frame {
      transition: transform 1.5s $ease-out-quart 0.25s;
      transform: translateX(0) skewX(5deg);
      &-inside {
        transition: transform 1.5s $ease-out-quart 0.25s;
        transform: translateX(0) skewX(-5deg);
      }
    }
  }

  &.disappear-right {
    .game-transition-title-frame {
      transition: transform 1.5s $ease-in-quart;
      transform: translateX(calc(-1 * var(--tr-width-half))) skewX(5deg);
      &-inside {
        transition: transform 1.5s $ease-in-quart;
        transform: translateX(var(--tr-width-half)) skewX(-5deg);
      }
    }
    .game-transition-frame-2 {
      transition: transform 1.5s $ease-in-quart 0.1s;
      transform: translateX(calc(-1 * var(--tr-width-half))) skewX(5deg);
    }
    .game-transition-frame-3 {
      transition: transform 1.5s $ease-in-quart 0.25s;
      transform: translateX(calc(-1 * var(--tr-width-half))) skewX(5deg);
    }
  }
}
</style>
