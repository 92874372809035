/////////////
//////////////////////////////////
//////////

import GltfTypes from '@/webgl/lib/nanogl-gltf/lib/types/GltfTypes';
import { quat, vec3 } from "gl-matrix";
import Node from "nanogl-node";
import BaseMaterial from "nanogl-pbr/BaseMaterial";
import Texture from "nanogl/texture";
import MultiTexture from "../../glsl/multitexture";
import Scene from "../../scene";
import GltfNode from "@webgl/lib/nanogl-gltf/lib/elements/Node";

export default class GroundEntity {
    node: Node
    // groundNode: Node
    mat:BaseMaterial
    mtPass:MultiTexture
    scene:Scene
    scaleY:number
    speed:number
    nodeCopy:GltfNode
    shadowNodeList:Node[]
    constructor(t1:Texture, t2:Texture, cloudTex:Texture, fgTex:Texture, scene:Scene) {
        this.scene = scene
        this.node = new Node()
        scene.game.root.add(this.node);
        this.nodeCopy = scene.game.dynsGltf.getElementByName(GltfTypes.NODE, 'Ground')
        vec3.copy(this.node.position, this.nodeCopy.position)
        quat.copy(this.node.rotation, this.nodeCopy.rotation)
        vec3.set(this.node.scale, (t1.width + t2.width) / t1.height * this.nodeCopy.scale[1], this.nodeCopy.scale[1], 1)
        
        this.node.invalidate()
        this.node.updateWorldMatrix()
        this.speed = 0.14
        this.mat = new BaseMaterial(scene.gl, 'multi-tex' );
        t1.bind()
        scene.gl.texParameteri(t1._target, scene.gl.TEXTURE_WRAP_S, scene.gl.REPEAT);
        scene.gl.texParameteri(t1._target, scene.gl.TEXTURE_WRAP_T, scene.gl.CLAMP_TO_EDGE);
        t2.bind()
        scene.gl.texParameteri(t2._target, scene.gl.TEXTURE_WRAP_S, scene.gl.REPEAT);
        scene.gl.texParameteri(t2._target, scene.gl.TEXTURE_WRAP_T, scene.gl.CLAMP_TO_EDGE);
        this.mtPass = new MultiTexture(t1, t2, cloudTex, fgTex, 'multi-tex')
        this.mat.addPass(this.mtPass)

        this.shadowNodeList = []

/////////////////////
///////////////////
//////////////////
    }

    shadowsReady() {
        this.mtPass.shadowsReady(this.shadowNodeList)
    }

    preRender(scroll:number) {
        this.mtPass.preRender(scroll, this.speed, this.node)
    }

    render() {
        const prg = this.mat.getAllPasses()[0].getProgram()
        prg.use();
        this.mtPass.prepare(prg, this.node, this.scene.camera)
        for (let i = 0; i < this.nodeCopy.mesh.primitives.length; i++) {
            const primitive = this.nodeCopy.mesh.primitives[i];
            this.drawCall(this.scene.game.getCamera(), prg, primitive)
        }
    }
    drawCall(camera, prg, sub) {
        sub.bindVao(prg);
        sub.render();
        sub.unbindVao();
    }

    _gui() {
        if(gui.__folders['ground']) return
        var pf = gui.addFolder('ground');
        pf.add(this, 'speed', 0, 4).step(0.01)
    }
}